import React, { useEffect, useRef, useState } from "react";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { IoIosLogOut } from "react-icons/io";
import { IoBagCheck, IoSearchOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/url";
import { RiMenu3Line } from "react-icons/ri";
import { IoDiamondOutline } from "react-icons/io5";
import { MdContactMail, MdOutlinePendingActions } from "react-icons/md";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GiBigDiamondRing } from "react-icons/gi";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/slice/logout/logout";
import { decrypt, encrypt } from "../../utils/EncryptDecrypt";

export default function SideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = async () => {
    const result = await dispatch(logoutUser());
    if (logoutUser.fulfilled.match(result)) {
      navigate('/');
    } else {
      console.error('Logout failed:', result.payload);
    }
  };

  const handlePermission = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/admin/get-user-permission`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      const data = await response.json();

      if (response.ok) {
        setPermissions(data.data);
        sessionStorage.setItem('permissions', encrypt(JSON.stringify(data.data)));
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  };

  useEffect(() => {
    const roleId = sessionStorage.getItem("UrI");
    const savedPermissions = sessionStorage.getItem('permissions');

    if (roleId === "1" && !permissionsFetched) {
      if (savedPermissions) {
        setPermissions(JSON.parse(decrypt(savedPermissions)));
      } else {
        handlePermission();
      }
      setPermissionsFetched(true); // Set the flag to true after fetching
    }
  }, [permissionsFetched]); // Dependency array includes permissionsFetched


  const links = [
    // {
    //   to: "/dashboardpage",
    //   label: "Dashboard",
    //   icon: <RiDashboardHorizontalLine fontSize={26} />,
    // },
    // {
    //   to: "/inventorypage",
    //   label: "Inventory",
    //   icon: <IoSearchOutline fontSize={26} />,
    // },
    // {
    //   to: "/diamondsserach",
    //   label: "Loose Stone",
    //   icon: <IoDiamondOutline fontSize={26} />,
    // },
    // {
    //   to: "/jewelerysearch",
    //   label: "Jewelry",
    //   icon: <GiBigDiamondRing fontSize={26} />,
    // },
    // {
    //   to: "/requests",
    //   label: "Requests",
    //   icon: <MdOutlinePendingActions  fontSize={26} />,
    // },
    // {
    //   to: "http://order.skylabdiamond.com/",
    //   label: "Custom Jewelry",
    //   icon: <IoBagCheck fontSize={26} />,
    //   target: "_blank",
    // },
  ];

  const emailLocal = sessionStorage.getItem("email");
  const role_id = sessionStorage.getItem("UrI");



  if (
    (permissions?.dashboard === 1 || permissions?.dashboard === "1" && permissions.email === emailLocal) ||
    role_id == 3
  ) {
    links.push({
      to: "/dashboardpage",
      label: "Dashboard",
      icon: <RiDashboardHorizontalLine fontSize={26} />,
    });
  }
  if (
    (permissions?.loose_stone === 1 || permissions?.loose_stone === "1" && permissions.email === emailLocal) ||
    role_id == 3
  ) {
    links.push({
      to: "/diamondsserach",
      label: "Loose Stone",
      icon: <IoDiamondOutline fontSize={26} />,
    });
  }
  if (
    (permissions?.jewelry === 1 || permissions?.jewelry === "1" && permissions.email === emailLocal) ||
    role_id == 3
  ) {
    links.push({
      to: "/jewelerysearch",
      label: "Jewelry",
      icon: <GiBigDiamondRing fontSize={26} />,
    });
  }
  if (
    (permissions?.requests === 1 || permissions?.requests === "1" && permissions.email === emailLocal) ||
    role_id == 3
  ) {
    links.push({
      to: "/requests",
      label: "Requests",
      icon: <MdOutlinePendingActions fontSize={26} />,
    });
  }
  if (
    (permissions?.custom_jewelry === 1 || permissions?.custom_jewelry === "1" && permissions.email === emailLocal) ||
    role_id == 3
  ) {
    links.push({
      to: "https://order.skylabdiamond.com/",
      label: "Custom Jewelry",
      icon: <IoBagCheck fontSize={26} />,
      target: "_blank",
    });
  }

  return (
    <div className="z-50">
      <div className="">
        <button className="lg:hidden pt-12 pl-2" onClick={toggleSidebar}>
          <RiMenu3Line fontSize={38} />
        </button>
      </div>
      <div
        ref={sidebarRef}
        className={`fixed lg:sticky left-0 lg:h-screen h-full w-[110px] lg:w-[110px] top-0 bg-[#feefdc] text-black rounded-e-[50px] text-center flex flex-col justify-between transition-transform duration-300 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0`}
      >
        <div className="p-4 flex justify-center text-black pt-12">
          <h1 className="text-2xl font-bold">
            <img
              src="/logo/Logo Black 001.png"
              width={74}
              height={74}
              alt="Logo"
            />
          </h1>
        </div>
        <nav className="flex-1 flex flex-col justify-between my-10">
          <div className="flex flex-col gap-4">
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                target={link.target}
                className={`py-2 rounded-s-3xl ml-3 transition duration-200 flex flex-col gap-1 justify-center items-center hover:bg-[#3b71ca] hover:text-white ${currentPath === link.to ? "bg-[#3b71ca] text-white" : ""
                  }`}
                onClick={() => setIsSidebarOpen(false)}
              >
                {link.icon}
                <span>{link.label}</span>
              </Link>
            ))}
          </div>
          <div>
            <div>
              {" "}
              <Link
                to="/contact_us"
                className="py-3 rounded-s-3xl ml-2 transition duration-200 flex flex-col gap-2 justify-center items-center hover:bg-[#3b71ca] hover:text-white"
              >
                <TfiHeadphoneAlt fontSize={26} />
                <span>Contact Us</span>
              </Link>
            </div>
            <div>
              {" "}
              <Link
                to="/logout"
                className="py-3 rounded-s-3xl ml-2 transition duration-200 flex flex-col gap-2 justify-center items-center hover:bg-[#3b71ca] hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                  setIsSidebarOpen(false);
                }}
              >
                <IoIosLogOut fontSize={26} />
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
