import React from "react";
import AdminRequestDynamicId from "../../adminComponents/adminrequeste/AdminRequestDynamicId";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminRequestDynamicIdPage() {
  return (
    <AdminDefaultLayout>
      <AdminRequestDynamicId />
    </AdminDefaultLayout>
  );
}
