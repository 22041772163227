import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/url";
import Swal from "sweetalert2";

export default function AdminAllUsers() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddUserDropdownOpen, setIsAddUserDropdownOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("All Users");
  const [rowData, setRowData] = useState([]);
  const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [viewCompanyList, setViewCompanyList] = useState([]);
  const [rowviewCompanyData, setRowviewCompanyData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userCounts, setUserCounts] = useState({
    pending: 0,
    approve: 0,
    deactive: 0,
    decline: 0,
  });
  const navigate = useNavigate();

  const fetchCompanyList = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/company-logo-list`, {
        method: "GET",
        headers: myHeaders,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch company list");
      }
      const result = await response.json();
      
      // Set the row data to the company list
      setRowviewCompanyData(result.data); 
      console.log("result.data",result.data); 
      setShowModal(true);

    } catch (err) {
      console.error("Error fetching company list:", err);
    }
  };
  

  const togglecloseModal = () => {
    setShowModal(!showModal);
  };

  const columnCompenyLogoDefs = [
    { headerName: "ID", field: "id", width: 120},
    { headerName: "Company Name", field: "company_name", width: 190},
    {
      headerName: "Action",
      field: "action",
      width: 150,
      cellRenderer: (params) => (
        <button
          className=" text-black px-2  rounded"
          onClick={() => handleCompanyDelete(params.data.id)}
        >
          <MdDeleteForever className="text-xl" />
        </button>
      ),
    },
  ];


  const handleCompanyDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this company",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete this company!",
    }).then(async (result) => {
      if(result.isConfirmed){
        try {
          const token = sessionStorage.getItem("token");
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          const response = await fetch(`${baseUrl}/admin/company-logo-delete/${id}`, {
            method: "DELETE",
            headers: myHeaders
          })

          const responseData = await response.json();
          const responseMessage = responseData.message;

          if(response.ok){
            Swal.fire({
              title: "Deleted!",
              text: responseMessage,
              icon: "success"
            });
            setRowviewCompanyData((prevData) => prevData.filter((user) => user.id !== id))
          }
        } catch (error) {
          console.error("Error deleting company logo", error);
        }
      }
    })
  }

  useEffect(() => {
    fetchAllUsers(null);
    fetchAllUsersCount();
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleAddUserDropdown = () => {
    setIsAddUserDropdownOpen(!isAddUserDropdownOpen);
  };

  const fetchAllUsers = async (status) => {
    try {
      const token = sessionStorage.getItem("token");
      const roleId = sessionStorage.getItem("UrI");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const queryParams = status
        ? `?status_id=${status}`
        : `?role_id=${roleId}`;
      const response = await fetch(
        `${baseUrl}/admin/get-all-users${queryParams}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setRowData(data.data);
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchAllUsersCount = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/get-all-usercount`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const data = await response.json();
        const counts = data.data.reduce((acc, item) => {
          acc[item.status_id] = item.count;
          return acc;
        }, {});
        setUserCounts(counts);
      } else {
        throw new Error("Failed to fetch user counts");
      }
    } catch (error) {
      console.error("Error fetching user counts:", error);
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    let status = null;

    switch (buttonName) {
      case `New Sign-Ups (${userCounts.pending || 0})`:
        status = "pending";
        break;
      case `Activated Users (${userCounts.approve || 0})`:
        status = "approve";
        break;
      case `Deactivated Users (${userCounts.deactive || 0})`:
        status = "deactive";
        break;
      case `Declined Users (${userCounts.decline || 0})`:
        status = "decline";
        break;
      default:
        status = null;
    }

    fetchAllUsers(status);
  };

  const handleEmailClick = async (userId) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/get-one-user/${userId}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === "Success" && data.data.length > 0) {
          const userData = data.data[0];

          navigate(`/admin/user/${userData.id}`, { state: { userData } });
        } else {
          console.error("User not found or failed to fetch user details");
        }
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async () => {
    if (!logoFile) {
      return;
    }

    const formData = new FormData();
    formData.append("logo", logoFile);
    formData.append("company_name", companyName);

    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/admin/upload-company-logo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        Swal.fire("Success", "Logo uploaded successfully", "success");
        setIsUploadLogoModalOpen(false);
      } else {
        throw new Error("Failed to upload logo");
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
      Swal.fire("Error", "Failed to upload logo", "error");
    }
  };

  const EmailCellRenderer = (params) => {
    const { data } = params;
    return (
      <span
        className="text-blue-500 underline cursor-pointer"
        onClick={() => handleEmailClick(data.id)}
      >
        {data.email}
      </span>
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = sessionStorage.getItem("token");
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          const response = await fetch(`${baseUrl}/admin/delete-users/${id}`, {
            method: "DELETE",
            headers: myHeaders,
          });

          const responseData = await response.json();
          const responseMessage = responseData.message;

          if (response.ok) {
            Swal.fire({
              title: "Deleted!",
              text: responseMessage,
              icon: "success",
            });
            setRowData((prevData) => prevData.filter((user) => user.id !== id));
          }
        } catch (error) {
          console.error("Error deleting user:", error);
        }
      }
    });
  };

  const deleteButtonRenderer = (params) => {
    const productId = params.data.id;
    return (
      <button onClick={() => handleDelete(productId)}>
        <MdDeleteForever className="text-xl" />
      </button>
    );
  };

  const role_id = sessionStorage.getItem("UrI");
  const email = sessionStorage.getItem("email");

  const columnDefs = [
    { headerName: "ID", field: "id", width: 70 },
    { headerName: "First Name", field: "first_name", width: 110, filter: true },
    { headerName: "Last Name", field: "last_name", width: 110, filter: true },
    {
      headerName: "Email",
      field: "email",
      width: 250,
      cellRenderer: EmailCellRenderer,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Company Name",
      field: "company_name",
      width: 150,
      filter: true,
    },
    {
      headerName: "Role Id",
      field: "role_id",
      width: 140,
      filter: true,
      cellRenderer: (params) => {
        switch (params.value) {
          case "1":
            return "Client";
          case "2":
            return "Admin";
          case "3":
            return "Super Admin";
          default:
            return "Unknown";
        }
      },
    },
    { headerName: "Phone", field: "phone", width: 100, filter: true },
    {
      headerName: "Address",
      field: "company_address",
      width: 100,
      filter: true,
    },
    {
      headerName: "Status",
      field: "status_id",
      width: 100,
      filter: true,
      cellRenderer: (params) => {
        const status = params.value || "";
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    { headerName: "Country", field: "country", width: 100, filter: true },
    { headerName: "State", field: "state", width: 100, filter: true },
    { headerName: "City", field: "city", width: 100, filter: true },
    { headerName: "Zip Code", field: "zip_code", width: 100, filter: true },
    { headerName: "Username", field: "user_name", width: 100, filter: true },
    { headerName: "Created At", field: "created_at", width: 100, filter: true },
    { headerName: "Updated At", field: "updated_at", width: 100, filter: true },
    { headerName: "Price Mode", field: "price_mode", width: 100, filter: true },
    {
      headerName: "Lock Price Mode",
      field: "lockprice_mode",
      width: 100,
      filter: true,
    },
    {
      headerName: "AllowBuyMemo",
      field: "allowbuy_memo",
      width: 150,
      filter: true,
    },
    {
      headerName: "Last Login Date",
      field: "last_login_date",
      width: 150,
      filter: true,
    },
  ];

  if (role_id === "3") {
    columnDefs.push({
      headerName: "Delete",
      field: "delete",
      width: 100,
      cellRenderer: deleteButtonRenderer,
    });
  }

  const openUploadLogoModal = () => {
    setIsUploadLogoModalOpen(true);
  };

  const closeUploadLogoModal = () => {
    setIsUploadLogoModalOpen(false);
    setLogoFile(null);
    setCompanyName(""); // Reset company name on modal close
  };

  const handleFileChange = (event) => {
    setLogoFile(event.target.files[0]);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };


  const handleExportData = () => {
    exportDataToCSV(rowData, "user_data.csv");
  };


  const exportDataToCSV = (data, filename) => {
    const csvHeaders = columnDefs.map((col) => col.headerName).join(",");
    const csvRows = data.map((row) =>
      columnDefs.map((col) => `"${row[col.field] || ""}"`).join(",")
    );
    const csvContent = [csvHeaders, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="min-h-full bg-gray-100 flex flex-col">
      <div className="flex-grow bg-white p-4">
        <h1 className="text-3xl text-center font-semibold mb-4">Users</h1>

        <div className="mb-4">
          <div className="flex gap-4 justify-between flex-wrap space-x-4 mb-2">
            <div className=" flex gap-3">
              {[
                {
                  name: `New Sign-Ups (${userCounts.pending || 0})`,
                  count: userCounts.pending,
                },
                {
                  name: `Activated Users (${userCounts.approve || 0})`,
                  count: userCounts.approve,
                },
                {
                  name: `Deactivated Users (${userCounts.deactive || 0})`,
                  count: userCounts.deactive,
                },
                {
                  name: `Declined Users (${userCounts.decline || 0})`,
                  count: userCounts.decline,
                },
                {
                  name: `All Users (${
                    (userCounts.pending || 0) +
                    (userCounts.approve || 0) +
                    (userCounts.deactive || 0) +
                    (userCounts.decline || 0)
                  })`,
                  count: null,
                },
              ].map(({ name, count }) => (
                <button
                  key={name}
                  onClick={() => handleButtonClick(name)}
                  className={`px-4 text-[13px] rounded-md ${
                    activeButton === name
                      ? "bg-[#5cafd6] text-white"
                      : "bg-gray-300 text-gray-700"
                  }`}
                >
                  {name}
                </button>
              ))}
            </div>
            <div className="flex gap-4">
            <button
                onClick={handleExportData}
                className={`px-4 text-[13px] rounded-md ${"bg-gray-300 text-gray-700"}`}

              >
                Export Data
              </button>
              <button
                className={`px-4 text-[13px] rounded-md ${"bg-gray-300 text-gray-700"}`}
                onClick={openUploadLogoModal}
              >
                Upload Logo
              </button>
              <div className="relative text-[13px]">
                <button
                  onClick={toggleAddUserDropdown}
                  className="px-3 py-2 bg-green-500 text-white rounded-md flex items-center gap-3"
                >
                  <FaPlus /> Add User
                  <svg
                    className={`w-5 h-5 transform ${
                      isAddUserDropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                {isAddUserDropdownOpen && (
                  <div className="absolute right-0 mt-1 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                    <Link to={"/adminadduserpage"}>
                      <button className="block w-32  py-2 text-gray-700 hover:bg-gray-200">
                        Add User
                      </button>
                    </Link>
                    {/* <Link to="/adminaddconsumerpage">
                      <button className="block px-4 w-full py-2 text-gray-700 hover:bg-gray-200">
                        Add Consumer
                      </button>
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ag-theme-alpine w-full" style={{ height: 750 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            rowSelection="multiple"
            pagination={true}
            paginationPageSize={50}
            frameworkComponents={{ deleteButtonRenderer: deleteButtonRenderer }}
          />
        </div>
      </div>
      {isUploadLogoModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-10">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Upload Logo</h2>
            <input
              type="text"
              value={companyName}
              onChange={handleCompanyNameChange}
              placeholder="Enter Company Name"
              className="mb-4 w-full px-4 py-2 border-2 rounded-xl"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="mb-4"
            />
            <button
              onClick={handleSubmit}
              className="bg-blue-600 text-white px-4 py-2 rounded mr-4"
              disabled={!logoFile}
            >
              Submit
            </button>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded mr-4"
              onClick={fetchCompanyList}
            >
              View Upload
            </button>
            <button
              onClick={closeUploadLogoModal}
              className="bg-red-600 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="ag-theme-alpine w-full" style={{ height: 550 }}>
              <AgGridReact
                rowData={rowviewCompanyData}
                columnDefs={columnCompenyLogoDefs}
                rowSelection="multiple"
                pagination={true}
                paginationPageSize={50}
              />
            </div>
            <button
              className="mt-4 bg-red-600 text-white px-4 py-2 rounded"
              onClick={togglecloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
