import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

export const fetchJewelrySlice = createAsyncThunk( "jewelry/fetchJewelrySlice", async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/users/get-save`,
        { method:'GET' , headers: myHeaders , redirect: "follow", }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch SaveSearch");
      }

      const data = await response.json();
      return data.data;
    } catch (error) {
      throw new Error(`Failed to fetch SaveSearch: ${error.message}`);
    }
  }
);

const saveJewelrySearchSlice = createSlice({
  name: "jewelry",
  initialState: {
    jewelry: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJewelrySlice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJewelrySlice.fulfilled, (state, action) => {
        state.loading = false;
        state.jewelry = action.payload;
      })
      .addCase(fetchJewelrySlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default saveJewelrySearchSlice.reducer;
