import React from 'react'
import AddUser from '../../../adminComponents/adminallusers/adduser/AddUser'
import { AdminDefaultLayout } from '../../../components/layout/adminDefaultLayout/AdminDefaultLayout'

export default function AdminAddNewUserPage() {
  return (
    <AdminDefaultLayout>
        <AddUser />
    </AdminDefaultLayout>
  )
}
