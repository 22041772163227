import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { baseUrl } from '../../utils/url';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export default function AdminAnalytics() {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(`${baseUrl}/admin/analytics`, requestOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setAnalyticsData(data.result);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, []);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy the chart on component unmount
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Prepare data for the bar chart
  const months = [...new Set(analyticsData.map(item => item.month))];
  
  // Get the last 3 months only
  const lastThreeMonths = months.slice(-3);

  const requestItemTypes = [...new Set(analyticsData.map(item => item.request_item_type))];

  const datasets = requestItemTypes.map(type => ({
    label: type,
    data: lastThreeMonths.map(month => {
      const item = analyticsData.find(item => item.month === month && item.request_item_type === type);
      return item ? item.count : 0;
    }),
    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    borderWidth: 1,
  }));

  const chartData = {
    labels: lastThreeMonths,
    datasets: datasets,
  };

  const chartOptions = {
    responsive: true,
    animation: {
      duration: 2000,
      easing: 'easeInOutBounce',
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
        type: 'category',
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h1>Admin Analytics</h1>
      {analyticsData && analyticsData.length > 0 ? (
        <div>
          <div>
            <Bar ref={chartRef} data={chartData} options={chartOptions} />
          </div>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
}
