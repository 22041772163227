import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaClipboardList } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoBagCheck } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdSavedSearch } from "react-icons/md";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { baseUrl, baseUrlLocal } from "../../../utils/url";
import Swal from "sweetalert2";

export default function AdminDashboard() {
  const [slides, setSlides] = useState([]);
  const [requestCount, setRequestCount] = useState([]);
  const [selectedType, setSelectedType] = useState("select");

  useEffect(() => {
    handleAdminImage();
    handleAdminRequestCount();
  }, []);

  console.log("baseUrlLocal",baseUrlLocal)

  const handleAdminRequestCount = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/admin/get-item-request-count`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }

      const data = await response.json();

      setRequestCount(data);
    } catch (error) {
      console.error("Erroe images", error);
    }
  };
  const handleAdminImage = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/get-images?type=web`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }

      const data = await response.json();
      const imageArray = JSON?.parse(data.data.image);
      setSlides(imageArray);


    } catch (error) {
      console.error("Erroe images", error);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      // Show SweetAlert if no files are selected
      Swal.fire({
        icon: "warning",
        title: "No files selected",
        text: "Please choose at least one file to upload.",
        showConfirmButton: true,
        timer: 2000,
      });
      return; // Stop further execution
    }

    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }
    formData.append("type", selectedType);

    // Make the API call to upload the images
    fetch(`${baseUrl}/admin/upload-images`, {
      method: "POST",
      body: formData,
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Image uploaded successfully!",
            showConfirmButton: true,
            timer: 2000,
          });
          return response.json(); // Proceed if the response is successful
        } else {
          throw new Error("Image upload failed");
        }
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an issue uploading the images. Please try again.",
          showConfirmButton: true,
        });
      });
  };


  return (
    <div className="p-4">
      <div className="flex justify-between pb-5 ">
        <h1 className="text-3xl font-bold">Dashboard</h1>
        {/* <Navbar /> */}
      </div>
      <div className="grid grid-cols-5 gap-4 mb-5 ">
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold justify-center hover:text-[#fff] items-center gap-2  border hover:shadow-xl   rounded-lg text-center">
          <Link to="/adminallmemopage">
            <div className="flex flex-col justify-center items-center gap-1">
              <FaClipboardList fontSize={30} />
              Memos Request ({requestCount.memo})*
            </div>
          </Link>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center gap-2 border hover:shadow-xl   rounded-lg text-center">
          <Link to="/adminallinvoicepage">
            <div className="flex flex-col justify-center items-center gap-1">
              <FaFileInvoiceDollar fontSize={30} />
              Invoices Request ({requestCount.invoice})*
            </div>
          </Link>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center gap-2 border hover:shadow-xl   rounded-lg text-center">
          <Link to="/adminallorderspage">
            <div className="flex flex-col justify-center items-center gap-1">
              <IoBagCheck fontSize={30} />
              All Orders ({requestCount.total})*
            </div>
          </Link>
        </div>
        {/* <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center border hover:shadow-xl   rounded-lg textrounded-mdcenter">
          <FaMoneyCheckDollar fontSize={30} />
          Next Payment Due (8204.4)*
          <br />
          <span className="text-base text-gray-500 font-bold">05/01/2024</span>
        </div> */}
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 rounded-lg hover:text-[#fff] border hover:shadow-xl ">
          <Link to="/adminmostsearchpage">
            <span className="flex justify-center flex-col gap-2 items-center text-[18px] w-full h-full">
              <MdSavedSearch fontSize={30} />
              <h2 className="font-bold mb-2">Most Search Items</h2>
            </span>
          </Link>
        </div>
        {/* <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 rounded-lg hover:text-[#fff] border hover:shadow-xl ">
          <Link to="/inventorypage/savesearchpage">
            <span className="flex justify-center flex-col gap-2 items-center text-[18px] w-full h-full">
              <FaUserCheck fontSize={30} />
              <h2 className="font-bold mb-2">All Active Users</h2>
            </span>
          </Link>
        </div> */}
        {/* <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 rounded-lg hover:text-[#fff] border hover:shadow-xl ">
          <Link to="/inventorypage/savesearchpage">
            <span className="flex justify-center flex-col gap-2 items-center text-[18px] w-full h-full">
              <MdOutlinePendingActions fontSize={30} />
              <h2 className="font-bold mb-2">Panding Request</h2>
            </span>
          </Link>
        </div> */}
        {/* <div className="bg-gray-50 hover:bg-[#3b82f680] p-4 rounded-lg border hover:shadow-xl ">
          <h1 className="font-bold mb-2">Recent Searches</h1>
          <ul className="list-disc list-inside">
            <li>All 25/07/2024 04:02 AM</li>
            <li>All 25/07/2024 03:59 AM</li>
            <li>All 25/07/2024 03:59 AM</li>
            <li>BR 23/07/2024 12:51 PM</li>
            <li>All 23/07/2024 12:50 PM</li>
          </ul>
        </div> */}
        {/* <div className="bg-gray-100 hover:bg-[#3b82f680] pt-2 p-10 rounded-lg text-center border hover:shadow-xl ">
          <h2 className="font-bold mb-2">Your personal Sales Executive</h2>
          <p>Test</p>
          <p>Email: test@gmail.com</p>
          <p>Phone: [Your phone number here]</p>
        </div> */}
      </div>
     
      <div className="bg-gray-100 p-4 flex gap-5 rounded-lg arr-btn" >
        <div>
          <Splide
            options={{
              // type: "loop",
              perPage: 1,
              perMove: 1,
              pagination: false,
              autoplay: true,

            }}
          >
            {slides.map((slide, index) => (
              <SplideSlide key={index}>
                <div className="p-4 border rounded-lg bg-white shadow-md">
                  <img
                    src={`${baseUrlLocal}/${slide}`}
                    alt="diamond"
                    className=" h-[350px]"
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
          <div>
            <div className="flex mb-4">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className=" text-transparent file:mr-4 file:py-2 file:px-4 w-[140px]"
              />

              <select
                className="p-2 border border-[#000]"
                value={selectedType}
                onChange={handleTypeChange}
              >
                <option value="select">select</option>
                <option value="web">web</option>
                <option value="mobile">mobile</option>
              </select>

            </div>
            <button
              onClick={handleUpload}
              className="rounded-md w-full py-3 text-white bg-[#3b82f6] hover:bg-[#2867d6]"
            >
              Upload Images
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
