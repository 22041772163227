import React, { useEffect, useState } from "react";
import { CgNotes } from "react-icons/cg";
import { IoIosRefresh } from "react-icons/io";
import { IoSaveOutline, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/url";
import { fetchUserList } from "../../redux/slice/getUser/getUser";
import Swal from "sweetalert2";

export function MainNavbar({ search }) {
  const dispatch = useDispatch();
  const getUser = useSelector((state) => state.user);
  const { savesearch } = useSelector((state) => state.savesearch);

  const userPermission = getUser.user.map((el) => el.lockprice_mode);

  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [adminPopup, setAdminPopup] = useState(false);
  const [markup, setMarkup] = useState("");

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    const formattedQuery = input.replace(/\s+/g, ",");
    setQuery(formattedQuery);
  };

  useEffect(() => {
    dispatch(fetchUserList());
  }, [dispatch]);

  const handleSearchSubmit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const queryArray = query
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "");

      const response = await fetch(`${baseUrl}/users/search/individual`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ value: queryArray, type: search }),
      });

      const responseData = await response.json();
      const responseMessage = responseData.message;

      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      const searchId = responseData.searchId;

      if (search === "diamond") {
        navigate(`/inventorypage/diamondcardpage?searchId=${searchId}`);
      } else {
        navigate(`/inventorypage/jewelerycardpage?searchId=${searchId}`);
      }
    } catch (error) {
      console.error("Error submitting search:", error);
    }
  };

  const handlePopup = () => {
    setAdminPopup(true);
  };

  const handleSubmit = async (priceMode) => {
    const formData = new FormData();
    formData.append("price_mode", priceMode);

    if (priceMode === "markup") {
      formData.append("markup_price", markup);
    }

    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const id = sessionStorage.getItem("id");

      const response = await fetch(`${baseUrl}/admin/approve/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: formData,
      });

      if (response.ok) {
        setAdminPopup(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section>
      <div className="lg:flex lg:justify-between flex justify-end lg:px-10 ">
        <span className="text-[30px] md:block hidden">
          <h1 className="font-semibold capitalize">{search} Search</h1>
        </span>

        <div className="flex lg:gap-5 gap-5 relative">
          <div
            className={`transition-all duration-300 ease-in-out ${showInput ? "w-60 opacity-100" : "w-0 opacity-0"
              } overflow-hidden`}
          >
            <input
              type="text"
              className="px-2 py-2 border rounded-md w-full"
              placeholder="Search..."
              value={query}
              onChange={handleInputChange}
            />
          </div>
          <button
            className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md relative group"
            onClick={() => {
              toggleInput();
              if (showInput) handleSearchSubmit();
            }}
          >
            <IoSearch fontSize={20} />
            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
              Search
            </div>
          </button>

          {userPermission.includes("true") && (
            <button
              className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md relative group"
              onClick={handlePopup}
            >
              <CgNotes fontSize={20} />
              <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Admin Mode
              </div>
            </button>
          )} 

          {adminPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={() => setAdminPopup(false)}
              ></div>
              <div className="bg-white w-[25%] p-5 rounded-md shadow-md z-50">
                <h2 className="text-center mb-6">Admin Mode</h2>
                <div className="border rounded-md mb-2 text-center py-2 hover:bg-blue-500 hover:text-white">
                  <button
                    className="pr-4"
                    onClick={() => handleSubmit("markup")}
                  >
                    Show Markup Price
                  </button>
                  <input
                    type="text"
                    name="markup"
                    value={markup}
                    onChange={(e) => setMarkup(e.target.value)}
                    className="border rounded-md text-black w-20 py-2 px-2"
                  />
                  %
                </div>
                <div className="border rounded-md mb-2 text-center py-4 hover:bg-blue-500 hover:text-white">
                  <button onClick={() => handleSubmit("base")}>
                    Show Base Price
                  </button>
                </div>
                <div className="border rounded-md mb-2 text-center py-4 hover:bg-blue-500 hover:text-white">
                  <button onClick={() => handleSubmit("hide")}>
                    Don't Show Price
                  </button>
                </div>
              </div>
            </div>
          )} 

          <button className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md relative group">
            <Link to="/inventorypage/savesearchpage">
              <div className="relative">
                <span className="absolute z-50 bg-blue-500 text-[13px] text-white rounded-full px-[8px] -top-[20px] -right-6">
                  {savesearch && savesearch.length}
                </span>
                <IoSaveOutline fontSize={20} />
                <div className="absolute  top-10 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                  Save Search
                </div>
              </div>
            </Link>
          </button>
          {/* <button className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md relative group">
            <IoIosRefresh fontSize={20} />
            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
              Refresh
            </div>
          </button> */}
        </div>
      </div>
    </section>
  );
}
