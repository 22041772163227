import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { LuArrowBigLeftDash } from "react-icons/lu";
import { FaCirclePlay } from "react-icons/fa6";
import { TbFileCertificate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiamonds,
  selectDiamonds,
} from "../../../../redux/slice/matchDiamondSlice/matchDiamondSlice";

const parseQueryString = (queryString) => {
  const params = new URLSearchParams(queryString);
  const result = {};
  for (const [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};

export default function DiamondMatch() {
  const location = useLocation();
  const queryParams = parseQueryString(location.search);

  const { Shape, Color, Clarity, Weight, Stock } = queryParams;
  const navigate = useNavigate();
  const stateSearchId = location.state;

  const handlegoproduct = (id) => {
    navigate(`/inventorypage/diamondcardpage/${stateSearchId}/${id}`);
  };
  const handlegoproductchange = () => {
    navigate(`/inventorypage/diamondcardpage?searchId=${stateSearchId}`)
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentMatchSlide, setCurrentMatchSlide] = useState(0);
  const [secondCardIndex, setSecondCardIndex] = useState(1);
  const mediaTypes = ["image", "video", "certificate"];
  const mediaMatchTypes = ["image", "video", "certificate"];

  const dispatch = useDispatch();
  const matchDiamondData = useSelector(selectDiamonds);

  useEffect(() => {
    dispatch(fetchDiamonds({ Shape, Color, Clarity, Weight, Stock }));
  }, [dispatch, Shape, Color, Clarity, Weight, Stock]);

  const handleNextCard = () => {
    setSecondCardIndex(
      (prevIndex) => (prevIndex + 1) % matchDiamondData.length
    );
  };

  const handlePrevCard = () => {
    setSecondCardIndex(
      (prevIndex) =>
        (prevIndex - 1 + matchDiamondData.length) % matchDiamondData.length
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % mediaTypes.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + mediaTypes.length) % mediaTypes.length
    );
  };

  const getMediaContent = (diamondData, currentSlide) => {
    if (!diamondData) {
      return <p>No media available</p>;
    }

    const mediaType = mediaTypes[currentSlide];

    if (
      mediaType === "image" &&
      diamondData["Image Link"] &&
      diamondData["Image Link"] !== "NULL"
    ) {
      return (
        <div className="w-full h-[500px] image-container relative">
          <img
            src={diamondData["Image Link"]}
            alt={diamondData.Title}
            loading="lazy"
            className="rounded-xl  w-full h-full object-cover"
          />
        </div>
      );
    } else if (mediaType === "video" && diamondData["Video Link"]) {
      return (
        <div className="iframe-container">
          <iframe
            src={diamondData["Video Link"]}
            title={diamondData.Title}
            width="500"
            height="500"
            frameBorder="0"
            allowFullScreen
            className="rounded-xl "
          />
        </div>
      );
    } else if (mediaType === "certificate" && diamondData["Certificate Url"]) {

      return (
        <div className="certificate-container">
          <iframe
            src={diamondData["Certificate Url"]}
            title="Certificate"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen
            className="rounded-xl "
          />
        </div>
      );
    }
    return (
      <img
        src="/logo/No Image Found.jpg"
        loading="lazy"
        className="rounded-xl w-full h-full object-cover"
      />
    );
  };
  const handleMatchNextSlide = () => {
    setCurrentMatchSlide(
      (prevSlide) => (prevSlide + 1) % mediaMatchTypes.length
    );
  };

  const handleMatchPrevSlide = () => {
    setCurrentMatchSlide(
      (prevSlide) =>
        (prevSlide - 1 + mediaMatchTypes.length) % mediaMatchTypes.length
    );
  };

  const getMediaMatchContent = (diamondData, currentMatchSlide) => {
    if (!diamondData) {
    
      return <p>No media available</p>;
    }

    const mediaMatchType = mediaMatchTypes[currentMatchSlide];

    if (
      mediaMatchType === "image" &&
      matchDiamondData[secondCardIndex]?.["Image Link"] &&
      matchDiamondData[secondCardIndex]?.["Image Link"] !== "NULL"
    ) {
      return (
        <div className="w-full h-[500px] image-container relative">
          <img
            src={matchDiamondData[secondCardIndex]?.["Image Link"]}
            alt={matchDiamondData[secondCardIndex]?.Title}
            loading="lazy"
            className="rounded-xl  w-full h-full object-cover"
          />
        </div>
      );
    } else if (
      mediaMatchType === "video" &&
      matchDiamondData[secondCardIndex]?.["Video Link"]
    ) {
      return (
        <div className="iframe-container">
          <iframe
            src={matchDiamondData[secondCardIndex]?.["Video Link"]}
            title={matchDiamondData[secondCardIndex]?.Title}
            width="500"
            height="500"
            frameBorder="0"
            allowFullScreen
            className="rounded-xl"
          />
        </div>
      );
    } else if (
      mediaMatchType === "certificate" &&
      matchDiamondData[secondCardIndex]?.["Certificate Url"]
    ) {;
      return (
        <div className="certificate-container">
          <iframe
            src={matchDiamondData[secondCardIndex]?.["Certificate Url"]}
            title="Certificate"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen
            className="rounded-xl"
          />
        </div>
      );
    }

    return (
      <img
        src="/logo/No Image Found.jpg"
        loading="lazy"
        className="rounded-xl w-full h-full object-cover"
      />
    );
  };

  return (
    <section>
      <div className="flex justify-between">
        <div className="p-4 max-w-7xl mx-auto">
          {" "}
          <div className="flex items-center justify-between pb-5 mt-8">
            <button
              className="mb-4 text-black flex items-center gap-2"
              onClick={() => navigate(-1)}
            >
              <span>
                <LuArrowBigLeftDash fontSize={20} />
              </span>
              <span> Back to Search Results</span>
            </button>

            <div className="flex items-center gap-4">
              <button
                className={`px-6 py-2 bg-gray-200 text-gray-800 rounded-md transition-colors duration-300 hover:bg-gray-300 focus:outline-none ${
                  secondCardIndex === 1 && "opacity-50 cursor-not-allowed"
                }`}
                onClick={() => handlePrevCard()}
                disabled={secondCardIndex === 1}
              >
                <FaArrowLeft color="#000" />
              </button>
              <span className="font-semibold">
                {` ${secondCardIndex + 0} of ${
                  matchDiamondData?.length - 1 || 0
                }`}
              </span>
              <button
                className={`px-6 py-2 bg-gray-200 text-gray-800 rounded-md transition-colors duration-300 hover:bg-gray-300 focus:outline-none ${
                  secondCardIndex === matchDiamondData?.length - 1 &&
                  "opacity-50 cursor-not-allowed"
                }`}
                onClick={() => handleNextCard()}
                disabled={secondCardIndex === matchDiamondData?.length - 1}
              >
                <FaArrowRight color="#000" />
              </button>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="lg:w-1/2 p-5 ">
              <div className="border-b-2 pb-3 mb-4">
                <div className="font-semibold text-2xl">
                  {matchDiamondData && matchDiamondData[0]?.Shape}{" "}
                  {matchDiamondData && matchDiamondData[0]?.["Weight"]}ct{" "}
                  {matchDiamondData &&
                    (matchDiamondData[0]?.["Fancy Color Intensity"] || "")}{" "}
                  {matchDiamondData &&
                    (matchDiamondData[0]?.["Fancy Color Overtone"] || "")}{" "}
                  {matchDiamondData &&
                    (matchDiamondData[0]?.["Fancy Color"] ||
                      matchDiamondData[0]?.Color)}{" "}
                  {matchDiamondData && matchDiamondData[0]?.["Clarity"]}
                </div>
                <div className="flex gap-2 justify-between">
                  <div>
                    <span className="text-lg">
                      Stock #{" "}
                      {matchDiamondData && matchDiamondData[0]?.["Stock #"]}
                    </span>
                  </div>
                  <div className="cursor-pointer">
                    <span
                      className="font-semibold text-blue-500 underline border-r-2 px-3 "
                      onClick={handlegoproductchange}
                    >
                      change
                    </span>
                    <span
                      className="font-semibold pl-3 text-blue-500 underline"
                      onClick={() => handlegoproduct(matchDiamondData[0].id)}
                    >
                      Go To Product
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 pb-5 border-b-2 mb-4">
                <div className="flex flex-col gap-1">
                  <span>Status</span>
                  <span>P/ct</span>
                  <span>Discount</span>
                  <span>Total Price</span>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-semibold">
                    {matchDiamondData &&
                      (matchDiamondData[0]?.["Availability"] || "-")}
                  </span>
                  <span className="font-semibold">
                    ${matchDiamondData && matchDiamondData[0]?.["Price"]}
                  </span>
                  <span className="font-semibold">
                    {matchDiamondData &&
                      matchDiamondData[0]?.["Discount Percent"]}
                    %
                  </span>
                  <span className="font-semibold">
                    $
                    {(
                      parseFloat(
                        matchDiamondData && matchDiamondData[0]?.["Total Price"]
                      ) || 0
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="w-full image-container relative">
                {getMediaContent(
                  matchDiamondData && matchDiamondData[0],
                  currentSlide
                )}
                <button
                  className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                  onClick={handlePrevSlide}
                >
                  <FaArrowLeft color="#000" />
                </button>
                <button
                  className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                  onClick={handleNextSlide}
                >
                  <FaArrowRight color="#000" />
                </button>
              </div>
              <div className="flex mt-4 gap-2 relative">
                {mediaTypes.map((type, index) => (
                  <div
                    key={type}
                    className={`w-20 h-20 relative cursor-pointer ${
                      currentSlide === index
                        ? "border-2 border-blue-500 rounded-xl"
                        : ""
                    }`}
                    onClick={() => setCurrentSlide(index)}
                  >
                    {type === "video" && (
                      <span className="z-50 flex absolute left-5 justify-center items-center h-full">
                        <FaCirclePlay fontSize={40} color="#fff" />
                      </span>
                    )}
                    {type === "certificate" && (
                      <span className="z-50 flex justify-center items-center h-full">
                        <TbFileCertificate
                          fontSize={80}
                          color="rgb(148 158 169)"
                        />
                      </span>
                    )}
                    {(type === "image" || type === "video") &&
                      matchDiamondData && (
                        <img
                          src={matchDiamondData[0]?.["Image Link"]}
                          alt={matchDiamondData[0]?.["Image Link"]}
                          className="rounded-xl h-full w-full object-cover -z-30 bg-black"
                        />
                      )}
                    {type === "video" && matchDiamondData && (
                      <video
                        src={matchDiamondData[0]?.["Video Link"]}
                        alt={matchDiamondData[0]?.["Video Link"]}
                        className="rounded-xl object-cover -z-30 "
                      />
                    )}
                  </div>
                ))}
              </div>

              <div className="border-b-2 pb-3 my-4">
                <h3 className="font-semibold mb-5 text-[18px]">
                  Product Details
                </h3>
                <div className="grid grid-cols-2 gap-5">
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Lab</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.Lab}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Report #</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Certificate #"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Shape</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.Shape}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Carat</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.["Weight"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Color</span>
                      <span>
                        {matchDiamondData &&
                          (matchDiamondData[0]?.["Fancy Color Intensity"] ||
                            "")}{" "}
                        {matchDiamondData &&
                          (matchDiamondData[0]?.["Fancy Color Overtone"] ||
                            "")}{" "}
                        {matchDiamondData &&
                          (matchDiamondData[0]?.["Fancy Color"] ||
                            matchDiamondData[0]?.Color)}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Clarity</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.Clarity}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Cut</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.["Cut Grade"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Polish</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.Polish}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Sym</span>
                      <span>
                        {matchDiamondData && matchDiamondData[0]?.["Symmetry"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Depth</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Depth Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Table</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Table Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Measurements</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Measurements"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Fluor</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Fluorescence Intensity"]}{" "}
                        -{" "}
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Fluorescence Color"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Depth %</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Depth Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Table %</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[0]?.["Table Percent"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 border p-5 shadow-2xl rounded-2xl">
              <div className="border-b-2 pb-3 mb-4">
                <div className="font-semibold text-2xl">
                  {matchDiamondData && matchDiamondData[secondCardIndex]?.Shape}{" "}
                  {matchDiamondData &&
                    matchDiamondData[secondCardIndex]?.["Weight"]}
                  ct{" "}
                  {matchDiamondData &&
                    (matchDiamondData[secondCardIndex]?.[
                      "Fancy Color Intensity"
                    ] ||
                      "")}{" "}
                  {matchDiamondData &&
                    (matchDiamondData[secondCardIndex]?.[
                      "Fancy Color Overtone"
                    ] ||
                      "")}{" "}
                  {matchDiamondData &&
                    (matchDiamondData[secondCardIndex]?.["Fancy Color"] ||
                      matchDiamondData[secondCardIndex]?.Color)}{" "}
                  {matchDiamondData &&
                    matchDiamondData[secondCardIndex]?.["Clarity"]}
                </div>
                <div className="flex gap-2 justify-between cursor-pointer ">
                  <div>
                    <span className="text-lg">
                      Stock #{" "}
                      {matchDiamondData &&
                        matchDiamondData[secondCardIndex]?.["Stock #"]}
                    </span>
                  </div>
                  <div>
                    <span
                      className="font-semibold pl-3 text-blue-500 underline"
                      onClick={() =>
                        handlegoproduct(matchDiamondData[secondCardIndex].id)
                      }
                    >
                      Go To Product
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 pb-5 border-b-2 mb-4">
                <div className="flex flex-col gap-1">
                  <span>Status</span>
                  <span>P/ct</span>
                  <span>Discount</span>
                  <span>Total Price</span>
                </div>

                <div className="flex flex-col gap-1">
                  <span className="font-semibold">
                    {matchDiamondData &&
                      (matchDiamondData[secondCardIndex]?.["Availability"] ||
                        "-")}
                  </span>
                  <span className="font-semibold">
                    $
                    {matchDiamondData &&
                      matchDiamondData[secondCardIndex]?.["Price"]}
                  </span>
                  <span className="font-semibold">
                    {matchDiamondData &&
                      matchDiamondData[secondCardIndex]?.["Discount Percent"]}
                    %
                  </span>
                  <span className="font-semibold">
                    $
                    {(
                      parseFloat(
                        matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Total Price"]
                      ) || 0
                    ).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="w-full image-container relative">
                {getMediaMatchContent(
                  matchDiamondData && matchDiamondData[secondCardIndex],
                  currentMatchSlide
                )}
                <button
                  className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                  onClick={handleMatchPrevSlide}
                >
                  <FaArrowLeft color="#000" />
                </button>
                <button
                  className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                  onClick={handleMatchNextSlide}
                >
                  <FaArrowRight color="#000" />
                </button>
              </div>
              <div className="flex mt-4 gap-2 relative">
                {mediaMatchTypes.map((type, index) => (
                  <div
                    key={type}
                    className={`w-20 h-20 relative cursor-pointer ${
                      currentMatchSlide === index
                        ? "border-2 border-blue-500"
                        : ""
                    }`}
                    onClick={() => setCurrentMatchSlide(index)}
                  >
                    {type === "video" && (
                      <span className="z-50 flex absolute left-5 justify-center items-center h-full">
                        <FaCirclePlay fontSize={40} color="#fff" />
                      </span>
                    )}
                    {type === "certificate" && (
                      <span className="z-50 flex justify-center items-center h-full">
                        <TbFileCertificate
                          fontSize={80}
                          color="rgb(148 158 169)"
                        />
                      </span>
                    )}

                    {(type === "image" || type === "video") &&
                      matchDiamondData && (
                        <img
                          src={
                            matchDiamondData[secondCardIndex]?.["Image Link"]
                          }
                          alt={
                            matchDiamondData[secondCardIndex]?.["Image Link"]
                          }
                          className="rounded-xl h-full w-full object-cover -z-30 bg-black"
                        />
                      )}

                    {type === "video" && matchDiamondData && (
                      <video
                        src={matchDiamondData[secondCardIndex]?.["Video Link"]}
                        alt={matchDiamondData[secondCardIndex]?.["Video Link"]}
                        className="rounded-xl object-cover -z-30 "
                        F
                      />
                    )}
                  </div>
                ))}
              </div>

              <div className="border-b-2 pb-3 my-4">
                <h3 className="font-semibold mb-5 text-[18px]">
                  Product Details
                </h3>
                <div className="grid grid-cols-2 gap-5">
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Lab</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.Lab}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Report #</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Certificate #"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Shape</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.Shape}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Carat</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Weight"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Color</span>
                      <span>
                        {matchDiamondData &&
                          (matchDiamondData[secondCardIndex]?.[
                            "Fancy Color Intensity"
                          ] ||
                            "")}{" "}
                        {matchDiamondData &&
                          (matchDiamondData[secondCardIndex]?.[
                            "Fancy Color Overtone"
                          ] ||
                            "")}{" "}
                        {matchDiamondData &&
                          (matchDiamondData[secondCardIndex]?.["Fancy Color"] ||
                            matchDiamondData[secondCardIndex]?.Color)}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Clarity</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.Clarity}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Cut</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Cut Grade"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Polish</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.Polish}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Sym</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Symmetry"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Depth</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Depth Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Table</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Table Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Measurements</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Measurements"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Fluor</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.[
                            "Fluorescence Intensity"
                          ]}{" "}
                        -{" "}
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.[
                            "Fluorescence Color"
                          ]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Depth %</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Depth Percent"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="font-semibold">Table %</span>
                      <span>
                        {matchDiamondData &&
                          matchDiamondData[secondCardIndex]?.["Table Percent"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx>{`
          .image-container {
            position: relative;
            width: auto;
            height: auto;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </div>
    </section>
  );
}
