import React, { useEffect, useState } from "react";

import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSaveSearchSlice } from "../../../../redux/slice/savesearchSlice/savesearchSlice";
import { baseUrl } from "../../../../utils/url";
import { IoSaveOutline, IoSearch } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { IoIosRefresh } from "react-icons/io";
import Swal from "sweetalert2";
import { DiamondData } from "../../../../components/Inventory/Search/diamondssearch/DiamondData";
import Modal from "../../../../components/Inventory/Search/savesearch/Modal/Modal";
import { DefaultLayout } from "../../../../components/layout/defaultLayout";
import { MainNavbar } from "../../../../components/mainnavbar/MainNavbar";
import { AdminDefaultLayout } from "../../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import { AdminMainNavbar } from "../adminmainnavbar/adminMainNavbar";


export default function AdminDiamondsSearch() {
  const navigate = useNavigate();
  const location = useLocation();

  const data = location?.state?.data.parsedCriteria;

  const [selectedDiamondCts, setSelectedDiamondCts] = useState(
    data?.Weight || []
  );
  const [selectedStoneShapes, setSelectedStoneShapes] = useState(
    data?.Shape || []
  );
  const [selectedAvailability, setSelectedAvailability] = useState(
    data?.Availability || []
  );
  const [selectedSymmetry, setSelectedSymmetry] = useState(
    data?.Symmetry || []
  );
  const [selectedColor, setSelectedColor] = useState(data?.color || []);
  const [selectedF_Color, setSelectedF_Color] = useState(
    data?.["Fancy Color"] || []
  );
  const [selectedClarity, setSelectedClarity] = useState(data?.Clarity || []);
  const [selectedCut_Grade, setSelectedCut_Grade] = useState([]);
  const [selectedPolish, setSelectedPolish] = useState(data?.Polish || []);
  const [selectedReport, setSelectedReport] = useState(data?.Lab || []);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showAllStoneShapes, setShowAllStoneShapes] = useState(false);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [ratioFrom, setRatioFrom] = useState("");
  const [ratioTo, setRatioTo] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [showAllClarity, setShowAllClarity] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [diamondCtFrom, setDiamondCtFrom] = useState("");
  const [diamondCtTo, setDiamondCtTo] = useState("");
  const [lengthMin, setLengthMin] = useState("");
  const [lengthMax, setLengthMax] = useState("");

  // State variables for Width
  const [widthMin, setWidthMin] = useState("");
  const [widthMax, setWidthMax] = useState("");

  // State variables for Height
  const [heightMin, setHeightMin] = useState("");
  const [heightMax, setHeightMax] = useState("");

  // Handlers for Length
  const handleLengthMinChange = (e) => setLengthMin(e.target.value);
  const handleLengthMaxChange = (e) => setLengthMax(e.target.value);

  // Handlers for Width
  const handleWidthMinChange = (e) => setWidthMin(e.target.value);
  const handleWidthMaxChange = (e) => setWidthMax(e.target.value);

  // Handlers for Height
  const handleHeightMinChange = (e) => setHeightMin(e.target.value);
  const handleHeightMaxChange = (e) => setHeightMax(e.target.value);

  const handleDiamondCtFromChange = (e) => {
    setDiamondCtFrom(e.target.value);
  };

  const handleDiamondCtToChange = (e) => {
    setDiamondCtTo(e.target.value);
  };

  const isWithinRange = (item) => {
    const from = parseFloat(diamondCtFrom);
    const to = parseFloat(diamondCtTo);
    const diamondCt = parseFloat(item);

    if (!isNaN(from) && !isNaN(to)) {
      return diamondCt >= from && diamondCt <= to;
    } else if (!isNaN(from)) {
      return diamondCt >= from;
    } else if (!isNaN(to)) {
      return diamondCt <= to;
    }
    return false;
  };

  const search = "diamond";
  const toggleShowAll = () => setShowAll(!showAll);

  const itemsToShow = showAll
    ? DiamondData.Color
    : DiamondData.Color.slice(0, 7);

  const toggleShowAllClarity = () => setShowAllClarity(!showAllClarity);

  const itemsToShowClarity = showAllClarity
    ? DiamondData.Clarity
    : DiamondData.Clarity.slice(0, 7);

  const dispatch = useDispatch();
  const { savesearch, loading, error } = useSelector(
    (state) => state.savesearch
  );

  useEffect(() => {
    dispatch(fetchSaveSearchSlice());
  }, [dispatch]);

  const handleFromChange = (e) => {
    setPriceFrom(e.target.value);
  };

  const handleToChange = (e) => {
    setPriceTo(e.target.value);
  };

  const handleRatioFromChange = (e) => {
    setRatioFrom(e.target.value);
  };
  const handleRatioToChange = (e) => {
    setRatioTo(e.target.value);
  };

  const toggleSelection = (value, selectedValues, setSelectedValues) => {
    setSelectedValues((prevState) =>
      prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value]
    );
  };

  const toggleShowAllStoneShapes = () => {
    setShowAllStoneShapes(!showAllStoneShapes);
  };

  const handleSaveSearch = async () => {
    const id = sessionStorage.getItem("id");
    const locationMap = {
      "New York": "NY Office",
      "Los Angeles": "LA Office",
    };
    const requestData = {
      criteria: {
        weight: selectedDiamondCts,
        shape: selectedStoneShapes,
        availability: selectedAvailability,
        lab: selectedReport,
        "Department Account Name": selectedLocation.map((location) => locationMap[location]),
        color: selectedColor,
        "fancy Color": selectedF_Color,
        polish: selectedPolish,
        "cut Grade": selectedCut_Grade,
        clarity: selectedClarity,
        symmetry: selectedSymmetry,
      },
      user_id: id,
      save_list: true,
      save_name: name,
      type: "diamond",
    };

    if (priceFrom) {
      requestData.criteria["price"] = [`${priceFrom}-${priceTo}`];
    }
    if (ratioFrom || ratioTo) {
      const ratioRange = `${ratioFrom || ""}-${ratioTo || ""}`;
      requestData["ratio"] = [ratioRange];
    }

    if (diamondCtFrom || diamondCtTo) {
      requestData.Weight = [`${diamondCtFrom}-${diamondCtTo}`];
    }

    const filteredRequestData = JSON.parse(
      JSON.stringify(requestData, (key, value) => {
        if (value === null || value === undefined || value.length === 0) {
          return undefined;
        }
        return value;
      })
    );

    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(filteredRequestData),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/add-to-table`,
        requestOptions
      );

      const responseData = await response.json();
      const responseMessage = responseData.message;

      if (response.ok) {
        dispatch(fetchSaveSearchSlice());
        setIsModalOpen(false);
        Swal.fire({
          icon: "success",
          text: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error updating like status:", error);
      Swal.fire({
        icon: "error",
        title:
          "An error occurred. Please check your network connection and try again.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const visibleStoneShapes = showAllStoneShapes
    ? DiamondData.stoneshape
    : DiamondData.stoneshape.slice(0, 11);

    const validateMinMax = (minValue, maxValue, fieldName) => {
        if ((minValue && !maxValue) || (maxValue && !minValue)) {
          Swal.fire(`Please enter both min and max values for ${fieldName}.`);
          return false;
        }
        return true;
      };

  const handleSubmit = async () => {

    if (
        !validateMinMax(lengthMin, lengthMax, "length") ||
        !validateMinMax(widthMin, widthMax, "width") ||
        !validateMinMax(heightMin, heightMax, "height") ||
        !validateMinMax(priceFrom, priceTo, "price") ||
        !validateMinMax(diamondCtFrom, diamondCtTo, "diamond weight") ||
        !validateMinMax(ratioFrom, ratioTo, "ratio")
      ) {
        return; // Exit if validation fails
      }
      const locationMap = {
        "New York": "NY Office",
        "Los Angeles": "LA Office",
      };
   

    const requestData = {
      Weight: selectedDiamondCts,
      "antique shape": selectedStoneShapes,
      color: selectedColor,
      Availability: selectedAvailability,
      Lab: selectedReport,
      "Department Account Name": selectedLocation.map((location) => locationMap[location]),
      "Fancy Color": selectedF_Color,
      Polish: selectedPolish,
      "Cut Grade": selectedCut_Grade,
      Clarity: selectedClarity,
      Symmetry: selectedSymmetry,
    };

    const measurements = {};

    // Add valid length, width, and height ranges to measurements
    if (lengthMin && lengthMax) {
        measurements.length = [`${lengthMin}-${lengthMax}`];
    }
    if (widthMin && widthMax) {
        measurements.width = [`${widthMin}-${widthMax}`];
    }
    if (heightMin && heightMax) {
        measurements.height = [`${heightMin}-${heightMax}`];
    }

    // Only add measurements if there are valid properties
    if (Object.keys(measurements).length > 0) {
        requestData.measurements = measurements;
    }

    if (priceFrom && priceTo) {
      requestData["Total Price"] = [`${priceFrom}-${priceTo}`];
    }

    if (diamondCtFrom && diamondCtTo) {
      requestData.Weight = [`${diamondCtFrom}-${diamondCtTo}`];
    }

    // Add ratio to the requestData
    if (ratioFrom && ratioTo) {
      const ratioRange = `${ratioFrom}-${ratioTo}`;
      requestData["ratio"] = [ratioRange];
    }

    const filteredRequestData = Object.fromEntries(
      Object.entries(requestData).filter(
        ([key, value]) => value !== null && value.length !== 0
      )
    );

    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/users/search`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(filteredRequestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const searchId = data.searchId;

      navigate(`/admininventorypage/admindiamondcardpage?searchId=${searchId}`);
    } catch (error) {
      console.error("Failed to submit search:", error);
    }
  };

  const handleReset = () => {
    setSelectedDiamondCts([]);
    setSelectedStoneShapes([]);
    setSelectedAvailability([]);
    setSelectedReport([]);
    setSelectedColor([]);
    setSelectedF_Color([]);
    setSelectedClarity([]);
  };

  return (
    <>
      <section className="lg:p-4 uppercase">
        <AdminMainNavbar search={search} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3">
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Stone Shape</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {visibleStoneShapes.map(({ icon, text }) => (
                <button
                  key={text}
                  className={`w-28 h-auto p-1 text-[15px]  rounded flex flex-col gap-2 items-center transition-all duration-300 ease-in-out ${
                    selectedStoneShapes.includes(text)
                      ? "!bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      text,
                      selectedStoneShapes,
                      setSelectedStoneShapes
                    )
                  }
                >
                  <img
                    src={icon}
                    alt={text}
                    width={40}
                    height={80}
                    className={`${
                      selectedStoneShapes.includes(text) ? "filter invert" : ""
                    }`}
                  />
                  {text}
                </button>
              ))}
              {DiamondData.stoneshape.length > 11 && (
                <button
                  onClick={toggleShowAllStoneShapes}
                  className="w-28 h-auto p-1 text-[15px]  rounded flex pt-6 flex-col gap-2 items-center bg-gray-200 hover:!bg-blue-500"
                >
                  {showAllStoneShapes ? (
                    <LuMinus fontSize={30} />
                  ) : (
                    <GoPlus fontSize={30} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Diamond Ct</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              <div className="flex flex-col gap-5">
                <div className="flex gap-1">
                  <input
                    type="number"
                    className="px-2 w-20 py-1 border rounded-md"
                    placeholder="From"
                    min="0"
                    value={diamondCtFrom}
                    onChange={handleDiamondCtFromChange}
                  />
                  <input
                    type="number"
                    className="px-2 w-20 py-1 border rounded-md"
                    placeholder="To"
                    min="0"
                    value={diamondCtTo}
                    onChange={handleDiamondCtToChange}
                  />
                </div>
              </div>
              {DiamondData.diamondCts.map((item) => (
                <button
                  key={item}
                  className={`py-2 text-[15px] px-3  rounded min-w-[60px] transition-all duration-300 ease-in-out ${
                    selectedDiamondCts.includes(item) || isWithinRange(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      item,
                      selectedDiamondCts,
                      setSelectedDiamondCts
                    )
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Color</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {itemsToShow.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedColor.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedColor, setSelectedColor)
                  }
                >
                  {item}
                </button>
              ))}
              {DiamondData.Color.length > 6 && (
                <button
                  className="px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out bg-gray-200"
                  onClick={toggleShowAll}
                >
                  {showAll ? (
                    <LuMinus fontSize={25} />
                  ) : (
                    <GoPlus fontSize={25} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">F_Color</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {DiamondData.F_Color.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedF_Color.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedF_Color, setSelectedF_Color)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Clarity</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {itemsToShowClarity.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedClarity.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedClarity, setSelectedClarity)
                  }
                >
                  {item}
                </button>
              ))}
              {DiamondData.Clarity.length > 7 && (
                <button
                  className="px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out bg-gray-200"
                  onClick={toggleShowAllClarity}
                >
                  {showAllClarity ? (
                    <LuMinus fontSize={25} />
                  ) : (
                    <GoPlus fontSize={25} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Price</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="From"
                    value={priceFrom}
                    onChange={handleFromChange}
                  />
                  <span className="absolute right-2 top-2 text-gray-500 text-[12px] mt-1">
                    USD
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="To"
                    value={priceTo}
                    onChange={handleToChange}
                  />
                  <span className="absolute text-center right-2 top-2 text-gray-500 text-[12px] mt-1">
                    USD
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Ratio</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MIN"
                    value={ratioFrom}
                    onChange={handleRatioFromChange}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MAX"
                    value={ratioTo}
                    onChange={handleRatioToChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Measurements</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              {/* Length */}
              <div className="flex flex-col gap-2">
                <h3 className="font-medium text-[16px]">Length</h3>
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MIN"
                    value={lengthMin}
                    onChange={handleLengthMinChange}
                  />
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MAX"
                    value={lengthMax}
                    onChange={handleLengthMaxChange}
                  />
                </div>
              </div>

              {/* Width */}
              <div className="flex flex-col gap-2">
                <h3 className="font-medium text-[16px]">Width</h3>
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MIN"
                    value={widthMin}
                    onChange={handleWidthMinChange}
                  />
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MAX"
                    value={widthMax}
                    onChange={handleWidthMaxChange}
                  />
                </div>
              </div>

              {/* Height */}
              <div className="flex flex-col gap-2">
                <h3 className="font-medium text-[16px]">Height</h3>
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MIN"
                    value={heightMin}
                    onChange={handleHeightMinChange}
                  />
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="MAX"
                    value={heightMax}
                    onChange={handleHeightMaxChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
          <h2 className="font-bold text-[18px]">Cut_Grade</h2>
          <div className="flex flex-wrap col-span-7 gap-2">
            {DiamondData.Cut_Grade.map((item) => (
              <button
                key={item}
                className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                  selectedCut_Grade.includes(item)
                    ? "bg-blue-500 text-white shadow-lg"
                    : "bg-gray-200"
                }`}
                onClick={() =>
                  toggleSelection(item, selectedCut_Grade, setSelectedCut_Grade)
                }
              >
                {item}
              </button>
            ))}
          </div>
        </div> */}
          {/* <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
          <h2 className="font-bold text-[18px]">Polish</h2>
          <div className="flex flex-wrap col-span-7 gap-2">
            {DiamondData.Polish.map((item) => (
              <button
                key={item}
                className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                  selectedPolish.includes(item)
                    ? "bg-blue-500 text-white shadow-lg"
                    : "bg-gray-200"
                }`}
                onClick={() =>
                  toggleSelection(item, selectedPolish, setSelectedPolish)
                }
              >
                {item}
              </button>
            ))}
          </div>
        </div> */}
          {/* <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
          <h2 className="font-bold text-[18px]">Symmetry</h2>
          <div className="flex flex-wrap col-span-7 gap-2">
            {DiamondData.Symmetry.map((item) => (
              <button
                key={item}
                className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                  selectedSymmetry.includes(item)
                    ? "bg-blue-500 text-white shadow-lg"
                    : "bg-gray-200"
                }`}
                onClick={() =>
                  toggleSelection(item, selectedSymmetry, setSelectedSymmetry)
                }
              >
                {item}
              </button>
            ))}
          </div>
        </div> */}
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">availability</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {DiamondData.Availability.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedAvailability.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      item,
                      selectedAvailability,
                      setSelectedAvailability
                    )
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">report</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {DiamondData.report.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedReport.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedReport, setSelectedReport)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">location</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {DiamondData.location.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedLocation.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedLocation, setSelectedLocation)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={handleSubmit}
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded mr-2"
          >
            Search
          </button>
          <button
            onClick={() => setIsModalOpen(true)} // Open the modal
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded mr-2"
          >
            Save Search
          </button>
          <button
            onClick={handleReset}
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded"
          >
            Reset
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveSearch}
          setName={setName}
          name={name}
        />
      </section>
    </>
  );
}
