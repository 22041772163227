import React, { useState } from "react";
import { baseUrl } from "../../../utils/url";
import Swal from "sweetalert2";

export default function AdminAddConsumer() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    password: "",
    email: "",
    phone: "",
    role_id: "",
    priceMode: "",
    lockPriceMode: false,
    allowBuyMemoHoldRequests: false,
    markupAmount: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataObj.append(key, formData[key]);
    });

    try {
      const response = await fetch(`${baseUrl}/users/register_user`, {
        method: "POST",
        body: formDataObj,
      });

      const responseData = await response.json();
      const responseMessage = responseData.message

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
        setFormData({
          first_name: "",
          last_name: "",
          user_name: "",
          password: "",
          email: "",
          phone: "",
          role_id: "",
          priceMode: "",
          lockPriceMode: false,
          allowBuyMemoHoldRequests: false,
          markupAmount: "",
        })
      } else {
        Swal.fire({
          icon: "error",
          title: responseData,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the user.");
    }
  };

  return (
    <section className="p-6 bg-gray-100 min-h-full flex justify-center items-center">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800">
          Add User Details
        </h1>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <h2 className="text-xl font-semibold mb-4 text-gray-700">
              Personal Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                "first_name",
                "last_name",
                "user_name",
                "password",
                "email",
                "phone",
              ].map((field) => (
                <div key={field}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-600"
                  >
                    {field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </label>
                  <input
                    type={
                      field === "password"
                        ? "password"
                        : field === "email"
                          ? "email"
                          : "text"
                    }
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <h2 className="text-xl font-semibold mb-4 text-gray-700">
                Role Id Settings
              </h2>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label
                    htmlFor="role_id"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Role Id
                  </label>
                  <select
                    id="role_id"
                    name="role_id"
                    value={formData.role_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value="">Select Role Id</option>
                    <option value="1">User</option>
                    <option value="2">Admin</option>
                    <option value="3">SuperAdmin</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-4 text-gray-700">
                Price Mode Settings
              </h2>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label
                    htmlFor="priceMode"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Price Modes
                  </label>
                  <select
                    id="priceMode"
                    name="priceMode"
                    value={formData.priceMode}
                    onChange={handleChange}
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value="">Select Price Mode</option>
                    <option value="base">Base Prices</option>
                    <option value="hide">Hide Prices</option>
                    <option value="markup">Show Markup Prices</option>
                  </select>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="lockPriceMode"
                    name="lockPriceMode"
                    checked={formData.lockPriceMode}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="lockPriceMode"
                    className="ml-2 block text-sm font-medium text-gray-600"
                  >
                    Lock user to selected price mode
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="allowBuyMemoHoldRequests"
                    name="allowBuyMemoHoldRequests"
                    checked={formData.allowBuyMemoHoldRequests}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="allowBuyMemoHoldRequests"
                    className="ml-2 block text-sm font-medium text-gray-600"
                  >
                    App users can make Buy/Memo/Hold Requests
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="markupAmount"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Markup Amount
                  </label>
                  <input
                    type="number"
                    id="markupAmount"
                    name="markupAmount"
                    value={formData.markupAmount}
                    onChange={handleChange}
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <button
              type="submit"
              className="px-6 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
