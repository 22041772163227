import React from "react";
import Contact_Us from "../../../components/Contact_Us/Contact_Us";
import { DefaultLayout } from "../../../components/layout/defaultLayout";

export default function Contact_UsPage() {
  return (
    <DefaultLayout>
      <Contact_Us />
    </DefaultLayout>
  );
}
