import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

export const fetchRequestData = createAsyncThunk(
  "requests/fetchRequestData",
  async ({ id, isBulk }) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const queryParam = isBulk ? `bulk_id=${id}` : `id=${id}`;
      const response = await fetch(`${baseUrl}/admin/requests?${queryParam}`, {
        method: "GET",
        headers: myHeaders,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch request data");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch request data: ${error.message}`);
    }
  }
);


const requestsSlice = createSlice({
  name: "requests",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRequestData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRequestData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default requestsSlice.reducer;
