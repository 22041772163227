import React from 'react'
import Compare from '../../components/Compare/Compare'
import { DefaultLayout } from '../../components/layout/defaultLayout'

export default function ComparePage() {
  return (
    <DefaultLayout>
        <Compare />
    </DefaultLayout>
  )
}
