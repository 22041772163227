
import React from "react";
import { DefaultLayout } from "../../../components/layout/defaultLayout";
import AddCart from "../../../components/Inventory/Search/addcart/AddCart";

export default function AddCartPage() {
  return (
    <DefaultLayout>
      <AddCart />
    </DefaultLayout>
  );
}
