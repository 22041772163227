import React, { useEffect, useState ,useRef} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAdminAllInvoice } from "../../redux/slice/adminallinvoice/AdminAllInvoice";
import { fetchadminallmemo } from "../../redux/slice/adminallmemo/adminallmemoSlice";
import { fetchAdminAllOrders } from "../../redux/slice/adminallorders/AdminAllOrders";
import { fetchadminallhold } from "../../redux/slice/adminallhold/AdminallHold";
import { Button } from "@mui/material";
import { fetchRequestData } from "../../redux/slice/adminrequest/AdminRequestSlice";

const AdminRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridApi = useRef(null);

  const { data: allorderData, status: allOrderStatus } = useSelector((state) => state.allorderpage);
  const { data: memoData, status: memoStatus } = useSelector((state) => state.allmemo);
  const { data: invoiceData, status: invoiceStatus } = useSelector((state) => state.allinvoice);
  const { data: holdData, status: holdStatus } = useSelector((state) => state.allhold);
  const [diamondfilter, setDiamondFilter] = useState("All Types");
  const [jewelryfilter, setJewelryFilter] = useState("All Types");
  const [diamondLoading, setDiamondLoading] = useState(false);
  const [jewelryLoading, setJewelryLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAdminAllOrders());
  }, [dispatch]);



  const rowDatadiamond = 
    diamondfilter === "All Types"
      ? allorderData.filter((item) => item.type === "diamond")
      : diamondfilter === "Invoice"
      ? invoiceData.filter((item) => item.type === "diamond")
      : diamondfilter === "Memo"
      ? memoData.filter((item) => item.type === "diamond")
      : diamondfilter === "Hold"
      ? holdData.filter((item) => item.type === "diamond")
      : []


  const rowDatajewelry = 
    jewelryfilter === "All Types"
      ? allorderData.filter((item) => item.type === "jewelry")
      : jewelryfilter === "Invoice"
      ? invoiceData.filter((item) => item.type === "jewelry")
      : jewelryfilter === "Memo"
      ? memoData.filter((item) => item.type === "jewelry")
      : jewelryfilter === "Hold"
      ? holdData.filter((item) => item.type === "jewelry")
      : []
  
  const columnDefs = [
    {
      headerName: "Request_ID",
      field: "id",
      width: 120,
      cellRenderer: (params) => {
        return (
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    { headerName: "Request", field: "request_type", width: 100 },
    {
      headerName: "Status",
      field: "status",
      width: 120,
      cellStyle: (params) => {
        if (params.value === "pending") {
          return {
            color: "#FFAF00",
            textTransform: "capitalize",
            fontWeight: "700",
          };
        } else if (params.value === "accept") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "partially accepted") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "decline") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "withdraw") {
          return {
            color: "#3b71ca",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "expire") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        }
        return null;
      },
    },
    { headerName: "Request Type", field: "request_item_type", width: 120 },
    { headerName: "Item Details", field: "item_details", width: 120 },

    { headerName: "Type", field: "type", width: 120 },
    { headerName: "Comments", field: "comments", width: 220 },
    { headerName: "Company Name", field: "company", width: 120 },
    { headerName: "Buyer", field: "buyer", width: 120 },
    { headerName: "Request Portal", field: "request_portal", width: 120 },
    {
      headerName: "Date Requested At",
      field: "created_at",
      width: 220,
      cellRenderer: (params) => {
        let dateStr = params.value.replace(" ", "T").replace("T24:", "T00:");
        let date = new Date(dateStr);
        if (params.value.includes(" 24:")) date.setDate(date.getDate() + 1);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
    {
      headerName: "Updated",
      field: "updated_at",
      width: 200,
      cellRenderer: (params) => {
        let dateStr = params.value.replace(" ", "T").replace("T24:", "T00:");
        let date = new Date(dateStr);
        if (params.value.includes(" 24:")) date.setDate(date.getDate() + 1);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
  ];

  const handleCellClicked = (event) => {
    if (event.colDef.field === "id") {
      let id = event.value;
      const isBulk = event.data.request_type === "bulk";
  
      // Check if the id starts with "B-" and modify it
      if (typeof id === 'string' && id.startsWith("B-")) {
        id = id.split("B-")[1]; // Remove "B-" prefix
      }
  
      dispatch(fetchRequestData({ id, isBulk }))
        .unwrap()
        .then((responseData) => {
          const queryParam = isBulk ? `bulk_id=${id}` : `id=${id}`;
          navigate(`/adminrequestedynamicidpage?${queryParam}`, {
            state: { responseData, isBulk, id },
          });
        })
        .catch((error) => {
          console.error("Error fetching request:", error);
          alert("Error fetching request.");
        });
    }
  };
  
  

  const handleJewelryFilterClick = (selectedFilter) => {
    setJewelryFilter(selectedFilter);

    if (selectedFilter === "All Types") {
      setJewelryLoading(true);
      dispatch(fetchAdminAllOrders())
        .then(() => dispatch(fetchAdminAllInvoice()))
        .then(() => dispatch(fetchadminallmemo()))
        .then(() => dispatch(fetchadminallhold()))
        .finally(() => setJewelryLoading(false));
    } else if (selectedFilter === "Invoice") {
      setJewelryLoading(true);
      dispatch(fetchAdminAllInvoice()).finally(() => setJewelryLoading(false));
    } else if (selectedFilter === "Memo") {
      setJewelryLoading(true);
      dispatch(fetchadminallmemo()).finally(() => setJewelryLoading(false));
    } else if (selectedFilter === "Hold") {
      setJewelryLoading(true);
      dispatch(fetchadminallhold()).finally(() => setJewelryLoading(false));
    }
  };

  const handleFilterClick = (selectedFilter) => {
    setDiamondFilter(selectedFilter);

    if (selectedFilter === "All Types") {
      setDiamondLoading(true);
      dispatch(fetchAdminAllOrders())
        .then(() => dispatch(fetchAdminAllInvoice()))
        .then(() => dispatch(fetchadminallmemo()))
        .then(() => dispatch(fetchadminallhold()))
        .finally(() => setDiamondLoading(false));
    } else if (selectedFilter === "Invoice") {
      setDiamondLoading(true);
      dispatch(fetchAdminAllInvoice()).finally(() => setDiamondLoading(false));
    } else if (selectedFilter === "Memo") {
      setDiamondLoading(true);
      dispatch(fetchadminallmemo()).finally(() => setDiamondLoading(false));
    } else if (selectedFilter === "Hold") {
      setDiamondLoading(true);
      dispatch(fetchadminallhold()).finally(() => setDiamondLoading(false));
    }
  };

  const exportDiamondToCSV = () => {
    exportDataToCSV(rowDatadiamond, "diamond_data.csv");
  };

  const exportJewelryToCSV = () => {
    exportDataToCSV(rowDatajewelry, "jewelry_data.csv");
  };

  const exportDataToCSV = (data, filename) => {
    const csvHeaders = columnDefs.map((col) => col.headerName).join(",");
    const csvRows = data.map((row) =>
      columnDefs.map((col) => `"${row[col.field] || ""}"`).join(",")
    );
    const csvContent = [csvHeaders, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setDiamondLoading(allOrderStatus === "loading" || invoiceStatus === "loading" || memoStatus === "loading" || holdStatus === "loading");
    setJewelryLoading(allOrderStatus === "loading" || invoiceStatus === "loading" || memoStatus === "loading" || holdStatus === "loading");
  }, [allOrderStatus, invoiceStatus, memoStatus, holdStatus]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-center mb-4">Received Requests</h1>
      <h2 className="text-xl font-semibold mb-2">Diamond Requests</h2>

      <div className="flex flex-wrap justify-between items-center gap-2 mb-2">
        <div className="flex gap-3">
          {["All Types", "Invoice", "Memo", "Hold"].map((filterOption) => (
            <button
              key={filterOption}
              onClick={() => handleFilterClick(filterOption)}
              className={`px-7 py-1 border border-[#1976d2] rounded-full ${
                diamondfilter === filterOption ? "bg-[#1976d2] text-white" : ""
              }`}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="flex gap-2 mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={exportDiamondToCSV}
          >
            Export Diamond to CSV
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 375, width: "100%" }}>
      <AgGridReact
          columnDefs={columnDefs}
          rowData={diamondLoading ? null : rowDatadiamond}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          onCellClicked={handleCellClicked}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-loading-center">Loading...</span>'
          }
        />
      </div>
      <h2 className="text-xl font-semibold mt-4 mb-2">Jewelry Requests</h2>
      <div className="flex flex-wrap justify-between items-center gap-2 my-2">
        <div className="flex gap-3">
          {["All Types", "Invoice", "Memo", "Hold"].map((filterOption) => (
            <button
              key={filterOption}
              onClick={() => handleJewelryFilterClick(filterOption)}
              className={`px-7 py-1 border border-[#1976d2] rounded-full ${
                jewelryfilter === filterOption ? "bg-[#1976d2] text-white" : ""
              }`}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="flex gap-2 mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={exportJewelryToCSV}
          >
            Export Jewelry to CSV
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 375, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={jewelryLoading ? null : rowDatajewelry}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          onCellClicked={handleCellClicked}
          
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-loading-center">Loading...</span>'
          }
        />
      </div>
    </div>
  );
};

export default AdminRequest;
