import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

export const fetchmodifySearch = createAsyncThunk(
  "modifySearch/fetchmodifySearch",
  async (searchId) => {
    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/users/modifySearch/${searchId}`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch");
    }

    const data = await response.json();
    return data;
  }
);

const modifySearchSlice = createSlice({
  name: "modifySearch",
  initialState: {
    modifySearch: [],
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchmodifySearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchmodifySearch.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.modifySearch = action.payload;
      })
      .addCase(fetchmodifySearch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default modifySearchSlice.reducer;
