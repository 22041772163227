import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { MdDelete } from "react-icons/md";
import { fetchadminallmemo } from "../../../../redux/slice/adminallmemo/adminallmemoSlice";
import { baseUrl } from "../../../../utils/url";
import { useNavigate } from "react-router-dom";

export default function AdminAllMemo() {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    handleadminallmemo();
  }, []);

  const handleadminallmemo = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/admin/get-all-diamond_jewel?request_type=memo`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Column definitions for diamonds
  const diamondColumnDefs = useMemo(
    () => [
      // {
      // headerName: "Created_at",
      // field: "created_at",
      // filter: "agDateColumnFilter",
      // filterParams: {
      //   comparator: (filterLocalDateAtMidnight, cellValue) => {
      //     if (cellValue == null) return -1;

      //     const dateParts = cellValue.split(" ")[0].split("/");
      //     const day = parseInt(dateParts[1], 10);
      //     const month = parseInt(dateParts[0], 10) - 1;
      //     const year = parseInt(dateParts[2], 10);

      //       const cellDate = new Date(year, month, day);

      //       // Compare the two dates
      //       if (cellDate < filterLocalDateAtMidnight) {
      //         return -1;
      //       } else if (cellDate > filterLocalDateAtMidnight) {
      //         return 1;
      //       } else {
      //         return 0;
      //       }
      //     },
      //     browserDatePicker: true,
      //   },
      //   valueFormatter: (params) => {
      //     const dateValue = params.value;
      //     if (dateValue) {
      //       return dateValue.split(" ")[0];
      //     }
      //     return "";
      //   },
      // },
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Req_Id",
        field: "request_id",
        width: 100,
        filter: true,
      },
      {
        headerName: "Req Bulk Id",
        field: "request_bulk_id",
        width: 180,
        filter: true,
      },
      { headerName: "Stock #", field: "Stock #" },
      { headerName: "Availability", field: "Availability" },

      {
        headerName: "Status",
        field: "status",
        width: 120,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "withdraw") {
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },
    ],
    []
  );

  // Column definitions for jewelry
  const jewelryColumnDefs = useMemo(
    () => [
      // {
      //   headerName: "Created_at",
      //   field: "created_at",
      //   filter: "agDateColumnFilter",
      //   filterParams: {
      //     comparator: (filterLocalDateAtMidnight, cellValue) => {
      //       if (cellValue == null) return -1;

      //       const dateParts = cellValue.split(" ")[0].split("/");
      //       const day = parseInt(dateParts[1], 10);
      //       const month = parseInt(dateParts[0], 10) - 1;
      //       const year = parseInt(dateParts[2], 10);

      //       const cellDate = new Date(year, month, day);

      //       // Compare the two dates
      //       if (cellDate < filterLocalDateAtMidnight) {
      //         return -1;
      //       } else if (cellDate > filterLocalDateAtMidnight) {
      //         return 1;
      //       } else {
      //         return 0;
      //       }
      //     },
      //     browserDatePicker: true,
      //   },
      //   valueFormatter: (params) => {
      //     const dateValue = params.value;
      //     if (dateValue) {
      //       return dateValue.split(" ")[0];
      //     }
      //     return "";
      //   },
      // },
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Req_Id",
        field: "request_id",
        width: 100,
        filter: true,
      },
      {
        headerName: "Req Bulk Id",
        field: "request_bulk_id",
        width: 180,
        filter: true,
      },

      { headerName: "Sku #", field: "Sku #", width: 100, filter: true },
      { headerName: "Availability", field: "Avability" },
      {
        headerName: "Status",
        field: "status",
        width: 120,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "withdraw") {
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Lot Name", field: "Lot Name", width: 120, filter: true },
      {
        headerName: "Jewelry Type",
        field: "Jewelry Type",
        width: 140,
        filter: true,
      },
      {
        headerName: "Jewelry Subtype",
        field: "Jewelry Subtype",
        width: 120,
        filter: true,
      },
      {
        headerName: "Metal Type",
        field: "Metal Type",
        width: 150,
        filter: true,
      },
      {
        headerName: "Setting Type",
        field: "Setting Type",
        width: 140,
        filter: true,
      },
      {
        headerName: "Orientation",
        field: "Orientation",
        width: 140,
        filter: true,
      },
      {
        headerName: "Diamond Shape",
        field: "Diamond Shape",
        width: 150,
        filter: true,
      },
      {
        headerName: "Total Diamond Weight",
        field: "Total Diamond Weight",
        width: 180,
        filter: true,
      },
      {
        headerName: "Total Diamond Pcs",
        field: "Total Diamond Pcs",
        width: 180,
        filter: true,
      },
      {
        headerName: "Total Price",
        field: "Total Price",
        width: 150,
        filter: true,
      },
      { headerName: "Lab Name", field: "Lab Name", width: 150, filter: true },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  const diamondRowData = useMemo(() => {
    if (data && data?.length) {
      return data.filter((item) => item.type === "diamond").map((item) => item);
    }
    return [];
  }, [data]);

  const jewelryRowData = useMemo(() => {
    if (data && data.length) {
      return data.filter((item) => item.type === "jewelry").map((item) => item);
    }
    return [];
  }, [data]);

  return (
    <section style={{ width: "100%" }}>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Diamond Memo Request</span>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "380px", marginBottom: "20px" }}
      >
        <AgGridReact
          columnDefs={diamondColumnDefs}
          rowData={diamondRowData}
          defaultColDef={defaultColDef}
          pagination={true} // Enable pagination
          paginationPageSize={50}
        />
      </div>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Jewelry Memo Request</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "380px" }}>
        <AgGridReact
          columnDefs={jewelryColumnDefs}
          rowData={jewelryRowData}
          defaultColDef={defaultColDef}
          pagination={true} // Enable pagination
          paginationPageSize={50}
        />
      </div>
    </section>
  );
}
