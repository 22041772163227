import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderData } from "../../../redux/slice/orderSlice/orderpageSlice";
import { AgGridReact } from "ag-grid-react";

export default function DashboardOrders() {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.orderpage);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchOrderData());
    }
  }, [status, dispatch]);
 
  // Column definitions for diamonds
  const diamondColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;
  
            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1; 
            const year = parseInt(dateParts[2], 10);
  
            const cellDate = new Date(year, month, day);
  
            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true, 
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0]; 
          }
          return "";
        },
      },
      { headerName: "Stock #", field: "Stock #" },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Availability", field: "Availability" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },
    ],
    []
  );

  // Column definitions for jewelry
  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;
  
            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1; 
            const year = parseInt(dateParts[2], 10);
  
            const cellDate = new Date(year, month, day);
  
            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true, 
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0]; 
          }
          return "";
        },
      },
      { headerName: "Sku #", field: "Sku #" },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Lot Name", field: "Lot Name" },
      { headerName: "Title", field: "Title" },
      { headerName: "Stone Details", field: "Stone Details" },
      { headerName: "Stone Details_1", field: "Stone Details_1" },
      { headerName: "Jewelry Type", field: "Jewelry Type" },
      { headerName: "Jewelry Subtype", field: "Jewelry Subtype" },
      { headerName: "Metal Type", field: "Metal Type" },
      { headerName: "Setting Type", field: "Setting Type" },
      { headerName: "Orientation", field: "Orientation" },
      { headerName: "Jewelry Size", field: "Jewelry Size" },
      { headerName: "Avg Center Weight", field: "Avg Center Weight" },
      { headerName: "Total Diamond Weight", field: "Total Diamond Weight" },
      { headerName: "Total Diamond Pcs", field: "Total Diamond Pcs" },
      { headerName: "Color", field: "Color" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Lab Name", field: "Lab Name" },
      { headerName: "Report", field: "Report" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Image File", field: "Image File" },
      { headerName: "Video File", field: "Video File" },
      { headerName: "Image File_1", field: "Image File_1" },
      { headerName: "Image File_2", field: "Image File_2" },
      { headerName: "Image File_3", field: "Image File_3" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  // Separate data based on type
  const diamondData = useMemo(
    () =>
      data
        .filter((item) => item.type === "diamond")
        .flatMap((item) =>
          item.item_details.filter((detail) => detail.deleted !== 1)
        ),
    [data]
  );

  const jewelryData = useMemo(
    () =>
      data
        .filter((item) => item.type === "jewelry")
        .flatMap((item) =>
          item.item_details.filter((detail) => detail.deleted !== 1)
        ),
    [data]
  ); 

  return (
    <section style={{ width: "100%" }}>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Diamond Orders</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "300px", marginBottom: "20px" }}>
        <AgGridReact
          rowData={diamondData}
          columnDefs={diamondColumnDefs}
          defaultColDef={defaultColDef}
        
        />
      </div>
      <div className="text-center font-bold text-2xl mt-14 mb-5">
        <span>Jewelry Orders</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "350px" }}>
        <AgGridReact
          rowData={jewelryData}
          columnDefs={jewelryColumnDefs}
          defaultColDef={defaultColDef}
          
        />
      </div>
    </section>
  );
}
