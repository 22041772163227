  import React, { useEffect, useState } from "react";
import { baseUrl } from "../../utils/url";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { useNavigate } from "react-router-dom";

export default function Signup() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    companyName: "",
    streetAddress: "",
    website: "",
    contact_person: "",
    contact_email: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    phone: "",
    phoneCode: "",
    office_phone: "",
    username: "",
    password: "",
    confirmPassword: "",
    request_mail: false,
  });

  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [code, setCode] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleEmailBlur = () => {
    setFormData((prevData) => ({
      ...prevData,
      username: prevData.email, // Set username to email value
    }));
  };

  useEffect(() => {
    fetch(`${baseUrl}/admin/countries`)
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data.map((country) => country).sort();
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/admin/codes`)
      .then((response) => response.json())
      .then((data) => {
        const sortedCode = data
          .filter((code) => code !== "0")
          .sort((a, b) => {
            if (a === "1") return -1;
            if (b === "1") return 1;
            return a - b;
          });

        setCode(sortedCode);

        if (sortedCode.includes("1")) {
          setFormData((prevData) => ({
            ...prevData,
            phoneCode: "1",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching codes:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update user data state
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
      ...(name === "email" ? { username: value } : {}), // Automatically update username when email changes
    }));

    // Fetch states if country is updated
    if (name === "country") {
      fetch(`${baseUrl}/admin/states?country=${value}`)
        .then((response) => response.json())
        .then((data) => {
          const sortedStates = data.sort(); // Assuming data is an array of states
          setStates(sortedStates);
        })
        .catch((error) => console.error("Error fetching states:", error));
    }
  };

  // Validation function to validate all fields and set errors
  const validateForm = () => {
    const newErrors = {};

    // Validate confirmEmail
    if (formData.confirmEmail !== formData.email) {
      newErrors.confirmEmail = "Email and Confirm Email do not match";
    }

    // Validate confirmPassword
    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = "Password and Confirm Password do not match";
    }

    // Validate password
    const passwordValidation = validatePassword(formData.password);
    if (!passwordValidation.isValid) {
      newErrors.password = passwordValidation.message;
    }

    return newErrors;
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    if (password.length < minLength) {
      return {
        isValid: false,
        message: "Password must be at least 8 characters long",
      };
    }
    if (!hasUpperCase) {
      return {
        isValid: false,
        message: "Password must contain at least one uppercase letter",
      };
    }
    if (!hasLowerCase) {
      return {
        isValid: false,
        message: "Password must contain at least one lowercase letter",
      };
    }
    if (!hasNumber) {
      return {
        isValid: false,
        message: "Password must contain at least one numeric digit",
      };
    }
    return { isValid: true, message: "" };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    const newErrors = validateForm();
    setErrors(newErrors);

    const fullPhoneNumber = `+${formData.phoneCode || "+1"} ${formData.phone}`;

    // Validation: Check if any form value is undefined or empty
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "companyName",
      "username",
      "password",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      console.error(
        "Form has errors: Required fields are missing",
        emptyFields
      );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please fill all required fields: ${emptyFields.join(", ")}`,
      });
      return;
    }

    if (Object.keys(newErrors).length > 0) {
      console.error("Form has errors:", newErrors);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fix the form errors before submitting!",
      });
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("first_name", formData.firstName);
    formDataToSend.append("last_name", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("mobile_number", fullPhoneNumber);
    formDataToSend.append("name", formData.companyName);
    formDataToSend.append("website", formData.website);
    formDataToSend.append("contact_person", formData.contact_person);
    formDataToSend.append("contact_email", formData.contact_email);
    formDataToSend.append("street_address", formData.streetAddress);
    formDataToSend.append("country", formData.country);
    formDataToSend.append("state", formData.state);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("zip_code", formData.postalCode);
    formDataToSend.append("office_phone", formData.office_phone);
    formDataToSend.append("user_name", formData.username);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("request_mail", formData.request_mail ? 1 : 0);

    try {
      const response = await fetch(`${baseUrl}/users/register_user`, {
        method: "POST",
        body: formDataToSend,
        redirect: "follow",
      });

      if (!response.ok) {
        const errorMessage = await response.text(); // Extract the response message
        throw new Error(errorMessage || "Network response was not ok");
      }

      // Success alert
      Swal.fire({
        icon: "success",
        title: "Registration Successful!",
        text: "Your form has been submitted successfully.",
        showConfirmButton: false,
        timer: 2000,
      });

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        confirmEmail: "",
        companyName: "",
        website: "",
        contact_person: "",
        contact_email: "",
        streetAddress: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        office_phone: "",
        phoneCode: "",
        username: "",
        password: "",
        confirmPassword: "",
        request_mail: false,
      });
      navigate("/"); // Redirect to success page
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Something went wrong!",
      });
    }
  };


    const closeModal = () => {
      setIsModalOpen(false);
    };

    return (
      <div className="flex justify-center items-center h-screen">
        <form
          className="bg-gray-100 p-8 rounded-lg shadow-lg max-h-full w-[70%] max-w-screen"
          onSubmit={handleSubmit}
          autoComplete="nope"
          
        >
          <div className="flex justify-center mb-5">
            <img
              src="/logo/Black_01.png"
              width={200}
              height={60}
              alt="Skylab Diamond"
              className="w-24"
            />
          </div>
          <h2 className="text-4xl font-bold text-center mb-5">
            Registration Form
          </h2>
          <div>
            <div className="grid grid-cols-3 max-sm:grid-cols-1 gap-10 mb-4">
              <div className="flex flex-col justify-center gap-4">
                <div className="text-[20px] font-semibold pb-3 text-center">
                  Personal Details
                </div>
                <div>
                  <label className="block text-gray-700 font-medium">
                    First Name<span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                    autoComplete="nope"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium">
                    Last Name<span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                    autoComplete="nope"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium">
                    Email Address
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                    autoComplete="nope"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium">
                    Confirm Email Address
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="email"
                    name="confirmEmail"
                    placeholder="Confirm Email Address"
                    value={formData.confirmEmail}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                    autoComplete="nope"
                  />
                  {errors.confirmEmail && (
                    <p className="text-red-500 text-sm">{errors.confirmEmail}</p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700 font-medium">
                    Phone Number
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <div className="flex">
                    <select
                      name="phoneCode"
                      value={formData.phoneCode}
                      onChange={handleChange}
                      className="p-2 border w-20 border-gray-300 rounded-l mt-1"
                      required
                    >
                      {code.map((code) => (
                        <option value={code}>+ {code}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded-r mt-1"
                      required
                      autoComplete="nope"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="text-[20px] font-semibold pb-8 pt-4 text-center">
                  Company Detalis
                </div>
                <div className="flex justify-between gap-4 max-sm:flex-wrap">
                  <div className="w-full pb-4">
                    <label className="block text-gray-700 font-medium">
                      Company Name
                      <span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Company Name"
                      value={formData.companyName}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      required
                      autoComplete="nope"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 font-medium">
                      Website
                    </label>
                    <input
                      type="text"
                      name="website"
                      placeholder="https://www.fsdfsdf"
                      value={formData.website}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-4 max-sm:flex-wrap">
                  <div className="w-full pb-4">
                    <label className="block text-gray-700 font-medium">
                      Contact Person
                      <span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="contact_person"
                      placeholder="Company Person"
                      value={formData.contact_person}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      required
                      autoComplete="nope"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 font-medium">
                      Contact Email
                      <span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="contact_email"
                      placeholder="Company Email"
                      value={formData.contact_email}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      required
                      autoComplete="nope"
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-4 max-sm:flex-wrap">
                  <div className="w-full pb-4">
                    <label className="block text-gray-700 font-medium">
                      Street Address
                      <span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="streetAddress"
                      placeholder="Company Street Address"
                      value={formData.streetAddress}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      autoComplete="nope"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 font-medium">
                      Country<span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="w-full p-2 flax flex-c border border-gray-300 rounded mt-1"
                    >
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-between gap-4 max-sm:flex-wrap">
                  <div className="w-full pb-4">
                    <label className="block text-gray-700 font-medium">
                      State<span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                    >
                      <option value="">Select State</option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 font-medium">
                      City<span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      autoComplete="nope"
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-4 max-sm:flex-wrap">
                  <div className="w-full pb-4">
                    <label className="block text-gray-700 font-medium">
                      Postal/Zip
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      placeholder="Postal/Zip"
                      value={formData.postalCode}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      autoComplete="nope"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 font-medium">
                      Office Phone
                      <span className="text-red-500 text-[20px]">* </span>
                    </label>
                    <input
                      type="text"
                      name="office_phone"
                      placeholder="Office Phone"
                      value={formData.office_phone}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                      autoComplete="nope"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="mb-4 mt-4">
                  <h5 className="text-[20px] font-semibold pb-2 text-center">
                    Login Information
                  </h5>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1 bg-gray-100 text-gray-500 cursor-not-allowed"
                    readOnly
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>

                <div className="mb-4">
                  Create Password:
                  <span className="text-red-500 text-[20px]">* </span>
                  <label className="block text-gray-700 font-medium">
                    Minimum 8 characters including 1 number
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 "
                      required
                      autoComplete="new-password"
                    />
                    <div
                      className="absolute bottom-3 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-sm">{errors.password}</p>
                  )}
                </div>
                <label className="block text-gray-700 font-medium">
                  Confirm Password
                  <span className="text-red-500 text-[20px]">* </span>
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                    autoComplete="new-password"

                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showPassword state
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
                )}

                {/* <div>
                  <input
                    type="checkbox"
                    name="request_mail"
                    className=" h-4 w-4 border rounded"
                    checked={formData.request_mail}
                    onChange={handleChange}
                  />
                  <label className=" text-gray-700 font-medium p-2">
                    Request Mail
                  </label>
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[40%] h-[50px] bg-blue-700 text-white p-2 rounded hover:bg-blue-400 transition duration-200"
            >
              Submit
            </button>
          </div>
        </form>

        {isModalOpen && (
          <>
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 text-center">
              <div className="bg-white p-8 rounded shadow-lg max-w-sm w-full z-50">
                <h2 className="text-2xl font-bold mb-4">Success</h2>
                <p className="mb-4">
                  Registration Request Sent Successfully! Your request will be
                  approved shortly
                </p>
                <button
                  onClick={closeModal}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
