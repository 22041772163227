import React from "react";
import AdminAllMemo from "../../../adminComponents/admindashboard/admindashboard/adminallmemo/AdminAllMemo";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminAllMemoPage() {
  return (
    <AdminDefaultLayout>
      <AdminAllMemo />
    </AdminDefaultLayout>
  );
}
