import { DefaultLayout } from "../../components/layout/defaultLayout";
import UserProfile from "../../components/userprofile/UserProfile";
import React from "react";

export default function  Userprofilepage() {
  return (
    <DefaultLayout>
      <UserProfile />
    </DefaultLayout>
  );
}
