import React from "react";
import AdminUploadfile from "../../adminComponents/adminuploadfile/AdminUploadfile";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminUploadfilePage() {
  return (
    <AdminDefaultLayout>
      <AdminUploadfile />
    </AdminDefaultLayout>
  );
}
