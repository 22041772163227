
import Signup from '../../components/signup/Signup'
import React from 'react'

export default function Signuppage() {
  return (
    <>
    <Signup />
    </>
  )
}
