import React, { useState } from "react";
import { baseUrl } from "../../utils/url";

const Contact_Us = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset the form fields after submission

    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formDataToSend = new FormData();
    // formDataToSend.append("name", formData.name);
    // formDataToSend.append("email", formData.email); // Append email
    formDataToSend.append("message", formData.message); // Append message

    try {
      const response = await fetch(`${baseUrl}/users/contact-us` , {
        method: "POST",
        headers: myHeaders,
        body: formDataToSend
      })
      if(!response.ok){
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error("Error to send message");
    }

    setFormData({ name: "", email: "", message: "" });

  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Contact Us</h2>
        <p className="text-center font-semibold mb-4">
          Questions, suggestions, or comments welcome! Send a message below or
          call +1 (646) 892-4262 workdays between 9AM-6PM EST (USA)
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div> */}
          <div>
            <textarea
              id="message"
              name="message"
              placeholder="Enter message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="4"
              className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact_Us;
