import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { baseUrl } from "../../utils/url";
import Swal from "sweetalert2";
import { fetchCartList } from "../../redux/slice/cartSlice/cartSlice";
import { useDispatch } from "react-redux";

const Compare = () => {
  const location = useLocation();
  const [diamonds, setDiamonds] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGet();
  }, []);

  const addToCart = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "diamond",
          productId: id,
          quantity: 1,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/add-to-cart`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }
      await Swal.fire({
        title: "Success",
        text: "Add To The Cart Successfully!",
        icon: "success",
      });
      dispatch(fetchCartList());

    } catch (error) {
      console.error("Error adding item to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Item Already Add In Cart!",
      });
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleGet = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const response = await fetch(`${baseUrl}/users/search/get/compare`, {
        method: "GET",
        headers: myHeaders,
      });
      if (!response.ok) {
        throw new Error("Couldn't fetch");
      }
      const data = await response.json();
      setDiamonds(data.data);
    } catch (error) {
      console.error("Error rendering compare data:", error);
    }
  };

  const handleRemove = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/users/search/delete/compare/${id}`,
        {
          method: "DELETE",
          headers: myHeaders,
        }
      );

      if (!response.ok) {
        throw new Error("Couldn't delete");
      }
      handleGet();
    } catch (error) {
      console.error("Error deleteing compare:", error);
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-14">Diamond Compare</h2>
      <div className="flex justify-center space-x-4">
        {diamonds?.map((diamond, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg p-4 w-80 text-center relative"
          >
            <button
              className="text-black-500 text-xl absolute top-2 right-2"
              onClick={() => handleRemove(diamond.id)}
            >
              ✖
            </button>
            <h3 className="font-semibold">#{diamond["Stock #"]}</h3>
            <div className="my-4">
              <img
                className="w-30 h-30 object-cover mx-auto"
                src={diamond["Image Link"]}
                alt={`Diamond ${diamond.Stock}`}
              />
            </div>
            <div className="text-left text-sm space-y-2">
              <p>
                <span className="font-semibold">Availability : </span>
                {diamond["Availability"]}
              </p>
              <p>
                <span className="font-semibold">Total Sale:</span> $
                {diamond["Total Price"]}
              </p>
              <p>
                <span className="font-semibold">Shape:</span> {diamond.Shape}
              </p>
              <p>
                <span className="font-semibold">Weight:</span> {diamond.Weight}
              </p>
              <p>
                <span className="font-semibold">Color:</span> {diamond.Color}
              </p>
              <p>
                <span className="font-semibold">Clarity:</span>{" "}
                {diamond.Clarity}
              </p>
              <p>
                <span className="font-semibold">Cut Grade:</span>{" "}
                {diamond["Cut Grade"]}
              </p>
              <p>
                <span className="font-semibold">Depth:</span>{" "}
                {diamond["Depth Percent"]}
              </p>
              <p>
                <span className="font-semibold">Measurements:</span>{" "}
                {diamond.Measurements}
              </p>
              <p>
                <span className="font-semibold">Ratio:</span>{" "}
                {diamond["Discount Percent"]}
              </p>
              <p>
                <span className="font-semibold">Lab:</span> {diamond.Lab}
              </p>
              <p>
                <span className="font-semibold">Cert No:</span>{" "}
                {diamond["Certificate #"]}
              </p>
            </div>
            <button
              className="bg-blue-500 rounded text-white px-4 py-2 mt-4"
              onClick={() => addToCart(diamond.id)}
            >
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Compare;
