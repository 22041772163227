import React from "react";
import SideBar from "../SideBar/SideBar";

export const DefaultLayout = ({ children }) => {
  return (
    <div className="flex">
      <SideBar />
      <main className="flex-1 p-6">
        {children}
      </main>
    </div>
  );
};

