import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderData } from "../../redux/slice/orderSlice/orderpageSlice";
import { baseUrl } from "../../utils/url";

export default function Requests() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.orderpage);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedIsBulk, setSelectedIsBulk] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isPendingFilter, setIsPendingFilter] = useState(false);

  const handlePendingFilterClick = () => {
    // Toggle the pending filter state when the "Pending" button is clicked
    setIsPendingFilter(!isPendingFilter);
  };


  const filteredData = isPendingFilter
    ? data.filter((el) => el.status === "pending")
    : data;

  useEffect(() => {
    dispatch(fetchOrderData());
  }, [dispatch]);

  const fetchrequestData = async (id, isBulk, index) => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        setLoading(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const queryParam = isBulk ? `bulk_id=${id}` : `id=${id}`;
      const url = `${baseUrl}/users/get-request?${queryParam}`;

      let response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          const updatedItemDetails = data[0].item_details.map((item) => ({
            ...item,
          }));
          setRowData(updatedItemDetails);
          setSelectedRequest(data[0]);
          setSelectedRequestId(id);
          setSelectedIsBulk(isBulk);
          setActiveIndex(index);
        } else {
          console.error("No data found for the given ID");
        }
      } else {
        console.error("Error fetching request data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching request data:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalPrice = (itemDetails) => {
    const total = itemDetails?.reduce(
      (sum, item) =>
        sum + parseFloat(item["Total Price"].replace(/[^0-9.-]+/g, "")),
      0
    );
    return parseFloat(total?.toFixed(2));
  };

  const totalPrice = calculateTotalPrice(selectedRequest?.item_details);

  const jewelryColumnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
    },
    // { headerName: "created_at", field: "created_at" },\

    { headerName: "Sku #", field: "Sku #", width: 100 },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      cellStyle: (params) => {
        if (params.value === "pending") {
          return {
            color: "#e6b800",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "accept") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "withdraw") {
          return {
            color: "#3b71ca",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "decline") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        }
        return null;
      },
    },
    { headerName: "Lot Name", field: "Lot Name", width: 100 },
    { headerName: "Total Price", field: "Total Price", width: 100 },
    { headerName: "Total Price offer", field: "total_price_offer", width: 135 },
    { headerName: "Pay Within Days", field: "pay_within_days", width: 140 },
    { headerName: "Availability", field: "Avability", width: 120 },
    { headerName: "Title", field: "Title", width: 100 },
    { headerName: "Stone Details", field: "Stone Details", width: 120 },
    { headerName: "Metal Type", field: "Metal Type", width: 120 },
    {
      headerName: "Total Diamond Weight",
      field: "Total Diamond Weight",
      width: 180,
    },
    {
      headerName: "Created At",
      field: "created_at",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
  ];

  const diamondColumnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
    },
    { headerName: "Stock #", field: "Stock #", width: 100 },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      cellStyle: (params) => {
        if (params.value === "pending") {
          return {
            color: "#e6b800",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "withdraw") {
          return {
            color: "#3b71ca",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "accept") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "decline") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "expire") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        }
        return null;
      },
    },
    { headerName: "Total Price", field: "Total Price", width: 100 },
    { headerName: "Total Price offer", field: "total_price_offer", width: 135 },
    { headerName: "Pay Within Days", field: "pay_within_days", width: 140 },
    { headerName: "Availability", field: "Availability", width: 120 },
    { headerName: "Shape", field: "Shape", width: 100 },
    { headerName: "Weight", field: "Weight", width: 100 },
    { headerName: "Clarity", field: "Clarity", width: 100 },
    { headerName: "Color", field: "Color", width: 100 },
    { headerName: "Discount Percent", field: "Discount Percent", width: 150 },
    {
      headerName: "Created At",
      field: "created_at",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
  ];

  const handleSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  const handleWithdrawSelected = async () => {
    setLoading(true);
    if (selectedRows.length === 0) return;

    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        setLoading(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      for (const row of selectedRows) {
        const url = `${baseUrl}/users/withdrawn_request/${row.id}`;
        const response = await fetch(url, {
          method: "PUT",
          headers: myHeaders,
        });

        if (response.ok) {
          const result = await response.json();
          fetchrequestData(selectedRequestId, selectedIsBulk);
        } else {
          console.error("Error withdrawing request:", response.statusText);
        }
      }
      setSelectedRows([]);
    } catch (error) {
      console.error("Error withdrawing requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const chatBox = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex">
      <div className="w-1/4 bg-[#feefdc] h-full text-black p-4">
        <div className="flex items-center mb-4">
          <button className="text-lg">
            <i className="fas fa-arrow-left"></i>
          </button>
          <h2 className="ml-4 text-xl">Notifications</h2>
          <button
            className="ml-4 p-1 text-white px-3 rounded-xl bg-slate-500"
            onClick={handlePendingFilterClick}
          >
            Pending
          </button>

          <button className="ml-auto text-lg">
            <i className="fas fa-bell"></i>
          </button>
        </div>
        <div className="overflow-y-auto max-h-[800px]">
          {filteredData &&
            filteredData.map((el, index) => {
              // Function to format the date and time without seconds
              const formatDateTime = (datetime) => {
                if (!datetime) return "";
                const datePart = datetime.slice(0, 10); // Extract the date part (YYYY-MM-DD)
                const timePart = datetime.slice(11, 16); // Extract the time part without seconds (HH:MM)
                return `${datePart} ${timePart}`;
              };

              return (
                <div
                  key={index}
                  className={`space-y-2 pb-5 `}
                  onClick={() =>
                    fetchrequestData(el.bulk_id || el.id, !!el.bulk_id, index)
                  }
                >
                  <div
                    className={`p-4 text-white rounded-xl cursor-pointer mr-3 ${activeIndex === index ? "bg-gray-400" : "bg-[#3b71ca]"
                      }`}
                  >
                    <p className="font-bold capitalize">
                      {el.status} ({el.type}) Request
                    </p>
                    <p
                      className={`${el.status === "pending" ? "bg-[#ff964d]" : ""
                        } p-2 rounded-xl`}
                    >
                      You have {el.status} a{" "}
                      {el.request_item_type || el.request_type} request to
                      Skylab Diamond Inc. (
                      {formatDateTime(el.updated_at) ||
                        formatDateTime(el.created_at)}
                      )
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* Main Content */}
      <div className="w-3/4 bg-white p-3">
        {selectedRequest && selectedRequest.item_details?.length > 0 ? (
          <div className="bg-gray-100 p-4 rounded">
            <h1 className="text-2xl font-bold mb-2">
              {selectedRequest.item_details[0].type === "diamond"
                ? "Lab-Grown Diamonds Hold Request"
                : "Jewelry Request"}
            </h1>
            <div className="text-right mb-4">
              <p className="text-xl font-bold">Total Price</p>
              <p className="text-2xl text-blue-600">${totalPrice}</p>
            </div>
            <div className="grid grid-cols-2 gap-1 mb-4">
              <div>
                <p>
                  <strong>Request Date:</strong>{" "}
                  {new Date(
                    selectedRequest.item_details[0].created_at
                  ).toLocaleString()}
                </p>
                <p>
                  <strong>Request Id:</strong> {selectedRequest.bulk_id ? `B-${selectedRequest.bulk_id}` : selectedRequest.id}
                </p>
                <p>
                  <strong>Time To Hold:</strong>{" "}
                  {selectedRequest.hold_duration || "N/A"}
                </p>
                <p>
                  <strong># of Items:</strong>{" "}
                  {selectedRequest.item_details.length}
                </p>
              </div>
              <div>
                <p>
                  <strong>Company:</strong>{" "}
                  {selectedRequest.item_details[0].company_name}
                </p>
                <p>
                  <strong>Contact:</strong>{" "}
                  Hiren Goti
                </p>
                <p className="flex gap-2">
                  <strong>Email:</strong>
                  <div>
                    <span>niren@skylabdiamond.com;</span>
                    <span>sagar@skylabdiamond.com;</span>
                    <span>hiren@skylabdiamond.com</span>
                  </div>
                </p>
                <p>
                  <strong>Office:</strong>{" "}
                  +1 646-892-4262
                </p>
                <p>
                  <strong>Mobile:</strong>{" "}
                  +1 646-241-8844
                </p>
              </div>
            </div>
            <p>
              <strong>Request Comments:</strong>{" "}
              {selectedRequest.item_details[0].comments || "No comments"}
            </p>
            <div
              className="ag-theme-alpine my-4 "
              style={{ height: "350px", width: "100%" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={
                  selectedRequest?.item_details[0].type === "jewelry"
                    ? [...jewelryColumnDefs]
                    : [...diamondColumnDefs]
                }
                rowSelection="multiple"
                onSelectionChanged={handleSelectionChanged}
                suppressRowClickSelection={true}
              />
            </div>

            <div
              className={`mt-10 text-white p-4 rounded text-center ${selectedRequest?.item_details.every(
                (item) => item.status === "withdraw"
              )
                ? "bg-red-600"
                : selectedRequest?.item_details.every(
                  (item) => item.status === "expire"
                )
                  ? "bg-red-600"
                  : selectedRequest?.item_details.some(
                    (item) => item.status === "accept"
                  )
                    ? "bg-green-600"
                    : "bg-yellow-400"
                }`}
            >
              <button>
                {selectedRequest?.item_details.every(
                  (item) => item.status === "withdraw"
                )
                  ? "Withdraw"
                  : selectedRequest?.item_details.filter(
                    (item) => item.status === "accept"
                  ).length === 1
                    ? "Accept"
                    : selectedRequest?.item_details.every(
                      (item) => item.status === "accept"
                    )
                      ? "Accept"
                      : selectedRequest?.item_details.some(
                        (item) => item.status === "accept"
                      )
                        ? `Request Partial Accepted on ${(() => {
                          const date = new Date(selectedRequest?.updated_at);
                          const day = date.getDate().toString().padStart(2, "0");
                          const month = date.toLocaleString("default", {
                            month: "short",
                          });
                          const year = date.getFullYear();
                          return `${day} ${month}, ${year}`;
                        })()}`
                        : selectedRequest?.item_details.some(
                          (item) => item.status === "pending"
                        ).length === 1 || selectedRequest?.hold_duration === null
                          ? "Request Pending"
                          : selectedRequest?.item_details.every(
                            (item) => item.status === "expire"
                          )
                            ? `Expired on ${(() => {
                              const date = new Date(selectedRequest?.updated_at);
                              const day = date.getDate().toString().padStart(2, "0");
                              const month = date.toLocaleString("default", {
                                month: "short",
                              });
                              const year = date.getFullYear();
                              return `${day} ${month}, ${year}`;
                            })()}`
                            : `${selectedRequest?.status} ${selectedRequest?.hold_duration}`}
              </button>
            </div>

            {!selectedRequest?.item_details.every(
              (item) => item.status === "withdraw"
            ) &&
              !selectedRequest?.item_details.every(
                (item) => item.status === "accept"
              ) &&
              !selectedRequest?.item_details.every(
                (item) => item.status === "expire"
              ) &&
              selectedRequest?.item_details.some(
                (item) => item.status === "pending"
              ) && (
                <div className="text-white p-4 flex items-center justify-between">
                  <button
                    className="bg-[#3b71ca] p-3 px-16 rounded-lg"
                    onClick={handleWithdrawSelected}
                    disabled={loading}
                  >
                    Withdraw
                  </button>
                </div>
              )}

            {/*<button
                className="bg-pink-500 p-3 px-16 rounded-lg"
                onClick={chatBox}
              >
                {" "}
                Chat{" "}
              </button> */}
          </div>
        ) : null}
      </div>
    </div>

    // {isModalOpen && (
    //   <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    //     <div className="bg-white p-8 rounded-lg shadow-lg w-[1000px] h-[800px] relative flex flex-col">
    //       <IoIosClose
    //         className="absolute top-2 right-2 cursor-pointer text-gray-500"
    //         fontSize={40}
    //         onClick={closeModal}
    //       />
    //       <h2 className="text-2xl font-bold mb-4">Chat Box</h2>
    //       <div className="absolute bottom-0 left-0 w-full p-4 flex items-center">
    //         <input
    //           className="border w-full p-3 rounded-[20px] pl-4 pr-12"
    //           placeholder="Type your message"
    //         />
    //         <FaArrowCircleUp
    //           className="absolute right-8 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
    //           fontSize={30}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // )}
  );
}
