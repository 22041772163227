import React, { useEffect, useRef, useState } from "react";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa6";
import { IoIosLogOut } from "react-icons/io";
import { IoBagCheck, IoSearchOutline } from "react-icons/io5";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiMenu3Line } from "react-icons/ri";
import { baseUrl } from "../../../utils/url";
import { MdOutlineInventory, MdOutlinePendingActions } from "react-icons/md";
import { TbDeviceAnalytics } from "react-icons/tb";
import { decrypt, encrypt } from "../../../utils/EncryptDecrypt";

export default function AdminSidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [permissions, setPermissions] = useState(null); // State for storing permissions
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedRoleId = sessionStorage.getItem("UrI");
    setRoleId(storedRoleId);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const id = sessionStorage.getItem("id");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(`${baseUrl}/users/logout-user/${id}`, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // localStorage?.removeItem("hasModalOpened");
      localStorage?.removeItem("token");
      await sessionStorage?.removeItem("id");
      await sessionStorage?.removeItem("first_name");
      await sessionStorage?.removeItem("UrI");
      await sessionStorage?.removeItem("token");
      await sessionStorage?.removeItem("email");
      await sessionStorage?.removeItem("pmatSEmItnigOL");

      navigate("/");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handlePermission = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/admin/get-admin-permission`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      const data = await response.json();

      if (response.ok) {
        setPermissions(data.data);
        sessionStorage.setItem(
          "permissions",
          encrypt(JSON.stringify(data.data))
        );
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  };

  useEffect(() => {
    const roleId = sessionStorage.getItem("UrI");
    const savedPermissions = sessionStorage.getItem("permissions");

    if (roleId === "2" && !permissionsFetched) {
      if (savedPermissions) {
        setPermissions(JSON.parse(decrypt(savedPermissions)));
      } else {
        handlePermission();
      }
      setPermissionsFetched(true); // Set the flag to true after fetching
    }
  }, [permissionsFetched]); // Dependency array includes permissionsFetched

  const links = [
    {
      to: "/admindashboardpage",
      label: "Dashboard",
      icon: <RiDashboardHorizontalLine fontSize={26} />,
    },
    // {
    //   to: "/adminalluserspage",
    //   label: "Users",
    //   icon: <FaUsers fontSize={26} />,
    // },
    // {
    //   to: "/adminuploadfilepage",
    //   label: "Inventory",
    //   icon: <MdOutlineInventory fontSize={26} />,
    // },
  ];

  const emailLocal = sessionStorage.getItem("email");

  if (
    permissions?.request === 1 ||
    (permissions?.request === "1" && permissions.email === emailLocal) ||
    roleId == "3"
  ) {
    links.push({
      to: "/adminrequestepage",
      label: "Requests",
      icon: <MdOutlinePendingActions fontSize={26} />,
    });
  }
  if (
    permissions?.user === 1 ||
    (permissions?.user === "1" && permissions.email === emailLocal) ||
    roleId == "3"
  ) {
    links.push({
      to: "/adminalluserspage",
      label: "Users",
      icon: <FaUsers fontSize={26} />,
    });
  }
  if (
    permissions?.inventory === 1 ||
    (permissions?.inventory === "1" && permissions.email === emailLocal) ||
    roleId == "3"
  ) {
    links.push({
      to: "/adminuploadfilepage",
      label: "Inventory",
      icon: <MdOutlineInventory fontSize={26} />,
    });
  }
  if (
    permissions?.custom_jewelry === 1 ||
    (permissions?.custom_jewelry === "1" && permissions.email === emailLocal) ||
    roleId == "3"
  ) {
    links.push({
      to: "https://order.skylabdiamond.com/",
      label: "Custom Jewelry",
      icon: <IoBagCheck fontSize={26} />,
      target: "_blank",
    });
  }
  if (roleId == "3") {
    links.push({
      to: "/superadminpermissionpage",
      label: "Permission",
      icon: <IoShieldCheckmarkOutline fontSize={26} />,
    });
  }
  if (roleId == "3") {
    links.push({
      to: "/adminanalyticspage",
      label: "analytics",
      icon: <TbDeviceAnalytics fontSize={26} />,
    });
  }

  return (
    <>
      <div className="z-50">
        <div className="">
          <button className="lg:hidden pt-10 pl-2" onClick={toggleSidebar}>
            <RiMenu3Line fontSize={40} />
          </button>
        </div>
        <div
          ref={sidebarRef}
          className={`fixed lg:sticky left-0 lg:h-screen h-full w-[110px] lg:w-[110px] top-0 bg-[#feefdc] text-black rounded-e-[50px] text-center flex flex-col justify-between transition-transform duration-300 transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0`}
        >
          <div className="p-4 flex justify-center text-black pt-12">
            <h1 className="text-2xl font-bold">
              <img
                src="/logo/Logo Black 001.png"
                width={74}
                height={74}
                alt="Logo"
              />
            </h1>
          </div>
          <nav className="flex-1 flex flex-col justify-between my-10">
            <div className="flex flex-col gap-3">
              {links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  target={link.target}
                  className={`py-2 rounded-s-3xl ml-3 transition duration-200 flex flex-col gap-1 justify-center items-center hover:bg-[#3b71ca] hover:text-white ${
                    currentPath === link.to ? "bg-[#3b71ca] text-white" : ""
                  }`}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  {link.icon}
                  <span>{link.label}</span>
                </Link>
              ))}
            </div>
            <div>
              <Link
                to="/logout"
                className="py-3 rounded-s-3xl ml-2 transition duration-200 flex flex-col gap-2 justify-center items-center hover:bg-[#3b71ca] hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                  setIsSidebarOpen(false);
                }}
              >
                <IoIosLogOut fontSize={26} />
                <span>Logout</span>
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
