import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../../../../../utils/url";
import { error } from "@splidejs/splide/src/js/utils";

const DiamondRequestModal = ({ isOpen, onClose, onSubmit, modalType, selectedItems, checkedItems, type, id , setCheckedItems }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [payWithin, setPayWithin] = useState("30");
  // const [discountOffer, setDiscountOffer] = useState("");
  const [pctOffer, setPctOffer] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPriceOffer, setTotalPriceOffer] = useState("");
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSingleItem = selectedItems?.length == 1;

  // console.log("selectedItems", selectedItems)
  // console.log("id", id)
  // console.log("isSingleItem", isSingleItem)


  const weight = checkedItems ? parseFloat(Object.values(checkedItems)[0]?.Weight || 0) : 0;

  useEffect(() => {
    if (checkedItems && typeof checkedItems == "object" && !Array.isArray(checkedItems)) {
      const item = Object.values(checkedItems)[0];
      setPctOffer(item?.Price || checkedItems.Price || "");
      // setDiscountOffer(item?.["Discount Percent"] || "");
      setTotalPriceOffer(item?.["Total Price"] || item?.TotalPrice || checkedItems["Total Price"] || "");
      setTotalPrice(item?.["Total Price"] || item?.TotalPrice || checkedItems["Total Price"] || "");
    } else {
      console.log("checkedItems is undefined or not an object");
    }
  }, [checkedItems]);

  // Update Total Price Offer based on Pct Offer change
  const handlePctOfferChange = (value) => {
    setPctOffer(value);
    const pctValue = parseFloat(value);

    if (!isNaN(pctValue) && weight > 0) {
      // Calculate Total Price Offer
      const calculatedTotalPriceOffer = pctValue * weight;
      setTotalPriceOffer(calculatedTotalPriceOffer.toFixed(2));
    } else {
      setTotalPriceOffer(""); // Clear if input is invalid
    }
  };

  // Update Pct Offer based on Total Price Offer change
  const handleTotalPriceChange = (value) => {
    setTotalPriceOffer(value);
    const totalPriceValue = parseFloat(value);

    if (!isNaN(totalPriceValue) && weight > 0) {
      // Calculate Pct Offer
      const calculatedPctOffer = totalPriceValue / weight;
      setPctOffer(calculatedPctOffer.toFixed(2));
    } else {
      setPctOffer(""); // Clear if input is invalid
    }
  };


  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Simulate an async operation
    setTimeout(() => {
      // Simulate successful response
      onSubmit({
        selectedOption,
        comment,
        modalType,
        id,
        payWithin,
        pctOffer,
        totalPrice,
        totalPriceOffer,
        setCheckedItems
        // discountOffer,
      });
      setIsSubmitting(false);
      setSelectedOption("");
      setComment("");
      setPayWithin("");
      // setDiscountOffer("");
      setPctOffer("");
      setTotalPriceOffer("");
      onClose();
    }, 1000); // Adjust this duration as needed
  };

  if (!isOpen) return null;

  console.log("modalType", modalType)

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300">
      <div className="bg-white p-6 rounded-lg max-w-sm w-full relative transition-transform duration-300">
        <button
          onClick={onClose}
          className="absolute top-5 right-4 text-gray-500 hover:text-gray-700"
        >
          <IoClose fontSize={24} />
        </button>
        <h2 className="text-lg font-semibold mb-4">
          {modalType == "memo"
            ? "Memo Request"
            : modalType == "invoice"
              ? "Invoice Request"
              : "Hold Request"}
        </h2>
        {modalType === "invoice" && isSingleItem && type === "diamond" ? (
          <div className="invoice permission">
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Pay Within (Days of Invoice)
              </label>
              <input
                modaltype="text"
                value={payWithin}
                onChange={(e) => setPayWithin(e.target.value)}
                className="border rounded-lg p-2 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                P/ct Offer
              </label>
              <input
                modaltype="text"
                value={pctOffer}
                onChange={(e) => handlePctOfferChange(e.target.value)}
                className="border rounded-lg p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Total Price
              </label>
              <input
                modaltype="text"
                readOnly
                value={totalPrice}
                className="border rounded-lg p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Total Price Offer
              </label>
              <input
                modaltype="text"
                value={totalPriceOffer}
                onChange={(e) => handleTotalPriceChange(e.target.value)}
                className="border rounded-lg p-2 w-full"
              />
            </div>
          </div>
        ) : modalType === "invoice" && type === "jewelry" ? (
          <div className="invoice permission">
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Pay Within (Days of Invoice)
              </label>
              <input
                modaltype="text"
                value={payWithin}
                onChange={(e) => setPayWithin(e.target.value)}
                className="border rounded-lg p-2 w-full"
              />
            </div>
            {isSingleItem && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Total Price Offer
                </label>
                <input
                  modaltype="text"
                  value={totalPrice}
                  onChange={(e) => setTotalPrice(e.target.value)}
                  className="border rounded-lg p-2 w-full"
                />
              </div>
            )}
          </div>
        ) : modalType != "memo" && modalType != "invoice" ? (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Duration</label>
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="border rounded-lg p-2 w-full"
            >
              <option value="">Select Duration</option>
              <option value="6 Hours">6 Hours</option>
              <option value="12 Hours">12 Hours</option>
              <option value="24 Hours">24 Hours</option>
              <option value="36 Hours">36 Hours</option>
              <option value="48 Hours">48 Hours</option>
              <option value="72 Hours">72 Hours</option>
              <option value="4 Days">4 Days</option>
            </select>
          </div>

        ) : null}
        {/* Comment section is included for all modalTypes */}
        <div className="mb-4">
          <label htmlFor="comment" className="block text-sm font-medium mb-2">
            Your Comment (Optional)
          </label>
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="border rounded-lg p-2 w-full"
            rows="4"
          />
        </div>
        <button
          onClick={handleSubmit}
          className={`bg-blue-500 text-white py-2 px-4 rounded-lg ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>

      </div>
    </div>,
    document.body
  );
};

export default DiamondRequestModal;
