import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../redux/slice/memoSlice/memopageSlice";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { baseUrl } from "../../../utils/url";
import { MdDelete } from "react-icons/md";

export default function DashboardMemo() {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.memopage);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/items/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Network response was not ok");
    } catch (err) {
      setError(err.message);
    }
  };

  // Column definitions for diamonds
  const diamondColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;

            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1;
            const year = parseInt(dateParts[2], 10);

            const cellDate = new Date(year, month, day);

            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true,
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0];
          }
          return "";
        },
      },
      { headerName: "Stock #", field: "Stock #" },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Availability", field: "Availability" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },
      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   cellRenderer: (params) => (
      //     <span
      //     className="w-full flex justify-center items-center h-full"
      //     onClick={() => handleDelete(params.data)}
      //   >
      //     <MdDelete fontSize={23} />
      //   </span>
      //   ),
      //   width: 100,
      // },
    ],
    []
  );

  // Column definitions for jewelry
  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;

            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1;
            const year = parseInt(dateParts[2], 10);

            const cellDate = new Date(year, month, day);

            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true,
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0];
          }
          return "";
        },
      },
      { headerName: "Sku #", field: "Sku #", width: 100, filter: true },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Lot Name", field: "Lot Name", width: 120, filter: true },
      {
        headerName: "Jewelry Type",
        field: "Jewelry Type",
        width: 140,
        filter: true,
      },
      {
        headerName: "Jewelry Subtype",
        field: "Jewelry Subtype",
        width: 120,
        filter: true,
      },
      {
        headerName: "Metal Type",
        field: "Metal Type",
        width: 150,
        filter: true,
      },
      {
        headerName: "Setting Type",
        field: "Setting Type",
        width: 140,
        filter: true,
      },
      {
        headerName: "Orientation",
        field: "Orientation",
        width: 140,
        filter: true,
      },
      {
        headerName: "Diamond Shape",
        field: "Diamond Shape",
        width: 150,
        filter: true,
      },
      {
        headerName: "Total Diamond Weight",
        field: "Total Diamond Weight",
        width: 180,
        filter: true,
      },
      {
        headerName: "Total Diamond Pcs",
        field: "Total Diamond Pcs",
        width: 180,
        filter: true,
      },
      {
        headerName: "Total Price",
        field: "Total Price",
        width: 150,
        filter: true,
      },
      {
        headerName: "Lab Name",
        field: "Lab Name",
        width: 150,
        filter: true,
      },
      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   cellRenderer: (params) => (
      //     <span
      //     className="w-full flex justify-center items-center h-full"
      //     onClick={() => handleDelete(params.data)}
      //   >
      //     <MdDelete fontSize={23} />
      //   </span>
      //   ),
      //   width: 100,
      // },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  // Split row data into diamonds and jewelry
  const diamondRowData = useMemo(() => {
    if (data && data.length) {
      return data
        .filter((item) => item.type === "diamond")
        .flatMap((item) => item.item_details);
    }
    return [];
  }, [data]);

  const jewelryRowData = useMemo(() => {
    if (data && data.length) {
      return data
        .filter((item) => item.type === "jewelry")
        .flatMap((item) => item.item_details);
    }
    return [];
  }, [data]);

  return (
    <section>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Diamond Memo Request</span>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "380px", marginBottom: "20px" }}
      >
        <AgGridReact
          rowData={diamondRowData}
          columnDefs={diamondColumnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={50}
        />
      </div>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Jewelry Memo Request</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "380px" }}>
        <AgGridReact
          rowData={jewelryRowData}
          columnDefs={jewelryColumnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={50}
        />
      </div>
    </section>
  );
}
