import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

// Define the initial state
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchAdminAllOrders = createAsyncThunk(
  "orderpage/fetchAdminAllOrders",
  async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/get-item-request`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch admin orders");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch admin orders: ${error.message}`);
    }
  }
);

const orderpageSlice = createSlice({
  name: "allorderpage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminAllOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminAllOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAdminAllOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default orderpageSlice.reducer;
