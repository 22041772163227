import DiamondMtach from "../../../../components/Inventory/Search/diamondssearch/DiamondMtach";
import { DefaultLayout } from "../../../../components/layout/defaultLayout";

import React from "react";

export default function Match() {
  return (
    <DefaultLayout>
      <DiamondMtach />
    </DefaultLayout>
  );
}
