import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchJewelrySlice } from "../../../../redux/slice/savesearchSlice/saveJwelerysearchSlice";
import { fetchSaveSearchSlice } from "../../../../redux/slice/savesearchSlice/savesearchSlice";
import { baseUrl } from "../../../../utils/url";
import Swal from "sweetalert2";
import Modal from "../../../../components/Inventory/Search/savesearch/Modal/Modal";
import { MainNavbar } from "../../../../components/mainnavbar/MainNavbar";
import { JeweleryData } from "../../../../components/Inventory/Search/jewelerysearch/JeweleryData";
import { AdminDefaultLayout } from "../../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import { AdminMainNavbar } from "../adminmainnavbar/adminMainNavbar";

const AdminJewelerySearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data?.parsedCriteria;

  const [showAll, setShowAll] = useState(false);
  const [showAllStoneShapes, setShowAllStoneShapes] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState(
    data?.["Jewelry Type"] || []
  );
  const [selectedSubTypes, setSelectedSubTypes] = useState(
    data?.["Jewelry Subtype"] || []
  );
  const [selectedSettings, setSelectedSettings] = useState(
    data?.["Setting Type"] || []
  );
  const [selectedOrientation, setSelectedOrientation] = useState(
    data?.Orientation || []
  );
  const [selectedMetals, setSelectedMetals] = useState(
    data?.["Metal Type"] || []
  );
  const [selectedDiamondCts, setSelectedDiamondCts] = useState(
    data?.["Total Diamond Weight"] || []
  );
  const [selectedStoneShapes, setSelectedStoneShapes] = useState(
    data?.["Diamond Shape"] || []
  );
  const [selectedAvailability, setSelectedAvailability] = useState(
    data?.Avability || []
  );
  const [selectedF_Color, setSelectedF_Color] = useState(data?.Color || []);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedRvailability, setSelectedRvailability] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [name, setName] = useState("");

  const [diamondCtFrom, setDiamondCtFrom] = useState("");
  const [diamondCtTo, setDiamondCtTo] = useState("");

  const handleDiamondCtFromChange = (e) => {
    setDiamondCtFrom(e.target.value);
  };

  const handleDiamondCtToChange = (e) => {
    setDiamondCtTo(e.target.value);
  };

  const isWithinRange = (item) => {
    const from = parseFloat(diamondCtFrom);
    const to = parseFloat(diamondCtTo);
    const diamondCt = parseFloat(item);

    if (!isNaN(from) && !isNaN(to)) {
      return diamondCt >= from && diamondCt <= to;
    } else if (!isNaN(from)) {
      return diamondCt >= from;
    } else if (!isNaN(to)) {
      return diamondCt <= to;
    }
    return false;
  };

  const subtypeMapping = {
    Bracelet: [
      "Station",
      "Tennis",
      "Bangles",
      "Station Line Tennis",
      "Double Strand Station",
      "Special",
      "Riviera",
    ],
    Necklace: [
      "Tennis",
      "Station",
      "Riviera",
      "Lariat",
      "Double Strand Station",
      "Station Line Tennis",
      "Special",
    ],
    Earring: [
      "Studs",
      "C Hoops",
      "Hoops",
      "Crawler",
      "Huggies",
      "Hanging",
      "Halo Studs",
      "Martini",
      "Omega Hoops",
      "Special",
    ],
    "Eternity band": [
      "Eternity Band",
      "Half Eternity",
      "3/4 Band",
      "5 Stones Band",
      "7 Stones",
    ],
    Ring: [
      "3 Stones",
      "3 Stones Halo",
      "5 Stones",
      "Hidden Halo",
      "Semi Hidden Halo",
      "Shank",
      "7 Stones",
      "Special",
      "Solitaire",
      "Solitaire Diamond Shank",
      "Split Shank",
      "Halo",
    ],
    Pendant: [
      "Solitaire",
      "Special",
      "Double Halo",
      "Halo",
      "Pendant",
      "Symbol",
    ],
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSaveSearchSlice());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchJewelrySlice());
  }, [dispatch]);

  const handleFromChange = (e) => {
    let value = e.target.value
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPriceFrom(value);
  };

  const handleToChange = (e) => {
    let value = e.target.value
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPriceTo(value);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleShowAllStoneShapes = () => {
    setShowAllStoneShapes(!showAllStoneShapes);
  };

  const visibleStoneShapes = showAllStoneShapes
    ? JeweleryData.stoneshape
    : JeweleryData.stoneshape.slice(0, 11);

  const toggleSelection = (item, selectedItems, setSelectedItems) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems?.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const allVisibleItems =
    Array.isArray(selectedTypes) && selectedTypes.length > 0
      ? [
          ...new Set(
            selectedTypes.map((type) => subtypeMapping[type] || []).flat()
          ),
        ] // Ensure uniqueness
      : JeweleryData.subtype;

  const visibleItems = showAll
    ? [...new Set(allVisibleItems)]
    : [...new Set(allVisibleItems.slice(0, 9))];

  const handleSubmit = async () => {
    const locationMap = {
      "New York": "NY Office",
      "Los Angeles": "LA Office",
    };
    const selectedFilters = {
      "Jewelry Type": selectedTypes,
      "Jewelry Subtype": selectedSubTypes,
      "Setting Type": selectedSettings,
      Orientation: selectedOrientation,
      "Metal Type": selectedMetals,
      "Total Diamond Weight": selectedDiamondCts,
      "Diamond Shape": selectedStoneShapes,
      Avability: selectedAvailability,
      "Lab Name": selectedRvailability,
      Color: selectedF_Color,
      "Department Account Name": selectedLocation.map(
        (location) => locationMap[location]
      ),
    };

    if (priceFrom) {
      selectedFilters["Total Price"] = [`${priceFrom}-${priceTo}`];
    }

    if (diamondCtFrom || diamondCtTo) {
      selectedFilters["Total Diamond Weight"] = [
        `${diamondCtFrom}-${diamondCtTo}`,
      ];
    }

    const filteredRequestData = Object.fromEntries(
      Object.entries(selectedFilters).filter(
        ([key, value]) => value !== null && value.length !== 0
      )
    );

    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(`${baseUrl}/users/searchresult`, {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(filteredRequestData),
      });

      if (!response.ok) {
        setIsModalOpen(false);
        throw new Error("Network response was not ok");
      }
      dispatch(fetchJewelrySlice());

      const data = await response.json();

      const searchId = data.id;

      navigate(`/admininventorypage/adminJewelrycardpage?searchId=${searchId}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveSearch = async () => {
    const id = sessionStorage.getItem("id");
    const locationMap = {
      "New York": "NY Office",
      "Los Angeles": "LA Office",
    };
    const requestData = {
      criteria: {
        "Jewelry Type": selectedTypes,
        "Jewelry Subtype": selectedSubTypes,
        "Setting Type": selectedSettings,
        Orientation: selectedOrientation,
        "Metal Type": selectedMetals,
        "Total Diamond Weight": selectedDiamondCts,
        "Diamond Shape": selectedStoneShapes?.includes("Cushion")
          ? [...selectedStoneShapes, "Cushion Brilliant", "Cushion Modified"]
          : selectedStoneShapes,
        Avability: selectedAvailability,
        "Department Account Name": selectedLocation.map(
          (location) => locationMap[location]
        ),
        "Lab Name": selectedRvailability,
      },
      user_id: id,
      save_list: true,
      save_name: name,
      type: "jewelry",
    };

    if (priceFrom) {
      requestData["Total Price"] = [`${priceFrom}-${priceTo}`];
    }

    if (diamondCtFrom || diamondCtTo) {
      requestData["Total Diamond Weight"] = [`${diamondCtFrom}-${diamondCtTo}`];
    }

    const filteredRequestData = JSON.parse(
      JSON.stringify(requestData, (key, value) => {
        if (value === null || value === undefined || value.length === 0) {
          return undefined;
        }
        return value;
      })
    );
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(filteredRequestData),
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/users/add-to-table`,
        requestOptions
      );

      const responseData = await response.json();
      const responseMessage = responseData.message;

      if (response.ok) {
        dispatch(fetchSaveSearchSlice());
        setIsModalOpen(false);
        Swal.fire({
          icon: "success",
          text: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating like status:", error);
      Swal.fire({
        icon: "error",
        title:
          "An error occurred. Please check your network connection and try again.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleReset = () => {
    setSelectedTypes([]);
    setSelectedSubTypes([]);
    setSelectedSettings([]);
    setSelectedOrientation([]);
    setSelectedMetals([]);
    setSelectedDiamondCts([]);
    setSelectedStoneShapes([]);
    setSelectedAvailability([]);
    setSelectedRvailability([]);
    setSelectedF_Color([]);
  };

  const search = "jewelry";

  return (
    <>
      <section className="lg:p-4 uppercase">
        <AdminMainNavbar search={search} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3">
          <div className="lg:grid grid-cols-8 items-center pb-2 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Type</h2>
            <div className="flex flex-wrap gap-2 col-span-7">
              {JeweleryData.type.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-3 text-[15px] rounded transition-all duration-300 ease-in-out ${
                    selectedTypes.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() => {
                    toggleSelection(item, selectedTypes, setSelectedTypes);
                    if (!selectedTypes.includes(item)) {
                      setSelectedSubTypes([]);
                    }
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-2 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Sub-Type</h2>
            <div className="flex flex-wrap gap-2 col-span-7">
              {Array.isArray(visibleItems) && visibleItems.length > 0 ? (
                visibleItems.map((item) => (
                  <button
                    key={item}
                    className={`px-10 py-3 text-[15px] rounded transition-all duration-300 ease-in-out ${
                      selectedSubTypes.includes(item)
                        ? "bg-blue-500 text-white shadow-lg"
                        : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                    }`}
                    onClick={() =>
                      toggleSelection(
                        item,
                        selectedSubTypes,
                        setSelectedSubTypes
                      )
                    }
                  >
                    {item}
                  </button>
                ))
              ) : (
                <p>No subtypes available for the selected type.</p>
              )}
              {allVisibleItems.length > 9 && (
                <button
                  onClick={toggleShowAll}
                  className="py-2 uppercase text-[15px] flex justify-center items-center  bg-gray-200 rounded flex-1"
                >
                  {showAll ? (
                    <LuMinus fontSize={30} />
                  ) : (
                    <GoPlus fontSize={30} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Stone Shape</h2>
            <div className="flex flex-wrap col-span-7 gap-[8px]">
              {visibleStoneShapes.map(({ icon, text }) => (
                <button
                  key={text}
                  className={`w-28 h-auto p-1 text-[15px]  rounded flex flex-col gap-2 items-center transition-all duration-300 ease-in-out ${
                    selectedStoneShapes.includes(text)
                      ? "!bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      text,
                      selectedStoneShapes,
                      setSelectedStoneShapes
                    )
                  }
                >
                  <img
                    src={icon}
                    alt={text}
                    width={50}
                    height={80}
                    className={`${
                      selectedStoneShapes.includes(text) ? "filter invert" : ""
                    }`}
                  />
                  {text}
                </button>
              ))}
              {JeweleryData.stoneshape.length > 11 && (
                <button
                  onClick={toggleShowAllStoneShapes}
                  className="w-28 h-auto p-1 text-[15px]  rounded flex pt-6 flex-col gap-2 items-center bg-gray-200"
                >
                  {showAllStoneShapes ? (
                    <LuMinus fontSize={30} />
                  ) : (
                    <GoPlus fontSize={30} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Diamond CTTW</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              <div className="flex flex-col gap-5">
                <div className="flex gap-1">
                  <input
                    type="number"
                    className="px-2 w-20 py-1 border rounded-md"
                    placeholder="From"
                    min="0"
                    value={diamondCtFrom}
                    onChange={handleDiamondCtFromChange}
                  />
                  <input
                    type="number"
                    className="px-2 w-20 py-1 border rounded-md"
                    placeholder="To"
                    min="0"
                    value={diamondCtTo}
                    onChange={handleDiamondCtToChange}
                  />
                </div>
              </div>
              {JeweleryData.diamondCts.map((item) => (
                <button
                  key={item}
                  className={`py-2 text-[15px] px-3  rounded min-w-[60px] transition-all duration-300 ease-in-out ${
                    selectedDiamondCts.includes(item) || isWithinRange(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      item,
                      selectedDiamondCts,
                      setSelectedDiamondCts
                    )
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Setting</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {JeweleryData.setting.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedSettings.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedSettings, setSelectedSettings)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Orientation</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {JeweleryData.orientation.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedOrientation.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      item,
                      selectedOrientation,
                      setSelectedOrientation
                    )
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Metal</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {Object.keys(JeweleryData.metal).map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px] rounded transition-all duration-300 ease-in-out ${
                    selectedMetals.includes(item)
                      ? `${JeweleryData.metal[item]} bg-blue-500 text-white shadow-lg`
                      : `${JeweleryData.metal[item]} hover:bg-blue-500 hover:text-white`
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedMetals, setSelectedMetals)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">Price</h2>
            <div className="flex flex-wrap col-span-7 gap-5 items-center">
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="From"
                    value={priceFrom}
                    onChange={handleFromChange}
                  />
                  <span className="absolute right-2 top-2 text-gray-500 text-[12px] mt-1">
                    USD
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div className="relative flex gap-1">
                  <input
                    type="text"
                    className="px-4 w-50 py-2 border rounded-md bg-gray-200"
                    placeholder="To"
                    value={priceTo}
                    onChange={handleToChange}
                  />
                  <span className="absolute text-center right-2 top-2 text-gray-500 text-[12px] mt-1">
                    USD
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">availability</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {JeweleryData.availability.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedAvailability.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(
                      item,
                      selectedAvailability,
                      setSelectedAvailability
                    )
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">F_Color</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {JeweleryData.F_Color.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedF_Color.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedF_Color, setSelectedF_Color)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="lg:grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
            <h2 className="font-bold text-[18px]">location</h2>
            <div className="flex flex-wrap col-span-7 gap-2">
              {JeweleryData.location.map((item) => (
                <button
                  key={item}
                  className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                    selectedLocation.includes(item)
                      ? "bg-blue-500 text-white shadow-lg"
                      : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                  }`}
                  onClick={() =>
                    toggleSelection(item, selectedLocation, setSelectedLocation)
                  }
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          {/* <div className="grid grid-cols-8 items-center pb-3 border-slate-200 border-b-2">
          <h2 className="font-bold text-[18px]">report</h2>
          <div className="flex flex-wrap col-span-7 gap-2">
            {JeweleryData.report.map((item) => (
              <button
                key={item}
                className={`px-10 py-2 text-[15px]  rounded transition-all duration-300 ease-in-out ${
                  selectedRvailability.includes(item)
                    ? "bg-blue-500 text-white shadow-lg"
                    : "bg-gray-200 hover:!bg-blue-500 hover:text-white"
                }`}
                onClick={() =>
                  toggleSelection(
                    item,
                    selectedRvailability,
                    setSelectedRvailability
                  )
                }
              >
                {item}
              </button>
            ))}
          </div>
        </div> */}
        </div>
        <div className="text-center ">
          <button
            onClick={handleSubmit}
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded mr-2 "
          >
            Search
          </button>
          <button
            onClick={() => setIsModalOpen(true)} // Open the modal
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded mr-2"
          >
            Save Search
          </button>
          <button
            onClick={handleReset}
            className="mt-4 px-4 py-2 bg-gray-300 text-black hover:bg-blue-500 hover:text-white rounded"
          >
            Reset
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveSearch}
          setName={setName}
          name={name}
        />
      </section>
    </>
  );
};

export default AdminJewelerySearch;
