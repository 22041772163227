
import JewelryDetails from "../../../../components/Inventory/Search/jewelerysearch/jeweleryCard/jeweleryDynamicCard/JeweleryDetails";
import React from 'react'
import { DefaultLayout } from "../../../../components/layout/defaultLayout";

export default function JewelryDynamicCard() {
  return (
    <DefaultLayout>
    <JewelryDetails />
    </DefaultLayout>
  )
}
