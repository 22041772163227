// src/features/orderpage/orderpageSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

// Define the initial state 


export const fetchOrderData = createAsyncThunk(
  "orderpage/fetchOrderData",
  async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/users/get-request`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch SaveSearch");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch SaveSearch: ${error.message}`);
    }
  }
);


const orderpageSlice = createSlice({
  name: "orderpage",
  initialState : {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchOrderData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default orderpageSlice.reducer;
