// wishlistSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../../../utils/url';

export const fetchWishlistSlice = createAsyncThunk('wishlist/fetchWishlistSlice', async () => {
  try {
    const token = sessionStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/users/get-wish`, { method:'GET' , headers: myHeaders , redirect: "follow", });

    if (!response.ok) {
      throw new Error('Failed to fetch wishlist');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch wishlist: ${error.message}`);
  }
});

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    wishlist: [], 
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishlistSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWishlistSlice.fulfilled, (state, action) => {
        state.wishlist = action.payload;
        state.loading = false;
      })
      .addCase(fetchWishlistSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default wishlistSlice.reducer;
