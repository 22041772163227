import React from "react";
import AdminUserDetails from "../../../adminComponents/adminallusers/adminuserdetails/AdminUserDetails";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminUserDetailsPage() {
  return (
    <AdminDefaultLayout>
      <AdminUserDetails />
    </AdminDefaultLayout>
  );
}
