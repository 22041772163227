import React from "react";
import AdminAllUsers from "../../adminComponents/adminallusers/AdminAllUsers";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminAllUsersPage() {
  return (
    <AdminDefaultLayout>
      <AdminAllUsers />
    </AdminDefaultLayout>
  );
}