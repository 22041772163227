

import React from "react";
import { DefaultLayout } from "../../../components/layout/defaultLayout";
import JeweleryCard from "../../../components/Inventory/Search/jewelerysearch/jewelwryCard/JeweleryCard";

export default function JeweleryCardPage() {
  return (
    <DefaultLayout>
      <JeweleryCard />
    </DefaultLayout>
  );
}