import React from "react";
import AdminAllOrders from "../../../adminComponents/admindashboard/admindashboard/adminallorders/AdminAllOrders";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminAllOrdersPage() {
  return (
    <AdminDefaultLayout>
      <AdminAllOrders />
    </AdminDefaultLayout>
  );
}
