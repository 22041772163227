import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Navbar from "../Inventory/Navbar/Navbar";
import { FaClipboardList } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoBagCheck } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { IoSave } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { baseUrl, baseUrlLocal } from "../../utils/url";
import Swal from "sweetalert2";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Dashboard() {

  const [slides, setSlides] = useState([]);
  const [requestCount, setRequestCount] = useState([]);

  useEffect(() => {
    handleImages();
    handleGetRequestCount();
  }, []);

  const handleImages = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/get-images?type=web`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }

      const data = await response.json();
      const imagesArray = JSON.parse(data.data.image);
      setSlides(imagesArray);
    } catch (error) {
      console.error("Error  images:", error);
    }
  };

  const handleGetRequestCount = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/get-request-count`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }
      const data = await response.json();
      setRequestCount(data);
    } catch (error) {
      console.error("Error  images:", error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-row sm:flex-row justify-between align-middle pb-5">
        <h1 className="text-2xl font-bold sm:text-3xl text-center">Welcome To Skylab Diamond</h1>
        <Navbar className="sm:pl-4" />
      </div>

      {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-5 ">
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold justify-center hover:text-[#fff] items-center gap-2  border hover:shadow-xl   rounded-lg text-center">
          <Link to="/memopage">
            <div className="flex flex-col justify-center items-center gap-1">
              <FaClipboardList fontSize={30} />
              Open Memos ({requestCount.memo})*
            </div>
          </Link>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center gap-2 border hover:shadow-xl   rounded-lg text-center">
          <Link to="/invoicepage">
            <div className="flex flex-col justify-center items-center gap-1">
              <FaFileInvoiceDollar fontSize={30} />
              Open Invoices ({requestCount.invoice})*
            </div>
          </Link>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center gap-2 border hover:shadow-xl   rounded-lg text-center">
          <Link to="/orderpage">
            <div className="flex flex-col justify-center items-center gap-1">
              <IoBagCheck fontSize={30} />
              Open Orders ({requestCount.total})*
            </div>
          </Link>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 rounded-lg hover:text-[#fff] border hover:shadow-xl ">
          <Link to="/inventorypage/savesearchpage">
            <span className="flex justify-center flex-col gap-2 items-center text-[18px] w-full h-full">
              <IoSave fontSize={30} />
              <h2 className="font-bold mb-2">Saved Searches</h2>
            </span>
          </Link>
        </div> */}
        {/* <div className="bg-gray-100 hover:bg-[#3b82f680] p-8 flex flex-col font-semibold hover:text-[#fff] justify-center items-center border hover:shadow-xl   rounded-lg textrounded-mdcenter">
          <FaMoneyCheckDollar fontSize={30} />
          Next Payment (8204.4)*
          <br />
          <span className="text-base text-gray-500 font-bold">05/01/2024</span>
        </div> */}
      {/* </div> */}
      {/* <div className="grid grid-cols-3 gap-4 mb-5 ">
        <div className="bg-gray-50 hover:bg-[#3b82f680] p-4 rounded-lg border hover:shadow-xl ">
          <h1 className="font-bold mb-2">Recent Searches</h1>
          <ul className="list-disc list-inside">
            <li>All 25/07/2024 04:02 AM</li>
            <li>All 25/07/2024 03:59 AM</li>
            <li>All 25/07/2024 03:59 AM</li>
            <li>BR 23/07/2024 12:51 PM</li>
            <li>All 23/07/2024 12:50 PM</li>
          </ul>
        </div>
        <div className="bg-gray-100 hover:bg-[#3b82f680] pt-2 p-10 rounded-lg text-center border hover:shadow-xl ">
          <h2 className="font-bold mb-2">Your personal Sales Executive</h2>
          <p>Test</p>
          <p>Email: test@gmail.com</p>
          <p>Phone: [Your phone number here]</p>
        </div>
      </div> */}
      <div className="bg-gray-100 p-4 flex justify-center items-center gap-5 arr-bttn">
        <Splide
          options={{
            // type: "loop",
            perPage: 1,
            perMove: 1,
            pagination: false,
            autoplay: true,
          }}
        >
          {slides.map((slide, index) => (
            <SplideSlide key={index}>
              <div className="p-4 border rounded-lg bg-white shadow-md">
                <img
                  src={`${baseUrlLocal}/${slide}`}
                  alt="diamond"
                  className=" w-full h-[350px]"
                />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}
