import React from "react";
import AdminAddNewUser from "../../../adminComponents/adminallusers/adminaddconsumeruser/AdminAddConsumer";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import AdminAddConsumer from "../../../adminComponents/adminallusers/adminaddconsumeruser/AdminAddConsumer";

export default function AdminAddConsumerPage() {
  return (
    <AdminDefaultLayout>
      <AdminAddConsumer />
    </AdminDefaultLayout>
  );
}
