import React, { useState } from "react";

const Modal = ({ isOpen, onClose, onSave , setName , name }) => {
  

  if (!isOpen) return null;

  const handleSave = () => {
    if (name.trim() !== "") {
      onSave(name);
      setName("");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-80">
        <h3 className="text-lg font-bold mb-2">Save Search</h3>
        <input
          type="text"
          className="w-full px-2 py-1 border rounded-md mb-4"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
