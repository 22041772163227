// src/components/GlobalWrapper.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearExpiredStorage } from "./storage";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/slice/logout/logout";

export const clearsessionStorage = async () => {
  await sessionStorage?.removeItem("id");
  await sessionStorage?.removeItem("first_name");
  await sessionStorage?.removeItem("UrI");
  await sessionStorage?.removeItem("token");
  await sessionStorage?.removeItem("email");
  await sessionStorage?.removeItem("pmatSEmItnigOL");
  localStorage?.removeItem("token");
  // localStorage?.removeItem("hasModalOpened");
};

const GlobalWrapper = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isTabClosing = false;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      isTabClosing = true;
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    const handleUnload = async () => {
      if (isTabClosing) {
        await dispatch(logoutUser());
        await clearsessionStorage();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const storageCleared = clearExpiredStorage();
  //   if (storageCleared) {
  //     navigate("/");
  //   }
  // }, [navigate]);

  return <>{children}</>;
};

export default GlobalWrapper;
