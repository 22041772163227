import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

// Async thunk to fetch data from the API
export const fetchInvoiceData = createAsyncThunk(
  "invoicepage/fetchInvoiceData",
  async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/users/get-request?request_type=invoice`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch SaveSearch");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch SaveSearch: ${error.message}`);
    }
  }
);

const invoicepageSlice = createSlice({
  name: "invoicepage",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvoiceData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchInvoiceData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default invoicepageSlice.reducer;
