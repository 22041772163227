import React, { useState } from "react";
import { FaInfo, FaShoppingCart } from "react-icons/fa";
import { FaShareNodes } from "react-icons/fa6";
import { IoDiamond } from "react-icons/io5";
import { PiWhatsappLogoBold } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { fetchCartList } from "../../../../../../redux/slice/cartSlice/cartSlice";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl, baseUrlLocal } from "../../../../../../utils/url";
import Swal from "sweetalert2";
import { CLASS_TOGGLE_PAUSE } from "@splidejs/splide";

export default function Menu({ searchId, item, type }) {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false); // State to handle popup visibility
  const [sendEmail, setSendEmail] = useState("");
  const [sendComments, setSendComments] = useState("");

  const addToCart = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: item["Sku #"] ? "jewelry" : "diamond",
          productId: id,
          quantity: 1,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/add-to-cart`,
        requestOptions
      );

      const data = await response.json();

      if (response.ok) {
        await Swal.fire({
          title: "Success!",
          text: "Add To The Cart Successfully!",
          icon: "success",
        });
        dispatch(fetchCartList());
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Item Already Add In Cart!",
      });
    }
  };
  const handleShareClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const setPathParams = (type, id) => {
    const url = new URL(window.location.href);
    url.pathname = `/emailshare/${type}/${id}`;

    // Instead of updating the visible URL, use it only internally
    return url.toString();
  };
  // Example usage

  const handleEmailShare = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json"); // Set the content type to JSON

      if (!validateEmails(sendEmail)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Email Address",
          text: "Please enter a valid email address.",
        });
        return;
      }

      const productIds = id ? Number(id) : [];
      console.log("id",id)
      console.log("productIds",productIds)
      console.log("sendEmail",sendEmail)
      
      const payload = {
        productId: [productIds], 
       type:type,
       comments:sendComments,
       email:sendEmail,
      }
      await setPathParams(type, id);
      const response = await fetch(`${baseUrl}/users/receipt-email`, {
        headers: myHeaders,
        method: "POST",
        body:JSON.stringify(payload),
      });

      const data = await response.json();
      setShowPopup(false);

      if (data.token) {
        localStorage.setItem("e_token", data.token);
      }

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      Swal.fire({
        icon: "success",
        title: "Email Sent",
        text: "Your email was successfully sent!",
      });

      setSendEmail("");
      setSendComments("");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending the email. Please try again later.",
      });

      console.error("Error to send email", error);
    }
  };
  // Function to validate email addresses
  const validateEmails = (emailString) => {
    const emails = emailString.split(/[,\s;]/).filter(Boolean);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.every((email) => emailPattern.test(email));
  };
  const handleWhatsAppShare = (item) => {
    const message = `Check out this item: ${item["Stock #"] || item["Sku #"]}\n ${item["Title"] || item.Shape
      }
    ${item.Weight}
    ${item.Color}
    ${item.Clarity}
    \n https://mail.itemlinkshare.com/emailshare/${type}/${item.id}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };
  const role_id = sessionStorage.getItem("UrI");
  return (
    <>
      <div className="origin-top-right absolute right-0 mt-2 w-56 z-30 rounded-md shadow-lg bg-slate-100 ring-1 ring-black ring-opacity-5">
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <Link
            to={
              role_id == 1 
                ? item["Sku #"] 
                  ? `/inventorypage/jewelerycardpage/${searchId}/${item["Sku #"]}` 
                  : `/inventorypage/diamondcardpage/${searchId}/${item.id}`
                : item["Sku #"] 
                  ? `/admininventorypage/adminjewelerycardpage/${searchId}/${item["Sku #"]}`
                  : `/admininventorypage/admindiamondcardpage/${searchId}/${item.id}`
            }
            className="px-4 py-4 flex gap-2 items-center text-sm text-gray-700 hover:bg-blue-100"
            role="menuitem"
          >
            <span>
              <FaInfo fontSize={18} />
            </span>
            <span className="font-semibold"> View Details</span>
          </Link>
          {role_id == "1" && (
            <button
              className="px-4 py-4 flex gap-2 items-center text-sm w-full text-gray-700 hover:bg-blue-100"
              onClick={() => addToCart(item.id)}
            >
              <span>
                <FaShoppingCart fontSize={18} />
              </span>
              <span className="font-semibold"> Add To Cart</span>
            </button>
          )}
          {item["Stock #"] && (
            <Link
              to={`/inventorypage/diamondcardpage/match?Shape=${item.Shape}&Color=${item.Color}&Clarity=${item.Clarity}&Weight=${item.Weight}`}
              className="px-4 py-4 flex gap-2 items-center text-sm text-gray-700 hover:bg-blue-100"
              role="menuitem"
            >
              <span>
                <IoDiamond fontSize={18} />
              </span>
              <span className="font-semibold"> Find Match</span>
            </Link>
          )}
          <div
            className="px-4 py-4 flex gap-2 items-center text-sm text-gray-700 hover:bg-blue-100 cursor-pointer"
            onClick={handleShareClick}
          >
            <span>
              <FaShareNodes fontSize={18} />
            </span>
            <span className="font-semibold"> Share Via Email</span>
          </div>
          <div
            className="px-4 py-4 flex gap-2 items-center text-sm text-gray-700 hover:bg-blue-100 cursor-pointer"
            onClick={() => handleWhatsAppShare(item)}
          >
            <span>
              <PiWhatsappLogoBold fontSize={18} />
            </span>
            <span className="font-semibold"> Share Via WhatsApp</span>
          </div>
        </div>
      </div >

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Share {type}</h3>
              <button onClick={handleClosePopup}>
                <IoCloseSharp fontSize={25} />
              </button>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-semibold text-gray-700">
                Enter Recipient's Email
              </label>
              <input
                type="email"
                value={sendEmail}
                onChange={(e) => setSendEmail(e.target.value)}
                placeholder="Please Enter Email."
                className="mt-1 block border p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
              <span className="text-sm pt-2">
                Please use a comma or semicolon to separate multiple email
                addresses.
              </span>
            </div>
            <div className="mt-6">
              <label className="block text-sm font-semibold text-gray-700">
                Your Comment (Optional)
              </label>
              <textarea
                className="mt-1 block p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows="3"
                value={sendComments}
                onChange={(e) => setSendComments(e.target.value)}
              />
            </div>

            <div className="mt-6 flex justify-center gap-6">
              <button
                onClick={() => handleEmailShare(item.id)}
                className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
              >
                Share
              </button>
              <button
                onClick={handleClosePopup}
                className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
