
import cartReducer from '../slice/cartSlice/cartSlice';
import wishlistReducer from '../slice/wishlistSlice/wishlistSlice'; 
import savesearchReducer from '../slice/savesearchSlice/savesearchSlice'; 
import saveJewelrySearchReducer from '../slice/savesearchSlice/saveJwelerysearchSlice'; 
import matchDiamondReducer from '../slice/matchDiamondSlice/matchDiamondSlice'; 
import memopageReducer from '../slice/memoSlice/memopageSlice'; 
import invoicepageReducer from '../slice/InvoiceSlice/invoicepageSlice'; 
import orderpageReducer from '../slice/orderSlice/orderpageSlice'; 
import userReducer from '../slice/getUser/getUser'
import allorderpageReducer from '../slice/adminallorders/AdminAllOrders'
import allinvoiceReducer from '../slice/adminallinvoice/AdminAllInvoice'
import allmemoReducer from '../slice/adminallmemo/adminallmemoSlice'
import allholdReducer from '../slice/adminallhold/AdminallHold'
import modifySearchReducer from '../slice/modifysearch/ModifySearch'
import logoutReducer from '../slice/logout/logout'
import { configureStore } from '@reduxjs/toolkit';


const store = configureStore({
  reducer: { 
    user: userReducer,
    cart: cartReducer,
    wishlist: wishlistReducer, 
    savesearch: savesearchReducer, 
    savesearchjwelery: saveJewelrySearchReducer, 
    diamonds: matchDiamondReducer,
    memopage: memopageReducer,   
    invoicepage: invoicepageReducer,
    orderpage: orderpageReducer,
    allorderpage: allorderpageReducer,
    allinvoice: allinvoiceReducer,
    allmemo: allmemoReducer,
    allhold: allholdReducer,
    modifySearch: modifySearchReducer,
    logout: logoutReducer,
  },
});

export default store;
