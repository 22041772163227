import React from "react";
import AdminSidebar from "../../SideBar/adminsidebar/AdminSidebar";
import SideBar from "../../SideBar/SideBar";
export const AdminDefaultLayout = ({ children }) => {
  return (
    <div className="flex">
 
      <AdminSidebar />
      <main className="flex-1 p-4">
        {children}
      </main>
    </div>
  );
};

