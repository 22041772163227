import React from 'react'
import { AdminDefaultLayout } from '../../../../components/layout/adminDefaultLayout/AdminDefaultLayout'
import AdminAddNewCompany from '../../../../adminComponents/adminallusers/AdminAddNewCompany/AdminAddNewCompany'

export default function AdminAddNewCompanyPage() {
  return (
    <AdminDefaultLayout>
      <AdminAddNewCompany />
    </AdminDefaultLayout>
  )
}
