// Import necessary functions from Redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../../../utils/url';

// Initial state for diamonds slice
const initialState = {
  diamonds: null,
  loading: false,
  error: null,
};

// Async thunk to fetch diamonds data
export const fetchDiamonds = createAsyncThunk(
  'diamonds/fetchDiamonds',
  async ({ Shape, Color, Clarity, Weight , Stock }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const queryParams = new URLSearchParams({ Shape, Color, Clarity, Weight , Stock });

      const response = await fetch(
        `${baseUrl}/users/find-products?${queryParams}`,
        {
          method: 'GET',
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch diamond data');
      }

      const data = await response.json();

      return data.diamonds;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define the diamonds slice
const matchDiamondSlice = createSlice({
  name: 'diamonds',
  initialState,
  reducers: {
    // Additional reducers can go here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiamonds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDiamonds.fulfilled, (state, action) => {
        state.loading = false;
        state.diamonds = action.payload;
      })
      .addCase(fetchDiamonds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectDiamonds = (state) => state.diamonds.diamonds;
export const selectLoading = (state) => state.diamonds.loading;
export const selectError = (state) => state.diamonds.error;

export default matchDiamondSlice.reducer;
