import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/url";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import Swal from "sweetalert2";

export default function AddUser() {
  const [addUserData, setAddUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    companyName: "",
    streetAddress: "",
    website: "",
    contact_person: "",
    contact_email: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    phone: "",
    office_phone: "",
    username: "",
    password: "",
    confirmPassword: "",
    request_mail: false,
    status: "",
    role: "",
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/admin/countries`)
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data.map((country) => country).sort();
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const statusOptions = [
    { value: "", label: "Select Status" },
    { value: "pending", label: "Pending" },
    { value: "approve", label: "Approve" },
    { value: "deactive", label: "Deactive" },
    { value: "decline", label: "Decline" },
  ];

  const roleOptions = [
    { value: "", label: "Select Role" },
    { value: "1", label: "User" },
    { value: "2", label: "Admin" },
    { value: "3", label: "SuperAdmin" },
  ];

  useEffect(() => {
    fetch(`${baseUrl}/admin/codes`)
      .then((response) => response.json())
      .then((data) => {
        // Sort the code array, placing "1" (for +1) at the top, and move "0" to the end or exclude it
        const sortedCode = data
          .filter((code) => code !== "0") // Optionally exclude "0"
          .sort((a, b) => {
            if (a === "1") return -1; // Move "+1" to the top
            if (b === "1") return 1;
            return a - b; // Sort remaining codes numerically
          });

        setCode(sortedCode);

        // Set default phoneCode to "+1" if it's available in the code array
        if (sortedCode.includes("1")) {
          setAddUserData((prevData) => ({
            ...prevData,
            phoneCode: "1", // Set the default phone code to "+1"
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching codes:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update user data state
    setAddUserData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
        ...(name === "email" ? { username: value } : {}), // Automatically update username when email changes
    }));

    // Fetch states if country is updated
    if (name === "country") {
        fetch(`${baseUrl}/admin/states?country=${value}`)
            .then((response) => response.json())
            .then((data) => {
                const sortedStates = data.sort(); // Assuming data is an array of states
                setStates(sortedStates);
            })
            .catch((error) => console.error("Error fetching states:", error));
    }
};

// Use an effect to validate fields whenever addUserData changes
useEffect(() => {
    const newErrors = { ...errors };

    // Validate confirmEmail
    if (addUserData.confirmEmail !== '' && addUserData.confirmEmail !== addUserData.email) {
        newErrors.confirmEmail = "Email and Confirm Email do not match";
    } else {
        delete newErrors.confirmEmail;
    }

    // Validate confirmPassword
    if (addUserData.confirmPassword !== '' && addUserData.confirmPassword !== addUserData.password) {
        newErrors.confirmPassword = "Password and Confirm Password do not match";
    } else {
        delete newErrors.confirmPassword;
    }

    // // Validate confirmUsername
    // if (addUserData.confirmUsername !== '' && addUserData.confirmUsername !== addUserData.username) {
    //     newErrors.confirmUsername = "Username and Confirm Username do not match";
    // } else {
    //     delete newErrors.confirmUsername;
    // }

    if (addUserData.password) {
        const passwordValidation = validatePassword(addUserData.password);
        if (!passwordValidation.isValid) {
            newErrors.password = passwordValidation.message;
        } else {
            delete newErrors.password;
        }
    }

    setErrors(newErrors);
}, [addUserData, errors]);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    if (password.length < minLength) {
      return {
        isValid: false,
        message: "Password must be at least 8 characters long",
      };
    }
    if (!hasUpperCase) {
      return {
        isValid: false,
        message: "Password must contain at least one uppercase letter",
      };
    }
    if (!hasLowerCase) {
      return {
        isValid: false,
        message: "Password must contain at least one lowercase letter",
      };
    }
    if (!hasNumber) {
      return {
        isValid: false,
        message: "Password must contain at least one numeric digit",
      };
    }
    return { isValid: true, message: "" };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fullPhoneNumber = `+${addUserData.phoneCode}${" "}${
      addUserData.phone
    }`;

    if (Object.keys(errors).length > 0) {
      return;
    }

    const addUserDataToSend = new FormData();
    addUserDataToSend.append("first_name", addUserData.firstName);
    addUserDataToSend.append("last_name", addUserData.lastName);
    addUserDataToSend.append("email", addUserData.email);
    addUserDataToSend.append("mobile_number", fullPhoneNumber);
    addUserDataToSend.append("name", addUserData.companyName);
    addUserDataToSend.append("website", addUserData.website);
    addUserDataToSend.append("contact_person", addUserData.contact_person);
    addUserDataToSend.append("contact_email", addUserData.contact_email);
    addUserDataToSend.append("street_address", addUserData.streetAddress);
    addUserDataToSend.append("country", addUserData.country);
    addUserDataToSend.append("state", addUserData.state);
    addUserDataToSend.append("city", addUserData.city);
    addUserDataToSend.append("zip_code", addUserData.postalCode);
    addUserDataToSend.append("office_phone", addUserData.office_phone);
    addUserDataToSend.append("user_name", addUserData.username);
    addUserDataToSend.append("password", addUserData.password);
    addUserDataToSend.append("request_mail", addUserData.request_mail ? 1 : 0);
    addUserDataToSend.append("status_id", addUserData.status);
    addUserDataToSend.append("role_id", addUserData.role);

    try {
      const myHeaders = new Headers();
      const token = sessionStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${token}`);
      const response = await fetch(`${baseUrl}/admin/admin-add-user`, {
        method: "POST",
        body: addUserDataToSend,
        headers: myHeaders,
        redirect: "follow",
      });

      const responseData = await response.json();
      const responseMessage = responseData.message;

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });

        setAddUserData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          confirmEmail: "",
          companyName: "",
          website: "",
          contact_person: "",
          contact_email: "",
          streetAddress: "",
          country: "",
          state: "",
          city: "",
          postalCode: "",
          office_phone: "",
          username: "",
          password: "",
          confirmPassword: "",
          request_mail: false,
          status: "",
          role: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: responseMessage,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      // setIsModalOpen(true);
      // Optionally handle success or redirect user here
    } catch (error) {
      console.error("Error sending form data:", error);
      // Handle error, show user a message, etc.
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: error.message, // Display the extracted error message
      });
    }
  };

  // const closeModal = () => {
  //     setIsModalOpen(false);
  // };

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        className="bg-gray-100 p-8 rounded-lg shadow-lg max-h-full w-[70%] max-w-screen"
        onSubmit={handleSubmit}
      >
        <h2 className="text-4xl font-bold text-center mb-5">Add User Form</h2>
        <div>
          <div className="grid grid-cols-3 gap-10 mb-4">
            <div className="flex flex-col justify-center gap-4">
              <div className="text-[20px] font-semibold pb-3 text-center">
                Personal Details
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  First Name<span className="text-red-500 text-[20px]">* </span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={addUserData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Last Name<span className="text-red-500 text-[20px]">* </span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={addUserData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Email Address
                  <span className="text-red-500 text-[20px]">* </span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={addUserData.email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Confirm Email Address
                  <span className="text-red-500 text-[20px]">* </span>
                </label>
                <input
                  type="email"
                  name="confirmEmail"
                  placeholder="Confirm Email Address"
                  value={addUserData.confirmEmail}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
                {errors.confirmEmail && (
                  <p className="text-red-500 text-sm">{errors.confirmEmail}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-700 font-medium">
                  Phone Number
                  <span className="text-red-500 text-[20px]">* </span>
                </label>
                <div className="flex">
                  <select
                    name="phoneCode"
                    value={addUserData.phoneCode}
                    onChange={handleChange}
                    className="p-2 border w-20 border-gray-300 rounded-l mt-1"
                    required
                  >
                    {code.map((code) => (
                      <option value={code}>+ {code}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={addUserData.phone}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-r mt-1"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-[20px] font-semibold pb-8 pt-4 text-center">
                Company Detalis
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                  <label className="block text-gray-700 font-medium">
                    Company Name
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    value={addUserData.companyName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 font-medium">
                    Website
                  </label>
                  <input
                    type="text"
                    name="website"
                    placeholder="https://www.fsdfsdf"
                    value={addUserData.website}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                  <label className="block text-gray-700 font-medium">
                    Contact Person
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="contact_person"
                    placeholder="Company Person"
                    value={addUserData.contact_person}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 font-medium">
                    Contact Email
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="contact_email"
                    placeholder="Company Email"
                    value={addUserData.contact_email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                  <label className="block text-gray-700 font-medium">
                    Street Address
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="streetAddress"
                    placeholder="Company Street Address"
                    value={addUserData.streetAddress}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 font-medium">
                    Country<span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <select
                    name="country"
                    value={addUserData.country}
                    onChange={handleChange}
                    className="w-full p-2 flax flex-c border border-gray-300 rounded mt-1"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                  <label className="block text-gray-700 font-medium">
                    State<span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <select
                    name="state"
                    value={addUserData.state}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  >
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 font-medium">
                    City<span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={addUserData.city}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                  <label className="block text-gray-700 font-medium">
                    Postal/Zip
                  </label>
                  <input
                    type="text"
                    name="zip"
                    placeholder="Postal/Zip"
                    value={addUserData.zip}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 font-medium">
                    Office Phone
                    <span className="text-red-500 text-[20px]">* </span>
                  </label>
                  <input
                    type="text"
                    name="office_phone"
                    value={addUserData.office_phone}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
              </div>
              <div className="flex gap-14">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    name="status"
                    value={addUserData.status || ""}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 border p-2 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  >
                    {statusOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Role
                  </label>
                  <select
                    name="role"
                    value={addUserData.role || ""}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 border p-2 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                  >
                    {roleOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="mb-4 mt-4">
                <h5 className="text-[20px] font-semibold pb-2 text-center">
                  Login Information
                </h5>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium">
                  Username<span className="text-red-500 text-[20px]">* </span>
                </label>
                <input
                  type="text"
                  name="username"
                  placeholder="Confirm Username"
                  value={addUserData.username}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div className="mb-4">
                Create Password:
                <span className="text-red-500 text-[20px]">* </span>
                <label className="block text-gray-700 font-medium">
                  Minimum 8 characters including 1 number
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={addUserData.password}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1 "
                    required
                  />
                  <div
                    className="absolute bottom-3 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>
              <label className="block text-gray-700 font-medium">
                Confirm Password
                <span className="text-red-500 text-[20px]">* </span>
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={addUserData.confirmPassword}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showPassword state
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
              )}

              {/* <div>
                  <input
                    type="checkbox"
                    name="request_mail"
                    className=" h-4 w-4 border rounded"
                    checked={addUserData.request_mail}
                    onChange={handleChange}
                  />
                  <label className=" text-gray-700 font-medium p-2">
                    Request Mail
                  </label>
                </div> */}
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="w-[40%] h-[50px] bg-blue-700 text-white p-2 rounded hover:bg-blue-400 transition duration-200"
          >
            Submit
          </button>
        </div>
      </form>

      {/* {isModalOpen && (
          <>
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 text-center">
              <div className="bg-white p-8 rounded shadow-lg max-w-sm w-full z-50">
                <h2 className="text-2xl font-bold mb-4">Success</h2>
                <p className="mb-4">
                  Registration Request Sent Successfully! Your request will be
                  approved shortly
                </p>
                <button
                  onClick={closeModal}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </>
        )} */}
    </div>
  );
}
