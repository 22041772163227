import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { MdDeleteForever } from "react-icons/md";
import { fetchCartList } from "../../../../redux/slice/cartSlice/cartSlice";
import { baseUrl } from "../../../../utils/url";
import Navbar from "../../Navbar/Navbar";
import Swal from "sweetalert2";
import { decrypt } from "../../../../utils/EncryptDecrypt";

export default function AddCart() {
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);

  const [gridApi1, setGridApi1] = useState(null);
  const [gridApi2, setGridApi2] = useState(null);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [totalPrice2, setTotalPrice2] = useState(0);
  const [selectedIds, setSelectedIds] = useState({});
  const [selectedTypeData, setSelectedTypeData] = useState(null);

  const onSelectionChanged = (gridApi) => {
    const selectedRows = gridApi?.getSelectedRows();
    const selectedType = selectedRows?.map((row) => row?.Stock ? "diamond" : "jewelry")
    setSelectedTypeData(selectedType?.[0])
    const selectedIds = selectedRows?.map((row) => row?.id);
    setSelectedIds(selectedIds);
  };

  const onGridReady = (params, setGridApi) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    dispatch(fetchCartList());
  }, [dispatch]);

  useEffect(() => {
    if (gridApi1) {
      updateTotalPrice(gridApi1, setTotalPrice1);
    }
    if (gridApi2) {
      updateTotalPrice(gridApi2, setTotalPrice2);
    }
  }, [cartList, gridApi1, gridApi2]);

  const updateTotalPrice = (gridApi, setTotalPrice) => {
    let total = 0;
    gridApi?.forEachNodeAfterFilter((node) => {
      total += parseFloat(node.data.TotalPrice) || 0;
    });
    setTotalPrice(total.toFixed(2));
  };

  const handleDelete = async (Id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/users/delete-cart/${Id}`, {
        method: "DELETE",
        headers: myHeaders,
      });

      if (response.ok) {
        await Swal.fire({
          title: "Delete Successfully!",
          text: "",
          icon: "success",
        });

        dispatch(fetchCartList());
      } else {
        throw new Error("Failed to delete item from cart");
      }
    } catch (error) {
      console.error("Error deleting item from cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      },
      { headerName: "Stock #", field: "Stock", width: 100, filter: true },
      {
        headerName: "Availability",
        field: "Availability",
        width: 120,
        filter: true,
      },
      { headerName: "Lab", field: "Lab", width: 100, filter: true },
      { headerName: "Shape", field: "Shape", width: 100, filter: true },
      { headerName: "Weight", field: "Weight", width: 100, filter: true },
      { headerName: "Color", field: "Color", width: 80, filter: true },
      { headerName: "Clarity", field: "Clarity", width: 80, filter: true },
      { headerName: "Cut", field: "Cut", width: 80, filter: true },
      { headerName: "Polish", field: "Polish", width: 100, filter: true },
      { headerName: "Symmetry", field: "Symmetry", width: 100, filter: true },
      { headerName: "Discount", field: "Discount", width: 100, filter: true },
      { headerName: "Price", field: "Price", width: 100, filter: true },
      {
        headerName: "Total Price",
        field: "TotalPrice",
        width: 100,
        filter: true,
        valueParser: (params) => Number(params.newValue),
      },
      { headerName: "Depth", field: "Depth", width: 80, filter: true },
      { headerName: "Table", field: "Table", width: 70, filter: true },
      {
        headerName: "Measurements",
        field: "Measurements",
        width: 120,
        filter: true,
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 90,
        cellRenderer: "deleteButtonRenderer",
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      cartList
        .filter((item) => item.type === "diamond")
        .map((item) => ({
          id: item.product_details.id,
          Stock: item.product_details["Stock #"] || "",
          Availability: item.product_details["Availability"] || "",
          Lab: item.product_details["Lab"] || "",
          Shape: item.product_details["Shape"] || "",
          Weight: item.product_details["Weight"] || "",
          Color: item.product_details["Color"] || "",
          Clarity: item.product_details["Clarity"] || "",
          Cut: item.product_details["Cut Grade"] || "",
          Polish: item.product_details["Polish"] || "",
          Symmetry: item.product_details["Symmetry"] || "",
          Discount: item.product_details["Discount Percent"] || "",
          Price: item.product_details["Price"] || "",
          TotalPrice: parseFloat(
            item.product_details["Total Price"] || 0
          ).toFixed(2),
          Depth: item.product_details["Depth Percent"] || "",
          Table: item.product_details["Table Percent"] || "",
          Measurements: item.product_details["Measurements"] || "",
        })),
    [cartList]
  );

  const columns2 = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      },
      { headerName: "Sku #", field: "Sku", width: 100, filter: true },
      { headerName: "Avability", field: "Avability", width: 80, filter: true },
      { headerName: "Lot Name", field: "LotName", width: 90, filter: true },
      {
        headerName: "Jewelry Type",
        field: "JewelryType",
        width: 140,
        filter: true,
      },
      {
        headerName: "Jewelry Subtype",
        field: "JewelrySubtype",
        width: 120,
        filter: true,
      },
      {
        headerName: "Metal Type",
        field: "MetalType",
        width: 150,
        filter: true,
      },
      {
        headerName: "Setting Type",
        field: "SettingType",
        width: 120,
        filter: true,
      },
      {
        headerName: "Orientation",
        field: "Orientation",
        width: 140,
        filter: true,
      },
      {
        headerName: "Diamond Shape",
        field: "DiamondShape",
        width: 150,
        filter: true,
      },
      {
        headerName: "Total Diamond Weight",
        field: "TotalDiamondWeight",
        width: 180,
        filter: true,
      },
      {
        headerName: "Total Diamond Pcs",
        field: "TotalDiamondPcs",
        width: 150,
        filter: true,
      },
      {
        headerName: "Total Price",
        field: "TotalPrice",
        width: 150,
        filter: true,
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 100,
        cellRenderer: "deleteButtonRenderer",
      },
    ],
    []
  );

  const rows2 = useMemo(
    () =>
      cartList
        .filter((item) => item.type === "jewelry")
        .map((item) => ({
          id: item.product_details.id,
          Sku: item.product_details["Sku #"] || "",
          Avability: item.product_details["Avability"] || "",
          LotName: item.product_details["Lot Name"] || "",
          JewelryType: item.product_details["Jewelry Type"] || "",
          JewelrySubtype: item.product_details["Jewelry Subtype"] || "",
          MetalType: item.product_details["Metal Type"] || "",
          SettingType: item.product_details["Setting Type"] || "",
          Orientation: item.product_details["Orientation"] || "",
          DiamondShape: item.product_details["Diamond Shape"] || "",
          TotalDiamondWeight:
            item.product_details["Total Diamond Weight"] || "",
          TotalDiamondPcs: item.product_details["Total Diamond Pcs"] || "",
          TotalPrice: parseFloat(
            (item.product_details["Total Price"] || "0").replace(/,/g, "")
          ).toFixed(2),
        })),
    [cartList]
  );

  const deleteButtonRenderer = (params) => {
    const productId = params.data.id;

    return (
      <button onClick={() => handleDelete(productId)}>
        {" "}
        <MdDeleteForever className="text-xl" />
      </button>
    );
  };

  const TotalPriceFooter = ({ totalPrice }) => (
    <div className="total-price">
      <h2 className="font-bold">Total Price: ${totalPrice}</h2>
    </div>
  );

  const encryptedPermission = sessionStorage.getItem("permissions");
  const decryptedPermission = decrypt(encryptedPermission);
  const JewelryPermissions = JSON.parse(decryptedPermission);


  return (
    <section className="ag-theme-alpine" style={{ width: "100%" }}>
      <div className="flex px-2 justify-between items-center">
        <span className="text-[30px] font-semibold">Cart</span>
        <Navbar selectedItems={selectedIds} setSelectedIds={setSelectedIds} type={selectedTypeData} />
        {/* <h1>Cart</h1> */}
      </div>
      <div className="cart-table-container">
        <h2 className="text-2xl font-semibold text-center pb-5">
          Diamond Cart Table
        </h2>
        <div className="ag-theme-alpine" style={{ height: 375, width: "100%" }}>
          <AgGridReact
            rowData={rows}
            columnDefs={columns.map((col) => ({
              ...col,
              cellRenderer:
                col.field === "actions" ? deleteButtonRenderer : undefined,
            }))}
            pagination={true}
            paginationPageSize={50}
            suppressRowClickSelection={true}
            rowSelection="multiple"
            onGridReady={(params) => onGridReady(params, setGridApi1)}
            frameworkComponents={{
              deleteButtonRenderer: deleteButtonRenderer,
              TotalPriceFooter: TotalPriceFooter,
            }}
            gridOptions={{
              frameworkComponents: {
                TotalPriceFooter: TotalPriceFooter,
              },
            }}
            onSelectionChanged={() => onSelectionChanged(gridApi1)}
          />
        </div>
        <TotalPriceFooter totalPrice={totalPrice1} />
      </div>
      {JewelryPermissions.jewelry === 1 && (
        <div className="cart-table-container" style={{ height: "350px" }}>
          <h2 className="text-2xl font-semibold text-center pb-5">
            Jewelry Cart Table
          </h2>
          <div className="ag-theme-alpine" style={{ height: 375 }}>
            <AgGridReact
              rowData={rows2}
              columnDefs={columns2.map((col) => ({
                ...col,
                cellRenderer:
                  col.field === "actions" ? deleteButtonRenderer : undefined,
              }))}
              pagination={true}
              paginationPageSize={50}
              suppressRowClickSelection={true}
              rowSelection="multiple"
              onGridReady={(params) => onGridReady(params, setGridApi2)}
              frameworkComponents={{
                deleteButtonRenderer: deleteButtonRenderer,
                TotalPriceFooter: TotalPriceFooter,
              }}
              gridOptions={{
                frameworkComponents: {
                  TotalPriceFooter: TotalPriceFooter,
                },
              }}
              onSelectionChanged={() => onSelectionChanged(gridApi2)}
            />
          </div>
          <TotalPriceFooter totalPrice={totalPrice2} />
        </div>
      )}
    </section>
  );
}
