import React, { useState, useMemo, useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../../Loader/Loader";

export default function Table({
  searchDiamondData,
  loading,
  AvailabilityDot,
  searchId,
  checkedItems,
  onCheckboxChange,
}) {
  const navigate = useNavigate();
  // Filter rows to exclude those with NaN Price or Total Price
  const filteredData = searchDiamondData.filter((item) => {
    const price = parseFloat(item["Price"]);
    const totalPrice = parseFloat(item["Total Price"]);
    return !isNaN(price) && !isNaN(totalPrice);
  });

  // Conditionally include columns based on filtered data
  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <div className="flex items-center justify-center h-full">
          <input
            type="checkbox"
            id={`checkbox-${params.row.id}`}
            className="hidden"
            checked={!!checkedItems[params.row.id]}
            onChange={() => onCheckboxChange(params.row)}
          />
          <label
            htmlFor={`checkbox-${params.row.id}`}
            className={`cursor-pointer flex items-center justify-center w-[17px] h-[17px] border border-black rounded transition-colors duration-300 ${
              checkedItems[params.row.id]
                ? "bg-blue-500 border-blue-500"
                : "bg-white"
            }`}
          >
            <svg
              className={`w-5 h-5 text-white transition-transform duration-300 ${
                checkedItems[params.row.id]
                  ? "transform scale-100"
                  : "transform scale-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </label>
        </div>
      ),
    },
    {
      field: "id",
      headerName: "Id",
      width: 75,
    },
    {
      field: "Stock",
      headerName: "Stock",
      width: 100,
      renderCell: (params) => (
        <div
          className="cursor-pointer text-blue-500 underline"
          onClick={() => {
            navigate(
              `/inventorypage/diamondcardpage/${searchId}/${params.row.id}`
            );
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Availability",
      headerName: "Availability",
      width: 50,
      renderCell: (params) => {
        const availability = params.value || ""; // Default to an empty string if undefined
        return <AvailabilityDot availability={availability} />;
      },
    },
    { field: "Lab", headerName: "Lab", width: 80 },
    { field: "Shape", headerName: "Shape", width: 80 },
    { field: "Weight", headerName: "Weight", width: 80 },
    { field: "Color", headerName: "Color", width: 80 },
    { field: "Clarity", headerName: "Clarity", width: 80 },
    { field: "ratio", headerName: "Ratio", width: 100 },
    { field: "Cut", headerName: "Cut", width: 80 },
    { field: "Polish", headerName: "Polish", width: 100 },
    { field: "Symmetry", headerName: "Symmetry", width: 100 },
    { field: "Discount", headerName: "Discount", width: 100 },
    filteredData.some((item) => !isNaN(parseFloat(item["Price"]))) && {
      field: "Price",
      headerName: "Price",
      width: 90,
      renderCell: (params) => parseFloat(params.value).toFixed(2),
    },
    filteredData.some((item) => !isNaN(parseFloat(item["Total Price"]))) && {
      field: "TotalPrice",
      headerName: "Total Price",
      width: 120,
      renderCell: (params) => parseFloat(params.value).toFixed(2),
    },
    { field: "Depth", headerName: "Depth", width: 80 },
    { field: "Table", headerName: "Table", width: 80 },
    { field: "Department Account Name", headerName: "Location", width: 150 },
    { field: "Measurements", headerName: "Measurements", width: 150 },
  ].filter(Boolean); // Remove any falsey values

  const rows = searchDiamondData.map((item) => ({
    id: item.id,
    Stock: item["Stock #"] || "",
    Availability: item["Availability"] || "",
    Lab: item["Lab"] || "",
    Shape: item.Shape || "",
    Weight: item["Weight"] || "",
    image: item["Image Link"] || "",
    Color: item["Color"] || "",
    Clarity: item["Clarity"] || "",
    ratio: item["ratio"] || "",
    Cut: item["Cut Grade"] || "",
    Polish: item["Polish"] || "",
    Symmetry: item["Symmetry"] || "",
    Discount: item["Discount Percent"] || "",
    Price: !isNaN(parseFloat(item["Price"])) ? item["Price"] : "",
    TotalPrice: !isNaN(parseFloat(item["Total Price"]))
      ? item["Total Price"]
      : "",
    Depth: item["Depth Percent"] || "",
    Table: item["Table Percent"] || "",
    "Department Account Name": item["Department Account Name"] === "NY Office"
    ? "New York"
    : item["Department Account Name"] === "LA Office"
    ? "Los Angeles"
    : item["Department Account Name"] || "",
    Measurements: item["Measurements"] || "",
  }));

  return (
    <div className="pt-5" style={{ height: "100%", width: "100%" }}>
      {columns && (
        <>
          <DataGrid
            rows={rows}
            columns={columns}
            paginationMode="client" 
            pageSize={rows.length || 10} 
            rowsPerPageOptions={[rows.length || 10]} 
            autoHeight
          />
          {loading && (
            <div className="text-lg font-bold items-center justify-center flex text-center mt-4">
              <Loader />
            </div>
          )}
        </>
      )}
    </div>
  );
}
