import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../utils/url';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2';

export default function ForgetPassword() {
    const [createNewPassword, setCreateNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [token, setToken] = useState('');

    const location = useLocation();

    useEffect(() => {
        // Extract the token from the query parameters
        const queryParams = new URLSearchParams(location.search);
        const tokenFromQuery = queryParams.get('token');
        if (tokenFromQuery) {
            setToken(tokenFromQuery);
        }
    }, [location.search]);

    const handleResetPassword = async (e) => {
        e.preventDefault();
    
        // Validate password and confirm password before proceeding
        const passwordValidation = validatePassword(createNewPassword);
        if (!passwordValidation.isValid) {
            setErrors(() => ({
                password: passwordValidation.message,
            }));
            return;
        }
    
        if (createNewPassword !== confirmNewPassword) {
            setErrors(() => ({
                confirmPassword: "Password and Confirm Password do not match",
            }));
            return;
        }
    
        setErrors({}); // Clear previous errors
        setLoading(true); // Start loading
    
        try {
    
            const forgetPassword = new FormData();
            forgetPassword.append('password', createNewPassword);
            forgetPassword.append('confirmPassword', confirmNewPassword);
            forgetPassword.append('token', token);
    
            const response = await fetch(`${baseUrl}/users/reset-password`, {
                method: 'POST',
                body: forgetPassword,
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // Log success
                Swal.fire({
                    icon: 'success',
                    title: 'Reset Password Successful!',
                    text: 'Your password has been reset successfully.',
                    showConfirmButton: false,
                    timer: 2000,
                });
                setCreateNewPassword('');
                setConfirmNewPassword('');
            } else if (data.message === "Token has already been used or is invalid") {
                // Log error for invalid or used token
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid or Expired Link',
                    text: 'The reset link has already been used or is invalid. Please request a new link.',
                    confirmButtonText: 'OK',
                });
            } else {
                // Log generic error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message || 'An unexpected error occurred.',
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            // Log unexpected errors
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `An unexpected error occurred: ${error.message}`,
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);

        if (password.length < minLength) {
            return {
                isValid: false,
                message: "Password must be at least 8 characters long",
            };
        }
        if (!hasUpperCase) {
            return {
                isValid: false,
                message: "Password must contain at least one uppercase letter",
            };
        }
        if (!hasLowerCase) {
            return {
                isValid: false,
                message: "Password must contain at least one lowercase letter",
            };
        }
        if (!hasNumber) {
            return {
                isValid: false,
                message: "Password must contain at least one numeric digit",
            };
        }
        return { isValid: true, message: "" };
    };

    return (
        <div className="flex min-h-screen">
            <div className="flex-1">
                <img
                    src="/logo/loginSideImg.jpg"
                    alt="loginSideImg"
                    className="w-full h-full"
                />
            </div>
            <div className="flex-1 flex flex-col items-center justify-center p-8">
                <div className="text-center w-full max-w-md">
                    <div className="flex justify-center">
                        <img
                            src="/logo/Logo Black 001.png"
                            alt="Skylab Logo"
                            width={150}
                            height={150}
                        />
                    </div>

                    <h1 className="pt-2 pb-2">Create Your New Password</h1>

                    <form className="space-y-4" onSubmit={handleResetPassword}>
                        <div className='relative'>
                            <div className='relative'>
                                <input
                                    className="w-full p-2 border border-gray-300 rounded"
                                    placeholder="Create new password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={(e) => setCreateNewPassword(e.target.value)}
                                    value={createNewPassword}
                                    required
                                />
                                <div
                                    className="absolute bottom-3 right-0 flex items-center pr-3 cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm">{errors.password}</p>
                            )}
                        </div>
                        <div className=" w-full">
                            <div className='relative'>
                                <input
                                    className="w-full p-2 border border-gray-300 rounded"
                                    placeholder="Confirm new password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    value={confirmNewPassword}
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showPassword state
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                            {errors.confirmPassword && (
                                <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="w-full p-2 bg-blue-600 text-white rounded"
                            disabled={loading}
                        >
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}