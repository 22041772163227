import React, { useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { fetchAdminAllOrders } from "../../../../redux/slice/adminallorders/AdminAllOrders";
import { baseUrl } from "../../../../utils/url";
import { useState } from "react";

export default function AdminAllOrders() {
  const [data, setData] = useState([]);

  useEffect(() => {
    handleadminalInvoice();
  }, []);

  const handleadminalInvoice = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/get-all-diamond_jewel`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Column definitions for diamonds
  const diamondColumnDefs = useMemo(
    () => [
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Request_ID",
        field: "id",
        width: 120,
        cellRenderer: (params) => {
          const isBulk = params.data.request_type === "bulk";
          const displayValue = isBulk ? `B-${params.value}` : params.value;
          return (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {displayValue}
            </span>
          );
        },
      },
      { headerName: "Req Bulk Id", field: "request_bulk_id", width: 180, filter: true },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Availability", field: "Availability" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },

    ],
    []
  );

  // Column definitions for jewelry
  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      { headerName: "Req_Id", field: "request_id", width: 100, filter: true },
      { headerName: "Req Bulk Id", field: "request_bulk_id", width: 180, filter: true },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }else if (params.value === "withdraw"){
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire"){
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Availability", field: "Avability" },
      { headerName: "Sku #", field: "Sku #" },
      { headerName: "Lot Name", field: "Lot Name" },
      { headerName: "Title", field: "Title" },
      { headerName: "Stone Details", field: "Stone Details" },
      { headerName: "Stone Details_1", field: "Stone Details_1" },
      { headerName: "Jewelry Type", field: "Jewelry Type" },
      { headerName: "Jewelry Subtype", field: "Jewelry Subtype" },
      { headerName: "Metal Type", field: "Metal Type" },
      { headerName: "Setting Type", field: "Setting Type" },
      { headerName: "Orientation", field: "Orientation" },
      { headerName: "Jewelry Size", field: "Jewelry Size" },
      { headerName: "Avg Center Weight", field: "Avg Center Weight" },
      { headerName: "Total Diamond Weight", field: "Total Diamond Weight" },
      { headerName: "Total Diamond Pcs", field: "Total Diamond Pcs" },
      { headerName: "Color", field: "Color" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Lab Name", field: "Lab Name" },
      { headerName: "Report", field: "Report" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Image File", field: "Image File" },
      { headerName: "Video File", field: "Video File" },
      { headerName: "Image File_1", field: "Image File_1" },
      { headerName: "Image File_2", field: "Image File_2" },
      { headerName: "Image File_3", field: "Image File_3" },
    ],
    []
  );

  

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  // Separate data based on type
  const diamondData = useMemo(() =>
    data?.filter((item) => item.type === "diamond").map((item) => item)
  );

  const jewelryData = useMemo(() =>
    data?.filter((item) => item.type === "jewelry").map((item) => item)
  );

  return (
    <section style={{ width: "100%" }}>
      <div className="text-center font-bold text-[25px] mb-5">
        <span>Diamond Orders</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "370px", marginBottom: "20px" }}>
        <AgGridReact
          rowData={diamondData}
          columnDefs={diamondColumnDefs}
          defaultColDef={defaultColDef}
          
        />
      </div>
      <div className="text-center font-bold text-[25px] mt-5 mb-5">
        <span>Jewelry Orders</span>
      </div>
      <div className="ag-theme-alpine" style={{ height: "370px" }}>
        <AgGridReact
          rowData={jewelryData}
          columnDefs={jewelryColumnDefs}
          defaultColDef={defaultColDef}
          
        />
      </div>
    </section>
  );
}
