import React from 'react'
import { Navigate } from 'react-router-dom';

export default function PrivetRoute({ Component, allowedRole }) {
    const isAutorized = !!sessionStorage.getItem("token");
    const UrI = Number(sessionStorage.getItem("UrI"));
    
    if (!isAutorized) {
        return <Navigate to="/" />;             
    }
    if (UrI && allowedRole && !allowedRole.includes(UrI)) {
        if (UrI === 1) {
            return <Navigate to="/dashboardpage" />;
        }
        if (UrI === 2) {
            return <Navigate to="/admindashboardpage" />;
        }
        if (UrI === 3) {
            return <Navigate to="/admindashboardpage" />;
        }
    }
    return <Component />;
}