import React, { useEffect, useState } from "react";
import { baseUrl } from "../utils/url";
import { useParams } from "react-router-dom";
import { FaCirclePlay, FaRegCirclePlay } from "react-icons/fa6";
import { PiFilePdfBold } from "react-icons/pi";
import Loader from "../components/Loader/Loader";
import { Helmet } from "react-helmet";

export default function EmailShare() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState(null);

  const { type, id } = useParams();

  useEffect(() => {
    document.title =
      type === "diamond"
        ? "Lab-Grown Diamonds Product Details Share"
        : "Jewelry Product Details Share";

    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.style.display = "none"; // Hide the logo
    }

    const fetchSearchData = async () => {
      try {
        const token = localStorage.getItem("e_token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        const response = await fetch(
          `${baseUrl}/users/get/receipt-email/${type}/${id}`,
          {
            method: "GET",
            redirect: "follow",
            headers: myHeaders,
          }
        );
        const result = await response.json();
        if (result.status === "Success" && result.data.length > 0) {
          const item = result.data[0];
          // Combine the links into an array for the slider

          const imageLink =
            type === "diamond"
              ? [
                  item["Image Link"],
                  item["Video Link"],
                  item["Certificate Url"],
                ]
              : [
                  item["Image File"],
                  item["Image File_1"],
                  item["Image File_2"],
                  item["Video File"],
                  // item["Certificate Url"],
                ];
          setData({ ...item, imageLink });
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchSearchData();
    return () => {
      if (favicon) {
        favicon.style.display = "block"; // Restore the logo visibility
      }
    };
  }, [type, id]);

  if (!data) {
    return <Loader />;
  }

  const {
    "Stock #":Stock ,
    Price,
    Polish,
    Symmetry,
    "Depth Percent": depthpercent,
    "Table Percent": tablepercent,
    Lab,
    imageLink,
    "Total Price": totalPrice,
    Shape: diamondshape,
    "Discount Percent": discount,
    Weight,
    Clarity,
    Color,
    "Certificate Url": certificateUrl,
    "Title": Title,
    "Sku #": Sku,
    "Lot Name": lotName,
    "Jewelry Subtype": jewelrySubtype,
    "Setting Type": settingType,
    "Jewelry Size": jewelrySize,
    "Jewelry Type": JewelryType,
    "Total Diamond Pcs": totalDiamondPcs,
    "Orientation": orientation,
    "Stone Details": description,
    "Avg Center Weight": caratWeight,
    "Total Diamond Weight": ctw,
    "Metal Type": metaltype,
    "Diamond Shape": Shape,
  } = data;

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % imageLink.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + imageLink.length) % imageLink.length
    );
  };

  const renderSlideContent = () => {
    const currentLink = imageLink[activeIndex];
    if (currentLink.endsWith(".html")) {
      return (
        <iframe
          src={currentLink}
          title={`Slide ${activeIndex + 1}`}
          className="w-full h-80"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else if (currentLink.endsWith(".pdf")) {
      return (
        <iframe
          src={`https://docs.google.com/viewer?url=${currentLink}`}
          title={`Slide ${activeIndex + 1}`}
          className="w-full h-80"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else if (currentLink.endsWith(".mp4") || currentLink.endsWith(".mov")) {
      return (
        <video
          src={currentLink}
          title={`Slide ${activeIndex + 1}`}
          className="w-full h-80"
          controls
        />
      );
    } else {
      return (
        <img
          src={currentLink}
          alt={`Slide ${activeIndex + 1}`}
          className="w-full h-80 object-cover rounded-md"
        />
      );
    }
  };

  const getThumbnailSrc = (link) => {
    if (link?.endsWith(".pdf")) {
      return <PiFilePdfBold />;
    } else if (link?.endsWith(".html")) {
      return <FaRegCirclePlay />;
    } else {
      return link;
    }
  };

  const title =
    type === "diamond"
      ? "Lab-Grown Diamonds Product Details Share"
      : "Jewelry Product Details Share";

  const favicon = document.querySelector("link[rel='icon']");
  if (favicon) {
    favicon.href = `${process.env.PUBLIC_URL}/faviconemail.ico`; // Set the favicon to the email-specific icon
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/faviconemail.ico`} />
      </Helmet>
      <div className="bg-gray-200 p-4 min-h-screen flex justify-center items-center">
        <div className="bg-white max-w-xl w-full shadow-lg rounded-md overflow-hidden">
          <div className="bg-gray-400 text-white p-2 text-center">
            <h1 className="text-sm font-semibold tracking-wider">
              {type === "diamond"
                ? "Lab-Grown Diamonds Product Details Share"
                : "Jewelry Product Details Share"}
            </h1>
          </div>

          <div className="p-4 flex flex-col items-center">
            <div className="text-center mb-2">
              <h2 className="text-lg font-bold">{Title}</h2>
            </div>

            <div className="relative w-full max-w-sm">
              {renderSlideContent()}

              <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                <button
                  onClick={prevSlide}
                  className="bg-gray-800 text-white p-1 rounded-full hover:bg-gray-700"
                >
                  ◀
                </button>
              </div>
              <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                <button
                  onClick={nextSlide}
                  className="bg-gray-800 text-white p-1 rounded-full hover:bg-gray-700"
                >
                  ▶
                </button>
              </div>
            </div>

            <div className="flex gap-2 justify-center my-4">
              {imageLink?.map((link, index) => (
                <div className="relative" key={link}>
                  {link.includes(".mp4") ? (
                    <>
                      <video
                        src={link}
                        onClick={() => setActiveIndex(index)}
                        className="w-12 h-12 object-cover border-2 rounded cursor-pointer"
                      />
                      <FaCirclePlay
                        fontSize={40}
                        color="#fff"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white"
                        onClick={() => setActiveIndex(index)}
                      />
                    </>
                  ) : link.endsWith(".html") ? (
                    <>
                      <div
                        className="w-12 h-12 bg-gray-300 border-2 rounded cursor-pointer flex justify-center items-center"
                        onClick={() => setActiveIndex(index)}
                      >
                        <FaRegCirclePlay fontSize={40} color="#555" />
                      </div>
                    </>
                  ) : link.endsWith(".pdf") ? (
                    <>
                      <div
                        className="w-12 h-12 bg-gray-300 border-2 rounded cursor-pointer flex justify-center items-center"
                        onClick={() => setActiveIndex(index)}
                      >
                        <PiFilePdfBold fontSize={40} color="#555" />
                      </div>
                    </>
                  ) : (
                    <img
                      key={index}
                      src={getThumbnailSrc(link)}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => setActiveIndex(index)}
                      className={`w-12 h-12 border-2 rounded cursor-pointer ${
                        activeIndex === index
                          ? "border-blue-500"
                          : "border-gray-300"
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="bg-gray-100 p-4 rounded-md w-full text-sm text-gray-600">
              {type === "diamond" ? (
                <ul className="grid grid-cols-2 gap-2">
                  <li>
                    <span className="font-medium">Stock # : </span>{" "}
                    <span className="font-bold ">{Stock}</span>
                  </li>
                  {/* <li>
                  <span className="font-medium">Price : </span>{" "}
                  <span className="font-bold ">
                    ${Price}
                  </span>
                </li> */}
                  <li>
                    <span className="font-medium">Weight : </span>{" "}
                    <span className="font-bold ">{Weight}</span>
                  </li>
                  {/* <li>
                  <span className="font-medium">totalPrice : </span>{" "}
                  <span className="font-bold ">
                    ${totalPrice}
                  </span>
                </li> */}

                  <li>
                    <span className="font-medium">Shape : </span>{" "}
                    <span className="font-bold ">{diamondshape}</span>
                  </li>
                  {/* <li>
                  <span className="font-medium">discount : </span>{" "}
                  <span className="font-bold ">
                    {discount}
                  </span>
                </li> */}

                  <li>
                    <span className="font-medium">Clarity : </span>{" "}
                    <span className="font-bold ">{Clarity}</span>
                  </li>
                  <li>
                    <span className="font-medium">Color : </span>{" "}
                    <span className="font-bold ">{Color}</span>
                  </li>
                  <li>
                    <span className="font-medium">Lab : </span>{" "}
                    <span className="font-bold ">{Lab}</span>
                  </li>
                  <li>
                    <span className="font-medium">Symmetry : </span>{" "}
                    <span className="font-bold ">{Symmetry}</span>
                  </li>
                  <li>
                    <span className="font-medium">Polish : </span>{" "}
                    <span className="font-bold ">{Polish}</span>
                  </li>
                  <li>
                    <span className="font-medium">Depth Percent : </span>{" "}
                    <span className="font-bold ">{depthpercent}</span>
                  </li>
                  <li>
                    <span className="font-medium">Table Percent : </span>{" "}
                    <span className="font-bold ">{tablepercent}</span>
                  </li>
                  <li>
                    <span className="font-medium">certificateUrl : </span>{" "}
                    <span className="font-bold ">
                      <a
                        href={certificateUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Open Link
                      </a>
                    </span>
                  </li>
                </ul>
              )  :  (
                <ul className="grid grid-cols-2 gap-2">
                  <li>
                    <span className="font-medium">Sku # : </span>
                    <span className="font-bold">{Sku}</span>
                  </li>
                  <li>
                    <span className="font-medium">Lot Name : </span>
                    <span className="font-bold">{lotName}</span>
                  </li>
                  {/* <li>
                  <span className="font-medium">Total Price :  $</span>
                  <span className="font-bold">
                    {totalPrice}
                  </span>
                </li> */}
                  <li>
                    <span className="font-medium">Jewelry Style : </span>
                    <span className="font-bold">{JewelryType}</span>
                  </li>
                  <li>
                    <span className="font-medium">Jewelry Subtype : </span>
                    <span className="font-bold">{jewelrySubtype}</span>
                  </li>
                  <li>
                    <span className="font-medium">Metal Type : </span>
                    <span className="font-bold">{metaltype}</span>
                  </li>
                  <li>
                    <span className="font-medium">Setting Type : </span>
                    <span className="font-bold">{settingType}</span>
                  </li>
                  <li>
                    <span className="font-medium">Jewelry Size : </span>
                    <span className="font-bold">{jewelrySize}</span>
                  </li>
                  <li>
                    <span className="font-medium">Orientation : </span>
                    <span className="font-bold">{orientation}</span>
                  </li>
                  <li>
                    <span className="font-medium">Stone Shape : </span>
                    <span className="font-bold">{Shape}</span>
                  </li>
                  <li>
                    <span className="font-medium">Total Diamond Pcs : </span>
                    <span className="font-bold">{totalDiamondPcs}</span>
                  </li>
                 
                  
                  <li>
                    <span className="font-medium">Total Diamond Weight : </span>
                    <span className="font-bold">{ctw}</span>
                  </li>
                  <li>
                    <span className="font-medium">Color/Clarity : </span>
                    <span className="font-bold">{Color} {Clarity}</span>
                  </li>
                  {/* <li>
                    <span className="font-medium"> : </span>
                    <span className="font-bold"></span>
                  </li> */}
                  <li>
                    <span className="font-medium">Description :  </span>
                    <span className="font-bold">{description}</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
