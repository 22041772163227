import React from "react";
import { DefaultLayout } from "../../../components/layout/defaultLayout";
import DashboardOrders from "../../../components/Dashboard/DashboardOrders.js/DashboardOrders";

export default function OrderPage() {
  return (
    <DefaultLayout>
      <DashboardOrders />
    </DefaultLayout>
  );
}
