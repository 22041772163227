import React, { useEffect, useState } from "react";
import { baseUrl } from "../../utils/url";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2';

export default function UserProfile() {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_address: "",
    country: "",
    state: "",
    city: "",
    email: "",
    phone: "",
    request_mail: false,
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const fetchUserData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const response = await fetch(`${baseUrl}/users/get-user`, {
        headers: myHeaders,
        redirect: "follow",
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setUser({
            ...data[0],
            request_mail: data[0].request_mail === 1, // Assuming 1 means true and 0 means false
          });
        }
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
  
    setUser((prevUser) => ({
      ...prevUser,
      [name]: type === "checkbox" ? checked : value, // Set checkbox value as true/false
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/users/update-user`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(user),
        redirect: "follow",
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setUser(data[0]);
        }
        Swal.fire({
          icon: 'success',
          title: 'successfully updated!',
          text: 'your profile has been updated successfully',
          showConfirmButton: false,
          timer: 2000,
        });
        fetchUserData();
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/users/change-password`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(passwords),
        redirect: "follow",
      });

      const responseData = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Paswords updated successfully',
          text: '',
          showConfirmButton: false,
          timer: 2000,
        });
        setPasswords({ oldPassword: "", newPassword: "" });
        togglePopup();
      } else {
        Swal.fire({
          icon: 'error',
          title: responseData.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("Error resetting password. Please try again.");
    }
  };

  return (
    <section className="flex w-full items-center h-full">
      <div className="w-1/2 border border-blue-400 mx-auto bg-white shadow-2xl rounded-lg p-6">
        <h2 className="text-2xl text-center font-semibold mb-4">
          User Profile
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex gap-5">
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="first_name"
              >
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={user.first_name}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="last_name"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={user.last_name}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
          </div>
          <div className="mb-4 flex gap-5">
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="company_name"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={user.company_name}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="country"
              >
                Country
              </label>
              <input
                type="text"
                id="country"
                name="country"
                value={user.country}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
          </div>
          <div className="mb-4 flex gap-5">
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="state"
              >
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={user.state}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="city"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={user.city}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="company_address"
            >
              Company Address
            </label>
            <input
              type="text"
              id="company_address"
              name="company_address"
              value={user.company_address}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          <div className="mb-4 flex gap-5">
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={user.email}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full md:w-1/2">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={user.phone}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div>
              <input
                type="checkbox"
                name="request_mail"
                className=" h-4 w-4 border rounded"
                checked={user.request_mail}
                onChange={handleChange}
              />
              <label className=" text-gray-700 font-medium p-2">
                Request Mail
              </label>
            </div>
          </div>
          <div className="">
            <button
              type="submit"
              className="w-full py-2 mb-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
        <button
          className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
          onClick={togglePopup}
        >
          Change Password
        </button>
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md w-[30%] shadow-md">
              <h2 className="text-xl font-semibold mb-4">Change Password</h2>
              <form onSubmit={handlePasswordSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700">Old Password</label>
                  <div className="flex relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="oldPassword"
                      value={passwords.oldPassword}
                      onChange={handlePasswordChange}
                      className="w-full px-3 py-2 border rounded-md"
                      placeholder="Enter Old Password"
                      required
                    />
                    <div
                      className="flex inset-y-0 items-center absolute right-0 pr-3 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">New Password</label>
                  <div className="flex relative">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      value={passwords.newPassword}
                      onChange={handlePasswordChange}
                      className="w-full px-3 py-2 border rounded-md"
                      placeholder="Enter New Password"
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={() => setShowNewPassword(!showNewPassword)}>

                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                    onClick={togglePopup}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
