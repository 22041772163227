const secretKey = process.env.REACT_APP_SECRET_KEY;

// Function to validate if a string is Base64 encoded
function isBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

// Encrypt function
export function encrypt(text) {
  if (!text || !secretKey) {
    console.error("Text or secretKey is missing.");
    return null;
  }

  let encrypted = "";
  for (let i = 0; i < text.length; i++) {
    encrypted += String.fromCharCode(
      text.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length)
    );
  }

  try {
    const encoded = btoa(encrypted);
    return encoded;
  } catch (error) {
    console.error("Failed to encrypt: ", error);
    return null;
  }
}

// Decrypt function
export function decrypt(encrypted) {
  if (!encrypted || !secretKey) {
    console.error("Encrypted string or secretKey is missing.");
    return null;
  }

  // Check if the encrypted string is valid Base64
  if (!isBase64(encrypted)) {
    console.error("Failed to decode the string. It may not be properly encoded.");
    return null;
  }

  try {
    encrypted = atob(encrypted); // Decode Base64
  } catch (error) {
    console.error("Failed to decode Base64: ", error);
    return null;
  }

  let decrypted = "";
  for (let i = 0; i < encrypted.length; i++) {
    decrypted += String.fromCharCode(
      encrypted.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length)
    );
  }
  return decrypted;
}
