
import React, { Suspense } from "react";
import DiamondCard from "../../../components/Inventory/Search/diamondssearch/diamondCard/DiamondCard";
import Loader from "../../../components/Loader/Loader";
import { DefaultLayout } from "../../../components/layout/defaultLayout";

export default function DiamondCardPage() {
  return (
    <DefaultLayout>
      <Suspense fallback={<Loader />}>
        <DiamondCard />
      </Suspense>
    </DefaultLayout>
  );
}
