import React, { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { baseUrl } from "../../utils/url";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function SuperAdminPermission() {
  const [rowDataAdmin, setRowDataAdmin] = useState([]);
  const [rowDataUser, setRowDataUser] = useState([]);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const response = await fetch(`${baseUrl}/admin/get-admin`, {
          method: "GET",
          headers: myHeaders,
        });
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setRowDataAdmin(data.data); // Assuming data is the array of row data
        setLoadingAdmin(false);
      } catch (error) {
        console.error("Error fetching admin data:", error);
        setLoadingAdmin(false);
      }
    };

    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const response = await fetch(`${baseUrl}/admin/get-user`, {
          method: "GET",
          headers: myHeaders,
        });
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setRowDataUser(data.data); // Assuming data is the array of row data
        setLoadingUser(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoadingUser(false);
      }
    };

    fetchAdminData();
    fetchUserData();
  }, []);

  const handleCellEdit = useCallback(async (params, isAdmin) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const updatedData = { [params.colDef.field]: params.newValue };

      const url = isAdmin
        ? `${baseUrl}/admin/update-admin-permission/${params.data.id}`
        : `${baseUrl}/admin/update-user-permission/${params.data.id}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  }, []);

  // Custom renderer for the checkbox
  const checkboxRenderer = (params, isAdmin) => {
    return (
      <input
        type="checkbox"
        checked={params.value === 1}
        onChange={(e) => {
          const newValue = e.target.checked ? 1 : 0;
          params.node.setDataValue(params.colDef.field, newValue);
          handleCellEdit({ ...params, newValue }, isAdmin);
        }}
      />
    );
  };

  const adminColumnDefs = [
    { field: "id", headerName: "ID", width: 100, filter: true },
    { field: "email", headerName: "Email", width: 250, filter: true },
    { field: "first_name", headerName: "First Name", width: 150, filter: true },
    { field: "company_name", headerName: "Company Name", width: 150, filter: true },
    {
      field: "dashboard",
      headerName: "dashboard",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, true),
    },
    {
      field: "request",
      headerName: "Requests",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, true),
    },
    {
      field: "inventory",
      headerName: "Inventory",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, true),
    },
    {
      field: "user",
      headerName: "User",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, true),
    },
    {
      field: "custom_jewelry",
      headerName: "Custom_jewelry",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, true),
    },

    {
      field: "created_at",
      headerName: "Created At",
      width: 210,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
  ];

  const userColumnDefs = [
    { field: "id", headerName: "ID", width: 100, filter: true },
    { field: "email", headerName: "Email", width: 250, filter: true },
    { field: "first_name", headerName: "First Name", width: 150, filter: true },
    { field: "company_name", headerName: "Company Name", width: 150, filter: true },

    // {
    //   field: "dashboard",
    //   headerName: "Dashboard",
    //   width: 100,
    //   cellRenderer: (params) => checkboxRenderer(params, false),
    // },
    {
      field: "loose_stone",
      headerName: "Loose_stone",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, false),
    },
    {
      field: "jewelry",
      headerName: "Jewelry",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, false),
    },
    // {
    //   field: "requests",
    //   headerName: "Requests",
    //   width: 100,
    //   cellRenderer: (params) => checkboxRenderer(params, false),
    // },
    {
      field: "custom_jewelry",
      headerName: "Custom_jewelry",
      width: 100,
      cellRenderer: (params) => checkboxRenderer(params, false),
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 210,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Check if the value exists
        const date = new Date(params.value);

        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

        return date.toLocaleString("en-US", {
          month: "2-digit", // "Jan"
          day: "2-digit", // "01"
          year: "numeric", // "2024"
          hour: "2-digit", // "14"
          minute: "2-digit", // "05"
          hour12: false, // For 24-hour format
        });
      },
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ width: "100%" }}>
      <h1 className="text-2xl font-bold text-center py-4">
        Super Admin Permissions
      </h1>

      {/* Admin Permissions Table */}
      <div style={{ height: 400, marginBottom: "20px" }}>
        <h2 className="text-xl font-semibold text-center py-2">
          Admin Permissions
        </h2>
        <AgGridReact
          rowData={rowDataAdmin}
          columnDefs={adminColumnDefs}
          pagination={true}
          paginationPageSize={50}
          onCellValueChanged={(params) => handleCellEdit(params, true)}
        />
      </div>

      {/* User Permissions Table */}
      <div style={{ height: 400 }}>
        <h2 className="text-xl font-semibold text-center py-2 mt-12">
          User Permissions
        </h2>
        <AgGridReact
          rowData={rowDataUser}
          columnDefs={userColumnDefs}
          pagination={true}
          paginationPageSize={50}
          onCellValueChanged={(params) => handleCellEdit(params, false)}
        />
      </div>
    </div>
  );
}
