import React from "react";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import AdminDashboard from "../../../adminComponents/admindashboard/admindashboard/AdminDashboard";

export default function AdminDashboardPage() {
  return (
    <AdminDefaultLayout>
      <AdminDashboard />
    </AdminDefaultLayout>
  );
}
