import React from "react";
import AdminMostSearch from "../../../adminComponents/admindashboard/adminmostsearch/AdminMostSearch";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminMostSearchPage() {
  return (
    <AdminDefaultLayout>
      <AdminMostSearch />
    </AdminDefaultLayout>
  );
}
