import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceData } from "../../../redux/slice/InvoiceSlice/invoicepageSlice";
import { AgGridReact } from "ag-grid-react";

export default function DashboardInvoice() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.invoicepage);

  useEffect(() => {
    dispatch(fetchInvoiceData());
  }, [dispatch]);

  const diamondColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;
  
            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1; 
            const year = parseInt(dateParts[2], 10);
  
            const cellDate = new Date(year, month, day);
  
            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true, 
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0]; 
          }
          return "";
        },
      },
      { headerName: "Stock #", field: "Stock #" },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "Accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "Decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Availability", field: "Availability" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },
    ],
    []
  );

  // Column definitions for jewelry
  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerName: "Created_at",
        field: "created_at",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue == null) return -1;
  
            const dateParts = cellValue.split(" ")[0].split("/");
            const day = parseInt(dateParts[1], 10);
            const month = parseInt(dateParts[0], 10) - 1; 
            const year = parseInt(dateParts[2], 10);
  
            const cellDate = new Date(year, month, day);
  
            // Compare the two dates
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          browserDatePicker: true, 
        },
        valueFormatter: (params) => {
          const dateValue = params.value;
          if (dateValue) {
            return dateValue.split(" ")[0]; 
          }
          return "";
        },
      },
      { headerName: "Sku #", field: "Sku #", width: 100 },
      {
        headerName: "Status",
        field: "status",
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "Accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "Decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Lot Name", field: "Lot Name", width: 120 },
      { headerName: "Jewelry Type", field: "Jewelry Type", width: 140 },
      { headerName: "Jewelry Subtype", field: "Jewelry Subtype", width: 120 },
      { headerName: "Metal Type", field: "Metal Type", width: 150 },
      { headerName: "Setting Type", field: "Setting Type", width: 140 },
      { headerName: "Orientation", field: "Orientation", width: 140 },
      { headerName: "Diamond Shape", field: "Diamond Shape", width: 150 },
      { headerName: "Total Diamond Weight", field: "Total Diamond Weight", width: 180 },
      { headerName: "Total Diamond Pcs", field: "Total Diamond Pcs", width: 180 },
      { headerName: "Total Price", field: "Total Price", width: 150 },
      { headerName: "Lab Name", field: "Lab Name", width: 150 },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  // Split row data into diamonds and jewelry
  const diamondRowData = useMemo(() => {
    if (data && data.length) {
      return data
        .filter(item => item.type === "diamond")
        .flatMap(item => item.item_details);
    }
    return [];
  }, [data]);

  const jewelryRowData = useMemo(() => {
    if (data && data.length) {
      return data
        .filter(item => item.type === "jewelry")
        .flatMap(item => item.item_details);
    }
    return [];
  }, [data]);

  if (loading) {
    return <div className="text-center text-xl">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-xl text-red-500">Error: {error}</div>;
  }

  return (
    <section style={{ width: "100%" }}>
      <div className="text-center font-bold text-2xl mb-5">
        Diamond Invoices Request
      </div>
      <div className="ag-theme-alpine" style={{ height: "300px", marginBottom: "20px" }}>
        <AgGridReact
          rowData={diamondRowData}
          columnDefs={diamondColumnDefs}
          defaultColDef={defaultColDef}
        />
      </div>
      <div className="text-center font-bold text-2xl mt-14 mb-5">
        Jewelry Invoices Request
      </div>
      <div className="ag-theme-alpine" style={{ height: "300px" }}>
        <AgGridReact
          rowData={jewelryRowData}
          columnDefs={jewelryColumnDefs}
          defaultColDef={defaultColDef}
        />
      </div>
    </section>
  );
}
