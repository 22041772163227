import React from "react";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import AdminAnalytics from "../../adminComponents/adminanalytics/AdminAnalytics";

export default function AdminAnalyticsPage() {
  return (
    <AdminDefaultLayout>
      <AdminAnalytics />
    </AdminDefaultLayout>
  );
}
