import React from "react";
import AdminRequest from "../../adminComponents/adminrequeste/AdminRequest";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminRequestPage() {
  return (
    <AdminDefaultLayout>
      <AdminRequest />
    </AdminDefaultLayout>
  );
}
