import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/url";
import Swal from "sweetalert2";

export default function AdminAddNewCompany() {
  const [formData, setFormData] = useState({
    name: "",
    jbtNumber: "",
    locationName: "",
    streetAddress: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    contactPerson: "",
    contactEmail: "",
    officePhone: "",
    mobilePhone: "",
    facetimePhone: "",
  });


  const [selectedImages, setSelectedImages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/admin/countries`)
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data.map((country) => country).sort();
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "country") {
      fetch(`${baseUrl}/admin/states?country=${value}`)
        .then((response) => response.json())
        .then((data) => {
          const sortedStates = data.map((state) => state).sort();
          setStates(sortedStates);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);




    // Create a FormData object to send data to the backend
    const data = new FormData();

    // Append text fields to FormData
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    // Append images to FormData
    selectedImages.forEach((image, index) => {
      data.append("images", image); // 'images' indicates an array of files
    });

    // Example request to send data to the backend
    fetch(`${baseUrl}/admin/company_create`, {
      method: "POST",
      body: data,
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          Swal.fire({
            icon: "success",
            title: "Form Submitted",
            text: "Company created successfully!",
          });

          setFormData({
            name: "",
            jbtNumber: "",
            locationName: "",
            streetAddress: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            contactPerson: "",
            contactEmail: "",
            officePhone: "",
            mobilePhone: "",
            facetimePhone: "",
          })
          setSelectedImages([])
        } else {
          Swal.fire({
            icon: "error",
            title: "Submission Failed",
            text: result.message || "There was an issue creating the company.",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error submitting the form.",
        });
        console.error("Error:", error);
      });
  };


  return (
    <div className="p-3 bg-gray-100 min-h-full">
      <div className="max-w-5xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-6">
          Create New Customer Company
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Company Information */}
            <div>
              <label className="block mb-2 text-sm font-medium">Name *</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Company Name"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Website</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="http://example.com"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">
                JBT Number
              </label>
              <input
                type="text"
                name="jbtNumber"
                value={formData.jbtNumber}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Enter JBT Number"
              />
            </div>
            {/* <div className="flex items-center">
              <label className="block mb-2 text-sm font-medium mr-4">
                Customer Company Purchasing Agent
              </label>
              <input
                type="checkbox"
                name="purchasingAgent"
                checked={formData.purchasingAgent}
                onChange={handleInputChange}
                className="toggle"
              />
            </div> */}

            {/* Location Details */}
            <div className="col-span-2">
              <h3 className="text-xl font-semibold mt-2 mb-4">
                Location Details
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Location Name
                  </label>
                  <input
                    type="text"
                    name="locationName"
                    value={formData.locationName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter Location Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Contact Person *
                  </label>
                  <input
                    type="text"
                    name="contactPerson"
                    value={formData.contactPerson}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter Contact Person"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Street Address
                  </label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="123 Street Name"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Contact Email *
                  </label>
                  <input
                    type="text"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="example@example.com"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Country
                  </label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">-Select Country-</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Office Phone
                  </label>
                  <input
                    type="text"
                    name="officePhone"
                    value={formData.officePhone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter Office Phone"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    State / Province / Region
                  </label>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">-Select State-</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Mobile Phone *
                  </label>
                  <input
                    type="text"
                    name="mobilePhone"
                    value={formData.mobilePhone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter Mobile Phone"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">City</label>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">
                    Facetime Phone
                  </label>
                  <input
                    type="text"
                    name="facetimePhone"
                    value={formData.facetimePhone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter Facetime Phone"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium">Zip</label>
                  <input
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    placeholder="Postal Code"
                  />
                </div>
              </div>
            </div>

            {/* Upload Images */}
            <div className="col-span-2">
              <h3 className="text-xl font-semibold mt-2 mb-4">
                Upload Images
              </h3>
              <input
                type="file"
                name="images"
                multiple
                onChange={handleImageChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow-md hover:bg-blue-600 transition-all"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}