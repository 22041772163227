export const JeweleryData = {
  type: ["Bracelet", "Necklace", "Earring", "Eternity band", "Ring", "Pendant"],
  subtype: [
    "Station",
    "Tennis",
    "Bangles",
    "Double Strand Station",
    "Station Line Tennis",
    "Lock",
    "Riviera",
    "Lariat",
    "Special",
    "Studs",
    "Hoops",
    "Omega Hoops",
    "Crawler",
    "Huggies",
    "Hanging",
    "Eternity Band",
    "Half Eternity",
    "3/4 Band",
    "5 Stones Band",
    "3 Stones",
    "7 Stones",
    "3 Stones Halo",
    "Hidden Halo",
    "Semi Hidden Halo",
    "Solitaire",
    "Solitaire Diamond Shank",
    "Split Shank",
  ],
  setting: [
    "Prong",
    "U Prong",
    "Bezel",
    "Channel",
    "Flush",
    "Half Bezel",
    "Micro Pave",
  ],
  orientation: ["North-South", "East-West", "Inclined", "Not Applicable"],
  metal: {
    "14KW": "bg-[#c0c0c0]",
    "14KY": "bg-[#FFCC00]",
    "18KW": "bg-[#a4a4a4]",
    "18KY": "bg-[#FAC000]",
    "14K RG": "bg-[#f7c5ad]",
    "18K RG": "bg-[#f1a886]",
    "14K TT": "bg-gradient-to-r from-gray-300 to-yellow-300",
    "18K TT": "bg-gradient-to-r from-gray-400 to-yellow-400",
    PT: "bg-gray-400",
  },
  diamondCts: [
    "0.0 - 0.50",
    "0.51 - 0.99",
    "1.00 - 1.49",
    "1.50 - 1.99",
    "2.00 - 2.99",
    "3.00 - 3.99",
    "4.00 - 4.99",
    "5.00 - 6.99",
    "7.00 - 9.99",
    "10+",
  ],
  stoneshape: [
    { icon: "/diamondSvg/Round.png", text: "Round" },
    { icon: "/diamondSvg/Oval.png", text: "Oval" },
    { icon: "/diamondSvg/Emerald.png", text: "Emerald" },
    { icon: "/diamondSvg/Radiant.png", text: "Radiant" },
    { icon: "/diamondSvg/Cushion.png", text: "Cushion" },
    { icon: "/diamondSvg/Antique Cushion.png", text: "Antique Cushion" },
    { icon: "/diamondSvg/Pear.png", text: "Pear" },
    { icon: "/diamondSvg/Heart.png", text: "Heart" },
    { icon: "/diamondSvg/Princess.png", text: "Princess" },
    { icon: "/diamondSvg/Ascher.png", text: "Asscher" },
    { icon: "/diamondSvg/Marquise.png", text: "Antique Marquise" },
    { icon: "/diamondSvg/Marquise.png", text: "Marquise" },
    { icon: "/diamondSvg/Stap Marquise.png", text: "Step Marquise" },
    { icon: "/diamondSvg/SQ. Emerald.png", text: "SQ. Emerald" },
    { icon: "/diamondSvg/SQ. Radiant.png", text: "SQ. Radiant" },
    {
      icon: "/diamondSvg/Squre Brilliant Cushion.png",
      text: "SQ. Cushion Brilliant",
    },
    { icon: "/diamondSvg/Modified Radiant.png", text: "Radiant Modified" },
    { icon: "/diamondSvg/Antique Asscher.png", text: "Antique Asscher" },
    { icon: "/diamondSvg/Antiqe Oval.png", text: "Antique Oval" },
    { icon: "/diamondSvg/European Cut.png", text: "Old European Brilliant" },
    { icon: "/diamondSvg/Hexagonal.png", text: "Hexagonal" },
    { icon: "/diamondSvg/Octagonal.png", text: "Octagon" },
    { icon: "/diamondSvg/Octagonal.png", text: "Octagon H&A" },
    { icon: "/diamondSvg/Octagon Nova.png", text: "Octagon Nova" },
    { icon: "/diamondSvg/Rose.png", text: "Rose Cut" },
    { icon: "/diamondSvg/Bugget.png", text: "Baguette" },
    { icon: "/diamondSvg/mix.png", text: "Mix" },
    { icon: "/diamondSvg/Trapped Budget.png", text: "Tapered Baguette" },
    { icon: "/diamondSvg/Tapered Bullet.png", text: "Tapered Bullet" },
    { icon: "/diamondSvg/Trapezoid.png", text: "Trapezoid" },
    { icon: "/diamondSvg/Bullet Cut.png", text: "Bullet Cut" },
    { icon: "/diamondSvg/Half Moon.png", text: "Half Moon" },
    // { icon: "/diamondSvg/Old Mine Cut.png", text: "Old Mine Cut" },
    { icon: "/diamondSvg/Pentagonal.png", text: "Pentagonal" },
    { icon: "/diamondSvg/Circular Brilliant.png", text: "Circular Brilliant" },
    { icon: "/diamondSvg/Lozenge.png", text: "Lozenge" },
    { icon: "/diamondSvg/Shield.png", text: "Shield" },
    { icon: "/diamondSvg/Star.png", text: "Star" },
    { icon: "/diamondSvg/Bull.png", text: "Bull" },
    { icon: "/diamondSvg/Horse.png", text: "Horse" },
    { icon: "/diamondSvg/Butterfly.png", text: "Butterfly" },
    { icon: "/diamondSvg/Trilliant Cut.png", text: "Trilliant Cut" },
    { icon: "/diamondSvg/Trilliant Cut.png", text: "Triangle" },
    { icon: "/diamondSvg/Kite.png", text: "Kite" },
    { icon: "/diamondSvg/Briolette.png", text: "Briolette" },
  ],
  availability: ["Stock", "Memo"],
  report: ["GIA", "IGI", "NON CERT"],
  F_Color: ["Blue", "Green", "Orange", "Pink", "Yellow"],
  location: ["New York", "Los Angeles"],
};
