import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import SaveSearchJwelery from "./saveJwelery/SaveSearchJwelery";
import { useNavigate } from "react-router-dom";
import { fetchSaveSearchSlice } from "../../../../redux/slice/savesearchSlice/savesearchSlice";
import { baseUrl } from "../../../../utils/url";
import Swal from "sweetalert2";
import { decrypt } from "../../../../utils/EncryptDecrypt";

export default function SaveSearch() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { savesearch } = useSelector(
    (state) => state.savesearch
  );


  useEffect(() => {
    dispatch(fetchSaveSearchSlice());
  }, [dispatch]);

  const handleDelete = async (searchId) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/users/deleteSearch/${searchId}`,
        {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (response.ok) {
        await Swal.fire({
          title: "Delete Successfully!",
          text: "",
          icon: "success",
        });

        dispatch(fetchSaveSearchSlice());
      } else {
        throw new Error("Failed to delete item from saved searches");
      }
    } catch (error) {
      console.error("Error deleting item from saved searches:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleViewDetails = (searchId) => {
    navigate(`/inventorypage/diamondcardpage/${searchId}`);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 350 },
    // { field: "userId", headerName: "User ID", width: 150 },
    { field: "name", headerName: "name", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDelete(params.row.searchId)}>Delete</button>
        </>
      ),
    },
    {
      field: "View",
      headerName: "View",
      width: 180,
      renderCell: (params) => (
        <>
          <button
            onClick={() => handleViewDetails(params.row.searchId)}
            style={{ marginLeft: 8 }}
          >
            View Details
          </button>
        </>
      ),
    },
  ];

  const rows = savesearch
    .filter((item) => item.type === "diamond")
    .map((item) => ({
      id: item.id,
      searchId: item.SearchId,
      name: item.name,
      userId: item.userId,
    }));

  const encryptedPermission = sessionStorage.getItem("permissions");
  const decryptedPermission = decrypt(encryptedPermission);
  const JewelryPermissions = JSON.parse(decryptedPermission);

  return (
    <>
      <section style={{ height: 400, width: "99%" }}>
        <h1 className="text-[30px] font-semibold text-center">
          Diamond Saved Searches
        </h1>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
        {JewelryPermissions.jewelry === 1 &&
          <SaveSearchJwelery />
        }
      </section>
    </>
  );
}
