import { DefaultLayout } from '../../../components/layout/defaultLayout'
import WishList from '../../../components/Inventory/Search/wishlist/WishList'
import React from 'react'

export default function WishListPage() {
  return (
    <DefaultLayout>
    <WishList />
    </DefaultLayout>
  )
}
