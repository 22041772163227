import React from "react";
import Requests from "../../components/request/Requests";
import { DefaultLayout } from "../../components/layout/defaultLayout";

export default function RequestsPage() {
  return (
    <DefaultLayout>
      <Requests />
    </DefaultLayout>
  );
}
