import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { DefaultLayout } from "../../components/layout/defaultLayout";


const DashboardPage = () => {
  return (
    <DefaultLayout>
      <Dashboard />
    </DefaultLayout>
  );
};

export default DashboardPage;
