import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { fetchWishlistSlice } from "../../../../redux/slice/wishlistSlice/wishlistSlice";
import { baseUrl } from "../../../../utils/url";

export default function WishList() {
  const dispatch = useDispatch();
  const wishList = useSelector((state) => state.wishlist.wishlist);


  // useEffect(() => {
  //   dispatch(fetchWishlistSlice());
  // }, [dispatch]);

  const handleDelete = async (productId) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/users/delete-wishlist/${productId}`,
        {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (response.ok) {
        // dispatch(fetchWishlistSlice());
      } else {
        throw new Error("Failed to delete item from cart");
      }
    } catch (error) {
      console.error("Error deleting item from cart:", error);
    }
  };

  const columns = [
    { field: "Stock #", headerName: "Stock #", width: 100 },
    { field: "Availability", headerName: "Availability", width: 120 },
    { field: "Lab", headerName: "Lab", width: 100 },
    { field: "Shape", headerName: "Shape", width: 100 },
    { field: "Weight", headerName: "Weight", width: 100 },
    { field: "Color", headerName: "Color", width: 80 },
    { field: "Clarity", headerName: "Clarity", width: 80 },
    { field: "Cut", headerName: "Cut", width: 80 },
    { field: "Polish", headerName: "Polish", width: 100 },
    { field: "Symmetry", headerName: "Symmetry", width: 100 },
    { field: "Discount", headerName: "Discount", width: 100 },
    { field: "Price", headerName: "Price", width: 100 },
    { field: "TotalPrice", headerName: "Total Price", width: 100 },
    { field: "Depth", headerName: "Depth", width: 80 },
    { field: "Table", headerName: "Table", width: 70 },
    { field: "Measurements", headerName: "Measurements", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => (
        <button onClick={() => handleDelete(params.row.id)}>
          <MdDeleteForever className="text-xl" />
        </button>
      ),
    },
  ];

  const rows =
    wishList && wishList.type === "diamond"
      ? wishList?.data?.map((item) => ({
          id: item.id,
          Stock: item["Stock #"] || "",
          Availability: item["Availability"] || "",
          Lab: item["Lab"] || "",
          Shape: item["Shape"] || "",
          Weight: item["Weight"] || "",
          Color: item["Color"] || "",
          Clarity: item["Clarity"] || "",
          Cut: item["Cut Grade"] || "",
          Polish: item["Polish"] || "",
          Symmetry: item["Symmetry"] || "",
          Discount: item["Discount Percent"] || "",
          Price: item["Price"] || "",
          TotalPrice: (parseFloat(item["Total Price"]) || 0).toFixed(2),
          Depth: item["Depth Percent"] || "",
          Table: item["Table Percent"] || "",
          Measurements: item["Measurements"] || "",
        }))
      : [];

  const columnsSecondTable = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
    },
    { headerName: "Sku #", field: "Sku", width: 100, filter: true },
    { headerName: "Lot Name", field: "LotName", width: 120, filter: true },
    {
      headerName: "Jewelry Type",
      field: "JewelryType",
      width: 140,
      filter: true,
    },
    {
      headerName: "Jewelry Subtype",
      field: "JewelrySubtype",
      width: 120,
      filter: true,
    },
    { headerName: "Metal Type", field: "MetalType", width: 150, filter: true },
    {
      headerName: "Setting Type",
      field: "SettingType",
      width: 140,
      filter: true,
    },
    {
      headerName: "Orientation",
      field: "Orientation",
      width: 140,
      filter: true,
    },
    {
      headerName: "Diamond Shape",
      field: "DiamondShape",
      width: 150,
      filter: true,
    },
    {
      headerName: "Total Diamond Weight",
      field: "TotalDiamondWeight",
      width: 180,
      filter: true,
    },
    {
      headerName: "Total Diamond Pcs",
      field: "TotalDiamondPcs",
      width: 180,
      filter: true,
    },
    {
      headerName: "Total Price",
      field: "TotalPrice",
      width: 150,
      filter: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => (
        <button onClick={() => handleDelete(params.row.id)}>
          <MdDeleteForever className="text-xl" />
        </button>
      ),
    },
  ];

  const rowsSecondTable =
    wishList && wishList.type === "jewelry"
      ? wishList?.data?.map((item) => ({
          id: item.id,
          Sku: item["Sku #"] || "",
          LotName: item["Lot Name"] || "",
          JewelryType: item["Jewelry Type"] || "",
          JewelrySubtype: item["Jewelry Subtype"] || "",
          MetalType: item["Metal Type"] || "",
          SettingType: item["Setting Type"] || "",
          Orientation: item["Orientation"] || "",
          DiamondShape: item["Diamond Shape"] || "",
          TotalDiamondWeight: item["Total Diamond Weight"] || "",
          TotalDiamondPcs: item["Total Diamond Pcs"] || "",
          TotalPrice: parseFloat((item["Total Price"] || "0").replace(/,/g, "")).toFixed(2),
        }))
      : [];

  return (
    <section style={{ width: "100%" }}>
      <h1 className="text-[30px] font-semibold text-center">Diamond Favourite Item</h1>
      <div style={{ height: 440, marginBottom: 40 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <h1 className="text-[30px] font-semibold text-center">Jewelry Favourite Item</h1>
      <div style={{ height: 440 }}>
        <DataGrid
          rows={rowsSecondTable}
          columns={columnsSecondTable}
          pageSize={5}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </section>
  );
}
