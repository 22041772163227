import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { fetchAdminAllInvoice } from "../../../../redux/slice/adminallinvoice/AdminAllInvoice";
import { baseUrl } from "../../../../utils/url";

export default function AdminAllInvoice() {
  const [data, setData] = useState([]);

  useEffect(() => {
    handleadminalInvoice();
  }, []);

  const handleadminalInvoice = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/admin/get-all-diamond_jewel?request_type=invoice`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const diamondColumnDefs = useMemo(
    () => [
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },

      {
        headerName: "Req_Id",
        field: "request_id",
        width: 100,
        filter: true,
      },
      {
        headerName: "Req Bulk Id",
        field: "request_bulk_id",
        width: 180,
        filter: true,
      },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Stock #", field: "Stock #" },
      {
        headerName: "Status",
        field: "status",
        width: 120,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "withdraw") {
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Availability", field: "Availability" },
      { headerName: "Shape", field: "Shape" },
      { headerName: "Weight", field: "Weight" },
      { headerName: "Clarity", field: "Clarity" },
      { headerName: "Color", field: "Color" },
      { headerName: "Fancy Color", field: "Fancy Color" },
      { headerName: "Fancy Color Intensity", field: "Fancy Color Intensity" },
      { headerName: "Fancy Color Overtone", field: "Fancy Color Overtone" },
      { headerName: "Price", field: "Price" },
      { headerName: "Total Price", field: "Total Price" },
      { headerName: "Discount Percent", field: "Discount Percent" },
      { headerName: "Cut Grade", field: "Cut Grade" },
      { headerName: "Polish", field: "Polish" },
      { headerName: "Symmetry", field: "Symmetry" },
      { headerName: "Depth Percent", field: "Depth Percent" },
      { headerName: "Table Percent", field: "Table Percent" },
      { headerName: "Fluorescence Intensity", field: "Fluorescence Intensity" },
      { headerName: "Lab", field: "Lab" },
      { headerName: "Certificate #", field: "Certificate #" },
      { headerName: "Cert Comment", field: "Cert Comment" },
      { headerName: "Girdle Thick", field: "Girdle Thick" },
      { headerName: "Girdle Thin", field: "Girdle Thin" },
      { headerName: "Measurements", field: "Measurements" },
      { headerName: "Pavilion Depth", field: "Pavilion Depth" },
      { headerName: "Crown Height", field: "Crown Height" },
      { headerName: "Crown Angle", field: "Crown Angle" },
      { headerName: "Pavilion Angle", field: "Pavilion Angle" },
      { headerName: "Laser Inscription", field: "Laser Inscription" },
    ],
    []
  );

  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        filter: true,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Req_Id",
        field: "request_id",
        width: 100,
        filter: true,
      },
      {
        headerName: "Request Bulk Id",
        field: "request_bulk_id",
        width: 180,
        filter: true,
      },
      { headerName: "Company Name", field: "company_name" },
      { headerName: "Sku #", field: "Sku #", width: 100 },
      {
        headerName: "Status",
        field: "status",
        width: 120,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "withdraw") {
            return {
              color: "#3b71ca",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "expire") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "First Name", field: "first_name" },
      { headerName: "Availability", field: "Avability", width: 150 },
      { headerName: "Lot Name", field: "Lot Name", width: 120 },
      { headerName: "Jewelry Type", field: "Jewelry Type", width: 140 },
      { headerName: "Jewelry Subtype", field: "Jewelry Subtype", width: 120 },
      { headerName: "Metal Type", field: "Metal Type", width: 150 },
      { headerName: "Setting Type", field: "Setting Type", width: 140 },
      { headerName: "Orientation", field: "Orientation", width: 140 },
      { headerName: "Diamond Shape", field: "Diamond Shape", width: 150 },
      {
        headerName: "Total Diamond Weight",
        field: "Total Diamond Weight",
        width: 180,
      },
      {
        headerName: "Total Diamond Pcs",
        field: "Total Diamond Pcs",
        width: 180,
      },
      { headerName: "Total Price", field: "Total Price", width: 150 },
      { headerName: "Lab Name", field: "Lab Name", width: 150 },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  );

  const diamondRowData = useMemo(() => {
    if (data && data.length) {
      return data.filter((item) => item.type === "diamond").map((item) => item);
    }
    return [];
  }, [data]);

  const jewelryRowData = useMemo(() => {
    if (data && data.length) {
      return data.filter((item) => item.type === "jewelry").map((item) => item);
    }
    return [];
  }, [data]);

  return (
    <section style={{ width: "100%" }}>
      <div className="text-center font-bold text-2xl mb-5">
        Diamond Invoices Request
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: "370px", marginBottom: "20px" }}
      >
        <AgGridReact
          rowData={diamondRowData}
          columnDefs={diamondColumnDefs}
          defaultColDef={defaultColDef}
          pagination={true} // Enable pagination
          paginationPageSize={50}
        />
      </div>
      <div className="text-center font-bold text-2xl mt-14 mb-5">
        Jewelry Invoices Request
      </div>
      <div className="ag-theme-alpine" style={{ height: "370px" }}>
        <AgGridReact
          rowData={jewelryRowData}
          columnDefs={jewelryColumnDefs}
          defaultColDef={defaultColDef}
          pagination={true} // Enable pagination
          paginationPageSize={50}
        />
      </div>
    </section>
  );
}
