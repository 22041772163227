import {
  json,
  Link,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Navbar from "../../../Navbar/Navbar";
import { useDispatch } from "react-redux";
import { SlOptionsVertical } from "react-icons/sl";
import Menu from "../../diamondssearch/diamondCard/components/Menu";
import Loader from "../../../../Loader/Loader";
import { fetchWishlistSlice } from "../../../../../redux/slice/wishlistSlice/wishlistSlice";
import HeartIcon from "../../../../HeartIcon/HeartIcon";
import { baseUrl } from "../../../../../utils/url";
import { AiOutlineSortAscending } from "react-icons/ai";
import Swal from "sweetalert2";

export default function JeweleryCard() {
  const type = "jewelry";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchIdQuery = queryParams.get("searchId");
  const { searchId: searchIdParams } = useParams();
  const searchId = searchIdQuery || searchIdParams;
  const scrollPositionRef = useRef(0);
  const [searchData, setSearchData] = useState([]);
  const [page, setPage] = useState(1);
  const [likedItems, setLikedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [scrollRestored, setScrollRestored] = useState(false);
  const dispatch = useDispatch();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [restoringScroll, setRestoringScroll] = useState(false);

  // useEffect(() => {
  //   dispatch(fetchWishlistSlice());
  // }, [dispatch]);

  const toggleMenu = useCallback(
    (id) => {
      setOpenMenuId(openMenuId === id ? null : id);
    },
    [openMenuId]
  );

  //control scroll position

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentLoaded(true);
    },1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.scrollY;
      sessionStorage.setItem(
        `searchId-${searchId}`,
        JSON.stringify({ scrollPosition: scrollPositionRef.current })
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [searchId]);


  useEffect(() => {
    const savedPosition = JSON.parse(
      sessionStorage.getItem(`searchId-${searchId}`)
    );
    if (savedPosition && savedPosition.scrollPosition) {
      setRestoringScroll(true);
      const tryScrollRestore = () => {
        if (window.scrollY !== savedPosition.scrollPosition) {
          window.scrollTo(0, savedPosition.scrollPosition);
          requestAnimationFrame(tryScrollRestore);
        } else {
        setRestoringScroll(false); // Scroll restoration complete
      }
      }
     
      tryScrollRestore();
    }
  }, []);

  useEffect(() => {
    if (contentLoaded) {
      const savedPosition = JSON.parse(
        sessionStorage.getItem(`searchId-${searchId}`)
      );
      if (savedPosition && savedPosition.scrollPosition) {
        window.scrollTo(0, savedPosition.scrollPosition);
      }
    }
  }, [contentLoaded, searchId]);


  useEffect(() => {
    const handleBeforeUnload = () => {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem(
        `searchId-${searchId}`,
        JSON.stringify({ scrollPosition })
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [searchId]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 5 &&
        hasMore &&
        !loading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  useEffect(() => {
    if (scrollRestored) {
      sessionStorage.removeItem(`searchId-${searchId}`);
    }
  }, [scrollRestored, searchId]);

  const fetchSearchData = useCallback(
    async (searchId, page, sortField, sortOrder) => {
      if (searchId) {
        setLoading(true);
        try {
          const token = sessionStorage.getItem("token");
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
          myHeaders.append("Content-Type", "application/json");

          let url;
          if (sortField && sortOrder) {
            url = `${baseUrl}/users/order-filter/jewelry/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
          } else {
            url = `${baseUrl}/users/search-results/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
          }

          let response = await fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          });

          if (!response.ok || response.status === 404) {
            url = `${baseUrl}/users/SaveData/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
            const secondResponse = await fetch(url, {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            });

            if (!secondResponse.ok) {
              if (secondResponse.status === 401) {
                console.error("Unauthorized: Token expired or invalid");
              } else {
                throw new Error("Failed to fetch search results");
              }
            }

            response = secondResponse;
          }

          const data = await response.json();
          if (data.data.length > 0) {
            setSearchData((prevData) => {
              const newData =
                page === 1 ? data.data : [...prevData, ...data.data];
              const uniqueData = newData.filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              );
              return uniqueData;
            });

            setDataLength(data.total);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
        } finally {
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    fetchSearchData(searchId, page);
  }, [searchId, page, fetchSearchData]);

  const handleLike = useCallback(
    async (itemId) => {
      setLikedItems((prev) => ({
        ...prev,
        [itemId]: !prev[itemId],
      }));

      const isLiked = likedItems[itemId];

      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: isLiked ? "DELETE" : "POST",
          headers: myHeaders,
          body: JSON.stringify({
            type: type,
            record_id: itemId,
            wish_list: true,
          }),
          redirect: "follow",
        };

        const apiEndpoint = isLiked
          ? `${baseUrl}/users/delete-wishlist/${itemId}`
          : `${baseUrl}/users/add-to-table`;

        const response = await fetch(apiEndpoint, requestOptions);

        if (response.ok) {
          // dispatch(fetchWishlistSlice());
          Swal.fire({
            title: "Good job!",
            text: "Your item add successfully in Wishlist!",
            icon: "success",
          });
        } else {
          throw new Error("Failed to update like status");
        }
      } catch (error) {
        console.error("Error updating like status:", error);
      }
    },
    [likedItems, dispatch]
  );

  const fallbackImage = "/logo/No Image Found.jpg";

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (sortOption) => {
    let sortField = "";
    let sortOrder = "";

    if (sortOption === "low-to-high") {
      sortField = "Total Price";
      sortOrder = "asc";
    } else if (sortOption === "high-to-low") {
      sortField = "Total Price";
      sortOrder = "desc";
    } else if (sortOption === "cts-low-to-high") {
      sortField = "Total Diamond Weight";
      sortOrder = "asc";
    } else if (sortOption === "cts-high-to-low") {
      sortField = "Total Diamond Weight";
      sortOrder = "desc";
    }
    setPage(1);
    fetchSearchData(searchId, 1, sortField, sortOrder);
  };

  const AvailabilityDot = useMemo(
    () =>
      ({ availability }) => {
        const dotColor =
          availability === "MEMO"
            ? "red"
            : availability === "STOCK"
              ? "green"
              : "";
        return (
          <span className="flex items-center h-[50px] w-auto">
            <span className={`dot ${dotColor}`}></span>
          </span>
        );
      },
    []
  );

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevSelectedItems) => {
      const updatedItems = { ...prevSelectedItems };
      if (updatedItems[item.id]) {
        delete updatedItems[item.id]; // Unselect the item
      } else {
        updatedItems[item.id] = item; // Store full item details
      }
      return updatedItems;
    });
  };

  return (
    <section>
      <div className="p-1 sticky top-0 bg-[#feefdc] rounded-3xl z-50">
        <div className="flex justify-between pt-1 items-center pb-1 px-5">
          <div className="flex items-center gap-5">
            <div>
              <div
                className="text-lg bg-[#2688bc] text-white py-1 px-2 rounded-lg cursor-pointer group relative"
                onClick={toggleDropdown}
              >
                <span className="">
                  <AiOutlineSortAscending fontSize={25} />
                </span>
                <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
                  Sort Your Result
                </div>
              </div>
              {isOpen && (
                <div className="absolute left-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("low-to-high")}
                    >
                      Sort by Price: Low to High
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("high-to-low")}
                    >
                      Sort by Price: High to Low
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("cts-low-to-high")}
                    >
                      Sort by CTS: Low to High
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("cts-high-to-low")}
                    >
                      Sort by CTS: High to Low
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <h1 className="text-2xl font-bold">Search Results</h1>
              <span>
                Total Jewelry -{" "}
                <span className="font-semibold text-[16px]">{dataLength}</span>
              </span>
            </div>
          </div>
          <Navbar
            selectedItems={Object.keys(checkedItems).filter(
              (id) => checkedItems[id]
            )}
            searchId={searchId}
            type={type}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        </div>
      </div>

      {restoringScroll && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-100 z-30">
          <Loader />
        </div>
      )}

      <div
        className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 p-4 item-link ${searchData.length === 0
          ? "flex items-center justify-center h-screen"
          : ""
          }`}
      >
        {searchData?.length > 0 ? (
          searchData?.map((item, index) => {
            let sku = item["Sku #"];
            let processedSku = sku?.replace(/\//g, "-");
            return (
              <div
                key={index}
                className="border p-4 rounded-3xl relative shadow-2xl"
              >
                <span
                  className="z-10 absolute left-6 top-6 cursor-pointer"
                // onClick={() => handleLike(item.id)}
                >
                  {/* <HeartIcon filled={likedItems[item.id]} /> */}
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    className="hidden"
                    checked={!!checkedItems[item.id]}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label
                    htmlFor={`checkbox-${item.id}`}
                    className={`cursor-pointer flex items-center z-10 justify-center w-[20px] h-[20px] border-2 border-gray-300 rounded transition-colors duration-300 ${checkedItems[item.id]
                      ? "bg-blue-500 border-blue-500"
                      : "bg-white"
                      }`}
                  >
                    <svg
                      className={`w-4 h-4 text-white transition-transform duration-300 ${checkedItems[item.id]
                        ? "transform scale-100"
                        : "transform scale-0"
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                </span>
                <Link
                  to={`/inventorypage/jewelerycardpage/${searchId}/${processedSku}`}
                >
                  <div className="z-50">
                    <span className="absolute right-7 top-[5px] z-10">
                      <AvailabilityDot availability={item["Avability"]} />
                    </span>
                  </div>
                  <div className="image-container rounded-xl">
                    {item["Image File"] && item["Image File"] !== "NULL" ? (
                      <img
                        src={item["Image File"]}
                        alt={item.Title}
                        loading="lazy"
                        className="rounded  w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={fallbackImage}
                        alt="Fallback Image"
                        loading="lazy"
                        className="rounded  w-full h-full object-cover"
                      />
                    )}
                  </div>
                </Link>
                <h2 className="text-[15px] font-bold mt-2">{item.Title} </h2>
                <span className="text-[15px] text-gray-500 font-semibold">
                  {item["Sku #"]}
                </span>

                <div className="text-black-600 flex justify-between items-center">
                  <div className="flex flex-col text-[15px]">
                    {item["Total Price"] ? (
                      <>
                        <span>Total Price :</span>
                        <span className="font-semibold">
                          ${item["Total Price"]}
                        </span>
                      </>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  {item["Stock"] ? (
                    <>
                      <div className="flex flex-col text-[15px] ">
                        <span className="pt-1">Stock : </span>
                        <span className="font-semibold">{item["Stock"]}</span>
                      </div>
                    </>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  <div className="relative inline-block text-left">
                    <button
                      type="button"
                      onClick={() => toggleMenu(item.id)}
                      className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <SlOptionsVertical fontSize={20} />
                    </button>
                    {openMenuId === item.id && (
                      <Menu searchId={searchId} item={item} type={type} />
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) :
          loading ? (
            <div className="flex flex-col items-center justify-start min-h-screen mt-4">
              <p className="flex items-center justify-center mt-4">
                <Loader />
              </p>
            </div>
          ) : (
            <div className="w-screen">
              <p className="text-lg font-bold text-center mt-4 whitespace-nowrap">
                Unfortunately no items were found as per your search criteria. Please try modifying your search.
              </p>
            </div>
          )}
        { }
      </div>
      <style>{`
        .image-container {
          position: relative;
          width: 100%;
          height: 270px;
          overflow: hidden;
        }
      `}</style>
    </section>
  );
}
