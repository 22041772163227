import React from "react";
import DashboardMemo from "../../../components/Dashboard/DashboardMemo/DashboardMemo";
import { DefaultLayout } from "../../../components/layout/defaultLayout";

export default function MemoPage() {
  return (
    <DefaultLayout>
      <DashboardMemo />
    </DefaultLayout>
  );
}
