import Login from '../../components/Login/Login'
import React from 'react'

export default function LoginPage() {
  return (
    <>
    <Login />
    </>
  )
}
