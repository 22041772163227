
import DiamondDetail from "../../../../components/Inventory/Search/diamondssearch/diamondCard/diamondCardDaynamic/DiamondDetail";

import React from "react";
import { DefaultLayout } from "../../../../components/layout/defaultLayout";

export default function DiamondDynamicCard() {
  return (
    <DefaultLayout>
      <DiamondDetail />
    </DefaultLayout>
  );
}
