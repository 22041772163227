import SaveSearch from "../../../components/Inventory/Search/savesearch/SaveSearch";
import { DefaultLayout } from "../../../components/layout/defaultLayout";
import React from "react";

export default function SaveSearchPage() {
  return (
    <DefaultLayout>
      <SaveSearch />
    </DefaultLayout>
  );
}
