import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchJewelrySlice } from "../../../../../redux/slice/savesearchSlice/saveJwelerysearchSlice";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../../../utils/url";
import Swal from "sweetalert2";

export default function SaveSearchJewelry() {
  const dispatch = useDispatch();
  const { jewelry, loading, error } = useSelector((state) => state.savesearchjwelery);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchJewelrySlice());
  }, [dispatch]);

  const handleDelete = async (searchId) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/users/deleteSearch/${searchId}`,
        {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (response.ok) {
        await Swal.fire({
          title: "Delete Successfully!",
          text: "",
          icon: "success",
        });
        dispatch(fetchJewelrySlice());
      } else {
        throw new Error("Failed to delete item from jewelry");
      }
    } catch (error) {
      console.error("Error deleting item from jewelry:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleViewDetails = (searchId) => {
    navigate(`/inventorypage/jewelerycardpage/${searchId}`);
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
          <button onClick={() => handleDelete(params.row.searchId)}>Delete</button>
        </>
      ),
    },
    {
      field: "View",
      headerName: "View",
      width: 180,
      renderCell: (params) => (
        <>
          <button onClick={() => handleViewDetails(params.row.searchId)} style={{ marginLeft: 8 }}>
            View Details
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const rows = jewelry
    ? jewelry
      .filter((item) => item.type === "jewelry")
      .map((item) => ({
        id: item.id,
        searchId: item.SearchId,
        name: item.name,
      }))
    : [];

  return (
    <section style={{ height: 400, width: "100%" }}>
      <h1 className="text-[30px] mt-8 font-semibold text-center">Jewelry Saved Searches</h1>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableSelectionOnClick
      />
    </section>
  );
}
