import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

// Define the initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Create an async thunk for the GET request
export const fetchData = createAsyncThunk("memopage/fetchData", async () => {
  try {
    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/users/get-request?request_type=memo`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch SaveSearch");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch SaveSearch: ${error.message}`);
  }
});

// Create the slice
const memopageSlice = createSlice({
  name: "memopage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default memopageSlice.reducer;
