import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/url";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { encrypt, decrypt } from "../../utils/EncryptDecrypt";
import { IoIosClose } from "react-icons/io";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPasswordEncrypted = localStorage.getItem("etc");
    const savedRememberMe = localStorage.getItem("rememberMe");

    if (savedEmail && savedPasswordEncrypted && savedRememberMe) {
      setEmail(decrypt(savedEmail));
      setPassword(decrypt(savedPasswordEncrypted));
      setRememberMe(decrypt(savedRememberMe) === "true");
    }
  }, []);

  useEffect(() => {
    const savedEmail = sessionStorage.getItem("email");
    const firstNameEncrypted = sessionStorage.getItem("first_name");

    if (savedEmail && firstNameEncrypted) {
      setEmail(decrypt(savedEmail));
      setPassword(decrypt(firstNameEncrypted));
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/dashboardpage");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch(`${baseUrl}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify({ email, password, rememberMe }),
      });

      if (response.ok) {
        const data = await response.json();
        const now = new Date().getTime();
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("id", data.user.id);
        sessionStorage.setItem("first_name", encrypt(data.user.first_name));
        sessionStorage.setItem("UrI", data.user.role_id);
        sessionStorage.setItem("email", encrypt(email));
        sessionStorage.setItem("pmatSEmItnigOL", now);


        if (rememberMe) {
          localStorage.setItem("email", encrypt(email));
          localStorage.setItem("etc", encrypt(password));
          localStorage.setItem("rememberMe", encrypt("true"));
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("etc");
          localStorage.removeItem("rememberMe");
        }

        await Swal.fire({
          title: "Log In!",
          text: "Log In Successfully!",
          icon: "success",
        });

        navigate("/dashboardpage");
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Login failed");

        if (errorData.message === "Not approved") {
          // Show custom popup for not approved error
          await Swal.fire({
            title: "Pending Approval",
            text: "Your login request is pending approval. You will be notified via email once it is approved.",
            icon: "info",
            confirmButtonText: "Okay",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid email or password! Please sign up for a new account if you don't have one.",
          });
        }
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgetPasswordSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await fetch(`${baseUrl}/users/forgot-password`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        await Swal.fire({
          title: "Password Reset!",
          text: "Please check your email for reset instructions.",
          icon: "success",
        });
        setIsForgetPasswordOpen(false);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to send reset instructions");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    }
  };

  useEffect(() => {
    const hasModalOpened = localStorage.getItem("hasModalOpened");
    if (!hasModalOpened) {
      setIsOpen(true);
      localStorage.setItem("hasModalOpened", "true");
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    console.log("Modal closed");
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black z-50 bg-opacity-50 flex items-center justify-center">
          <div className="modal-content rounded  relative">
            <span
              className="close-button cursor-pointer text-lg absolute right-[20%] top-0"
              onClick={closeModal}
            >
              <IoIosClose fontSize={40} />
            </span>
            <div className="flex justify-center">
              <img
                src="jewelry poppup_011.jpg"
                alt="Jewelry Popup"
                className="w-[60%] h-auto"
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex min-h-screen">
        <div className="flex-1 max-sm:hidden">
          <img
            src="/logo/loginSideImg.jpg"
            alt="loginSideImg"
            className="w-[100%] h-full"
          />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center p-8">
          <div className="text-center w-full max-w-md">
            <div className="flex justify-center ">
              <img
                src="/logo/Logo Black 001.png"
                alt="Skylab Logo"
                width={150}
                height={150}
              />
            </div>
            <h1 className="text-[18px] font-semibold my-4">Already Member?</h1>
            {error && <p className="text-red-500">{error}</p>}
            {isLoading && <p className="text-blue-500">Logging in...</p>}
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter your email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
              />
              <div className="relative w-full">
                <input
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"} // Toggle password visibility
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="rememberMe" className="ml-2">
                    Save Password
                  </label>
                </div>
                <div className="flex items-center">
                  <span
                    onClick={() => setIsForgetPasswordOpen(true)} // Open the forget password modal
                    className="ml-2 underline text-blue-500 cursor-pointer"
                  >
                    Forget Password
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="w-full p-2 bg-blue-600 text-white rounded"
                disabled={isLoading} // Disable button when loading
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </form>
            <p className="mt-4">
              Don't have an account?{" "}
              <Link to="/signuppage" className="text-blue-600">
                Sign up
              </Link>
            </p>
          </div>
        </div>

        {isForgetPasswordOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded shadow-lg w-full max-w-sm">
              <h2 className="text-2xl font-bold mb-4">Forget Password</h2>
              <input
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                onClick={handleForgetPasswordSubmit}
                className="w-full p-2 bg-blue-600 text-white rounded"
              >
                Submit
              </button>
              <button
                onClick={() => setIsForgetPasswordOpen(false)}
                className="w-full p-2 mt-4 bg-gray-500 text-white rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
