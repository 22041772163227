import React from "react";
import AdminAllInvoice from "../../../adminComponents/admindashboard/admindashboard/adminallinvoice/AdminAllInvoice";
import { AdminDefaultLayout } from "../../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function AdminAllInvoicePage() {
  return (
    <AdminDefaultLayout>
      <AdminAllInvoice />
    </AdminDefaultLayout>
  );
}
