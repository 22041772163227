import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/url";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function AdminMostSearch() {
  const [diamondData, setDiamondData] = useState([]);
  const [jewelryData, setJewelryData] = useState([]);
  const [diamondColumnDefs] = useState([
    { headerName: "Type", field: "type", sortable: true, filter: true },
    { headerName: "Shape", field: "shape", sortable: true, filter: true },
    { headerName: "Weight", field: "weight", sortable: true, filter: true },
  ]);
  const [jewelryColumnDefs] = useState([
    {headerName: "Type", field : "type" , sortable: true, filter: true},
    {headerName: "Jewelry Type", field : "Jewelry Type", sortable: true, filter: true},
    {headerName: "Jewelry Subtype", field : "Jewelry Subtype", sortable: true, filter: true}
  ])

  const fetchDiamondData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      let url = `${baseUrl}/admin/most-searched?type=diamond`;

      let response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      const diamonds = data ? [data] : [];
      setDiamondData(diamonds);
    } catch (error) {
      console.error("Error fetching diamond data:", error);
      setDiamondData([]);
    }
  };

  const fetchJewelryData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if(!token){
        console.error("Token not found");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      let url = `${baseUrl}/admin/most-searched?type=jewelry`;

      let response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const jewelries = data ? [data] : [];
      setJewelryData(jewelries);
    } catch (error) {
      console.error("Error fetching jewelry data:",error);
      setJewelryData([]);
    }
  };

  useEffect(() => {
    fetchDiamondData();
    fetchJewelryData();
  }, []);

  return (
    <section>
    <div style={{ height: '350px', width: '100%'}} className="mb-28 ag-theme-alpine">
      <h1 className="text-[25px] text-center py-5 font-bold">Diamond Most Search</h1>
      <AgGridReact
        rowData={diamondData}
        columnDefs={diamondColumnDefs}
        defaultColDef={{
          flex: 1,
          minWidth: 100,
        }}
        pagination={true}
        paginationPageSize={50}
      />
    </div>
    <div style={{ height: '350px', width: '100%' }} className="ag-theme-alpine">
      <h1 className="text-[25px] text-center py-5 font-bold">Jewelery Most Search</h1>
      <AgGridReact
        rowData={jewelryData}
        columnDefs={jewelryColumnDefs}
        defaultColDef={{
          flex: 1,
          minWidth: 100,
        }}
        pagination={true}
        paginationPageSize={50}
      />
    </div>
    </section>
  );
}
