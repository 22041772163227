import React, { useEffect, useState } from "react";
import { CgNotes } from "react-icons/cg";
import { IoIosRefresh } from "react-icons/io";
import { IoSaveOutline, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchUserList } from "../../../../redux/slice/getUser/getUser";
import { baseUrl } from "../../../../utils/url";
import Swal from "sweetalert2";

export function AdminMainNavbar({ search }) {

    const dispatch = useDispatch();
    const getUser = useSelector((state) => state.user);
    const { savesearch } = useSelector((state) => state.savesearch);

    const userPermission = getUser.user.map((el) => el.lockprice_mode);

    const [showInput, setShowInput] = useState(false);
    const [query, setQuery] = useState("");
    const navigate = useNavigate();
    const [adminPopup, setAdminPopup] = useState(false);
    const [markup, setMarkup] = useState("");

    const toggleInput = () => {
        setShowInput(!showInput);
    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        const formattedQuery = input.replace(/\s+/g, ",");
        setQuery(formattedQuery);
    };

    useEffect(() => {
        dispatch(fetchUserList());
    }, [dispatch]);

    const handleSearchSubmit = async () => {

        try {
            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const queryArray = query
                .split(",")
                .map((item) => item.trim())
                .filter((item) => item !== "");

            const response = await fetch(`${baseUrl}/users/search/individual`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({ value: queryArray, type: search }),
            });

            const responseData = await response.json();
            const responseMessage = responseData.message;

            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: responseMessage,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

            const searchId = await responseData.searchId;

            if (search == "diamond") {
                console.log("first")
                navigate(`/admininventorypage/admindiamondcardpage?searchId=${searchId}`);
            } else {
                navigate(`/admininventorypage/adminJewelrycardpage?searchId=${searchId}`);
            }
        } catch (error) {
            console.error("Error submitting search:", error);
        }
    };

    const handlePopup = () => {
        setAdminPopup(true);
    };

    const handleSubmit = async (priceMode) => {
        const formData = new FormData();
        formData.append("price_mode", priceMode);

        if (priceMode === "markup") {
            formData.append("markup_price", markup);
        }

        try {
            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const id = sessionStorage.getItem("id");

            const response = await fetch(`${baseUrl}/admin/approve/${id}`, {
                method: "PUT",
                headers: myHeaders,
                body: formData,
            });

            if (response.ok) {
                setAdminPopup(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <section>
            <div className=" p-3 top-10 absolute right-52">
                <div className="flex lg:gap-5 gap-5 relative">
                    <div
                        className={`transition-all duration-300 ease-in-out ${showInput ? "w-60 opacity-100" : "w-0 opacity-0"
                            } overflow-hidden`}
                    >
                        <input
                            type="text"
                            className="px-2 py-2 border rounded-md w-full"
                            placeholder="Search..."
                            value={query}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button
                        className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md relative group"
                        onClick={() => {
                            toggleInput();
                            if (showInput) handleSearchSubmit();
                        }}
                    >
                        <IoSearch fontSize={20} />
                        <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                            Search
                        </div>
                    </button>
                </div>
            </div>
        </section>
    );
}
