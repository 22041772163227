import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MatchPage from "./pages/inventorypage/diamondcardpage/match/Match";
import DiamondCardPage from "./pages/inventorypage/diamondcardpage/DiamondCardPage";
import SaveSearchPage from "./pages/inventorypage/savesearchpage/SaveSearchPage";
import WishListPage from "./pages/inventorypage/wishlistpage/WishListPage";
import LoginPage from "./pages/loginpage/LoginPage";
import SignupPage from "./pages/signuppage/Signuppage";
import UserProfilePage from "./pages/userprofilepage/Userprofilepage";
import DashboardPage from "./pages/dashboardpage/DashboardPage";
import DiamondDynamicCard from "./pages/inventorypage/diamondcardpage/DiamondDynamicCard/DiamondDynamicCard";
import JewelryDynamicCard from "./pages/inventorypage/jewelerycardpage/JewelryDynamicCard/JewelryDynamicCard";
import JeweleryCardPage from "./pages/inventorypage/jewelerycardpage/JeweleryCardPage";
import MemoPage from "./pages/dashboardpage/memo/MemoPage";
import InvoicePage from "./pages/dashboardpage/Invoice/InvoicePage";
import OrderPage from "./pages/dashboardpage/order/OrderPage";
import AdminDashboard from "./adminPages/adminDashboardPage/adminDashboard/AdminDashboardPage";
import AdminDashboardPage from "./adminPages/adminDashboardPage/adminDashboard/AdminDashboardPage";
import AdminAllUsersPage from "./adminPages/adminuserspage/AdminAllUsersPage";
import AdminAddNewUser from "./adminComponents/adminallusers/adminaddconsumeruser/AdminAddConsumer";
import AdminUserDetailsPage from "./adminPages/adminuserspage/adminuserdetailspage/AdminUserDetailsPage";
import AdminMostSearchPage from "./adminPages/adminDashboardPage/adminmostsearchpage/AdminMostSearchPage";
import DiamondsSearch from "./components/Inventory/Search/diamondssearch/DiamondsSerach";
import JewelerySearch from "./components/Inventory/Search/jewelerysearch/JewelerySearch";
import Contact_UsPage from "./pages/contact_uspage/contact_us/Contact_UsPage";
import AdminAnalyticsPage from "./adminPages/adminanalyticspage/AdminAnalyticsPage";
import AdminAddNewCompanyPage from "./adminPages/adminuserspage/adminaddnewuserpage/adminaddnewcompanypage/AdminAddNewCompanyPage";
import AdminUploadfilePage from "./adminPages/adminuploadfilepage/AdminUploadfilePage";
import AdminAllOrdersPage from "./adminPages/adminDashboardPage/adminallorderspage/AdminAllOrdersPage";
import AdminAllInvoicePage from "./adminPages/adminDashboardPage/adminallinvoicepage/AdminAllInvoicePage";
import AdminAllMemoPage from "./adminPages/adminDashboardPage/adminallmemopage/AdminAllMemoPage";
import AdminRequestPage from "./adminPages/adminrequest/AdminRequestPage";
import RequestsPage from "./pages/requestpage/RequestsPage";
import AdminRequestDynamicIdPage from "./adminPages/adminrequest/AdminRequestDynamicIdPage";
import ComparePage from "./pages/comparediamondpage/ComparePage";
import SuperAdminPermissionPage from "./superadminPages/SuperAdminPermissionPage/SuperAdminPermissionPage";
import AdminAddConsumerPage from "./adminPages/adminuserspage/adminaddnewuserpage/AdminAddConsumerPage";
import Table from "./components/Inventory/Search/diamondssearch/diamondCard/components/Table";
import AddUser from "./adminComponents/adminallusers/adduser/AddUser";
import AdminAddNewUserPage from "./adminPages/adminuserspage/adminaddnewuserpage/AdminAddNewUserPage";
import PrivetRoute from "./utils/PrivetRoute";
import ForgetPassword from "./forgetpassword/ForgetPassword";
import GlobalWrapper from "./utils/GlobalWrapper";
import EmailShare from "./email/EmailShare";
import AdminDiamondsSearch from "./adminComponents/adminuploadfile/admininventroyshow/admindiamondsearch/AdminDiamondSearch";
import AdminJewelerySearch from "./adminComponents/adminuploadfile/admininventroyshow/adminjewelrysearch/AdminJewelrySearch";
import AdminDiamondCard from "./adminComponents/adminuploadfile/admininventroyshow/admindiamondcard/AdminDiamondCard";
import AdminJeweleryCard from "./adminComponents/adminuploadfile/admininventroyshow/adminjewelrycard/AdminJewelryCard";
import AdminDiamondDetail from "./adminComponents/adminuploadfile/admininventroyshow/admindiamonddetail/AdminDiamondDetail";
import AdminJewelryDetails from "./adminComponents/adminuploadfile/admininventroyshow/adminjewelrydetail/AdminJewelryDetail";
import AddCartPage from "./pages/inventorypage/addcartpage/AddCartPage";

function App() {

  return (
    <BrowserRouter>
      <GlobalWrapper>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/signuppage" element={<SignupPage />} />

          {/* Admin Routes (Role ID: 1) */}
          <Route
            path="/dashboardpage"
            element={
              <PrivetRoute Component={DashboardPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/contact_us"
            element={
              <PrivetRoute Component={Contact_UsPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/addcartpage"
            element={<PrivetRoute Component={AddCartPage} allowedRole={[1]} />}
          />
          <Route
            path="/inventorypage/diamondcardpage/match"
            element={<PrivetRoute Component={MatchPage} allowedRole={[1]} />}
          />
          <Route
            path="/inventorypage/diamondcardpage"
            element={
              <PrivetRoute Component={DiamondCardPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/diamondcardpage/:searchId"
            element={
              <PrivetRoute Component={DiamondCardPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/jewelerycardpage/:searchId"
            element={
              <PrivetRoute Component={JeweleryCardPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/jewelerycardpage"
            element={
              <PrivetRoute Component={JeweleryCardPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/savesearchpage"
            element={
              <PrivetRoute Component={SaveSearchPage} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/wishlistpage"
            element={<PrivetRoute Component={WishListPage} allowedRole={[1]} />}
          />
          {/* <Route path="/inventorypage" element={<InventoryPage />} /> */}
          <Route
            path="/userprofilepage"
            element={
              <PrivetRoute Component={UserProfilePage} allowedRole={[1]} />
            }
          />
          <Route
            path="/diamondsserach"
            element={
              <PrivetRoute Component={DiamondsSearch} allowedRole={[1]}  />
            }
          />
          <Route
            path="/jewelerysearch"
            element={
              <PrivetRoute Component={JewelerySearch} allowedRole={[1]} />
            }
          />
          <Route
            path="/inventorypage/jewelerycardpage/:searchId/:processedSku"
            element={
              <PrivetRoute Component={JewelryDynamicCard} allowedRole={[1]} />
            }
          />
          <Route path="/emailshare/:type/:id" element={<EmailShare />} />
          <Route
            path="/inventorypage/diamondcardpage/:searchId/:itemId"
            element={
              <PrivetRoute Component={DiamondDynamicCard} allowedRole={[1]} />
            }
          />
          <Route
            path="/admindiamondsserach"
            element={<AdminDiamondsSearch />}
          />
          <Route
            path="/adminjewelrysserach"
            element={<AdminJewelerySearch />}
          />
          <Route
            path="/admininventorypage/admindiamondcardpage"
            element={<AdminDiamondCard />}
          />
          <Route
            path="/admininventorypage/adminJewelrycardpage"
            element={<AdminJeweleryCard />}
          />
          <Route
            path="/admininventorypage/admindiamondcardpage/:searchId/:itemId"
            element={<AdminDiamondDetail />}
          />
          <Route
            path="/admininventorypage/adminjewelerycardpage/:searchId/:processedSku"
            element={<AdminJewelryDetails />}
          />
          <Route
            path="/memopage"
            element={<PrivetRoute Component={MemoPage} allowedRole={[1]} />}
          />
          <Route
            path="/invoicepage"
            element={<PrivetRoute Component={InvoicePage} allowedRole={[1]} />}
          />
          <Route
            path="/orderpage"
            element={<PrivetRoute Component={OrderPage} allowedRole={[1]} />}
          />
          <Route
            path="/requests"
            element={<PrivetRoute Component={RequestsPage} allowedRole={[1]} />}
          />
          <Route
            path="/comparepage"
            element={<PrivetRoute Component={ComparePage} allowedRole={[1]} />}
          />

          {/* Admin Routes (Role ID: 2) */}
          <Route
            path="/admindashboardpage"
            element={
              <PrivetRoute
                Component={AdminDashboardPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminalluserspage"
            element={
              <PrivetRoute Component={AdminAllUsersPage} allowedRole={[2, 3]} />
            }
          />
          <Route
            path="/adminaddconsumerpage"
            element={
              <PrivetRoute
                Component={AdminAddConsumerPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminadduserpage"
            element={
              <PrivetRoute
                Component={AdminAddNewUserPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/admin/user/:userId"
            element={
              <PrivetRoute
                Component={AdminUserDetailsPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminmostsearchpage"
            element={
              <PrivetRoute
                Component={AdminMostSearchPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminaddnewcompanypage"
            element={
              <PrivetRoute
                Component={AdminAddNewCompanyPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminuploadfilepage"
            element={
              <PrivetRoute
                Component={AdminUploadfilePage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminallorderspage"
            element={
              <PrivetRoute
                Component={AdminAllOrdersPage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route
            path="/adminallinvoicepage"
            element={
              <PrivetRoute
                Component={AdminAllInvoicePage}
                allowedRole={[2, 3]}
              />
            }
          />
          <Route
            path="/adminallmemopage"
            element={
              <PrivetRoute Component={AdminAllMemoPage} allowedRole={[2, 3]} />
            }
          />
          <Route
            path="/adminrequestepage"
            element={
              <PrivetRoute Component={AdminRequestPage} allowedRole={[2, 3]} />
            }
          />
          <Route
            path="/adminrequestedynamicidpage"
            element={
              <PrivetRoute
                Component={AdminRequestDynamicIdPage}
                allowedRole={[2, 3]}
              />
            }
          />

          {/* Admin Routes (Role ID: 3) */}
          <Route
            path="/superadminpermissionpage"
            element={
              <PrivetRoute
                Component={SuperAdminPermissionPage}
                allowedRole={[3]}
              />
            }
          />
          <Route
            path="/adminanalyticspage"
            element={
              <PrivetRoute Component={AdminAnalyticsPage} allowedRole={[3]} />
            }

          />
          
        </Routes>
      </GlobalWrapper>
    </BrowserRouter>

    // <BrowserRouter>
    //   <GlobalWrapper>
    //     <Routes>
    //       <Route path="/dashboardpage" element={<DashboardPage />} />
    //       <Route path="/inventorypage/addcartpage" element={<AddCartPage />} />
    //       <Route
    //         path="/inventorypage/diamondcardpage/match"
    //         element={<MatchPage />}
    //       />
    //       <Route
    //         path="/inventorypage/diamondcardpage"
    //         element={<DiamondCardPage />}
    //       />
    //       <Route
    //         path="/inventorypage/diamondcardpage/:searchId"
    //         element={<DiamondCardPage />}
    //       />
    //       <Route
    //         path="/inventorypage/jewelerycardpage/:searchId"
    //         element={<JeweleryCardPage />}
    //       />
    //       <Route
    //         path="/inventorypage/jewelerycardpage"
    //         element={<JeweleryCardPage />}
    //       />
    //       <Route
    //         path="/inventorypage/savesearchpage"
    //         element={<SaveSearchPage />}
    //       />
    //       <Route path="/inventorypage/wishlistpage" element={<WishListPage />} />
    //       {/* <Route path="/inventorypage" element={<InventoryPage />} /> */}
    //       <Route path="/" element={<LoginPage />} />
    //       <Route path="/signuppage" element={<SignupPage />} />
    //       <Route path="/forgetpassword" element={<ForgetPassword />} />
    //       <Route path="/userprofilepage" element={<UserProfilePage />} />
    //       <Route path="/diamondsserach" element={<DiamondsSearch />} />
    //       <Route path="/jewelerysearch" element={<JewelerySearch />} />
    //       <Route path="/admindiamondsserach" element={<AdminDiamondsSearch />} />
    //       <Route path="/adminjewelrysserach" element={<AdminJewelerySearch />} />
    //       <Route
    //         path="/admininventorypage/admindiamondcardpage"
    //         element={<AdminDiamondCard />}
    //       />
    //       <Route
    //         path="/admininventorypage/adminJewelrycardpage"
    //         element={<AdminJeweleryCard />}
    //       />
    //       <Route
    //         path="/admininventorypage/admindiamondcardpage/:searchId/:itemId"
    //         element={<AdminDiamondDetail />}
    //       />
    //       <Route
    //         path="/admininventorypage/adminjewelerycardpage/:searchId/:processedSku"
    //         element={<AdminJewelryDetails />}
    //       />
    //       <Route path="/contact_us" element={<Contact_UsPage />} />
    //       <Route
    //         path="/inventorypage/jewelerycardpage/:searchId/:processedSku"
    //         element={<JewelryDynamicCard />}
    //       />
    //       <Route
    //         path="/inventorypage/diamondcardpage/:searchId/:itemId"
    //         element={<DiamondDynamicCard />}
    //       />
    //       <Route path="/memopage" element={<MemoPage />} />
    //       <Route path="/invoicepage" element={<InvoicePage />} />
    //       <Route path="/orderpage" element={<OrderPage />} />
    //       <Route path="/requests" element={<RequestsPage />} />
    //       <Route path="/comparepage" element={<ComparePage />} />
    //       <Route path="/admindashboardpage" element={<AdminDashboardPage />} />
    //       <Route path="/adminalluserspage" element={<AdminAllUsersPage />} />
    //       <Route
    //         path="/adminaddconsumerpage"
    //         element={<AdminAddConsumerPage />}
    //       />
    //       <Route path="/admin/user/:userId" element={<AdminUserDetailsPage />} />
    //       <Route path="/adminmostsearchpage" element={<AdminMostSearchPage />} />
    //       <Route path="/adminanalyticspage" element={<AdminAnalyticsPage />} />
    //       <Route
    //         path="/adminaddnewcompanypage"
    //         element={<AdminAddNewCompanyPage />}
    //       />
    //       <Route path="/adminuploadfilepage" element={<AdminUploadfilePage />} />
    //       <Route path="/adminallorderspage" element={<AdminAllOrdersPage />} />
    //       <Route path="/adminallinvoicepage" element={<AdminAllInvoicePage />} />
    //       <Route path="/adminallmemopage" element={<AdminAllMemoPage />} />
    //       <Route path="/adminrequestepage" element={<AdminRequestPage />} />
    //       <Route
    //         path="/adminrequestedynamicidpage"
    //         element={<AdminRequestDynamicIdPage />}
    //       />
    //       <Route path="/adminadduserpage" element={<AdminAddNewUserPage />} />

    //       <Route
    //         path="/superadminpermissionpage"
    //         element={<SuperAdminPermissionPage />}
    //       />
    //     </Routes>
    //   </GlobalWrapper>
    // </BrowserRouter>
  );
}

export default App;
