import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  lazy,
  useRef,
} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SlOptionsVertical } from "react-icons/sl";
import Loader from "../../../../Loader/Loader";
import Navbar from "../../../Navbar/Navbar";
import HeartIcon from "../../../../HeartIcon/HeartIcon";
import { fetchWishlistSlice } from "../../../../../redux/slice/wishlistSlice/wishlistSlice";
import { baseUrl } from "../../../../../utils/url";
import { AiOutlineSortAscending } from "react-icons/ai";
import { FaArrowDownAZ } from "react-icons/fa6";
import Menu from "./components/Menu";
import Table from "./components/Table";
import { decrypt, encrypt } from "../../../../../utils/EncryptDecrypt";

// const Menu = lazy(() => import("./components/Menu"));
// const Table = lazy(() => import("./components/Table"), {
//   loading: () => <Loader />,
// });

export default function DiamondCard() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchIdQuery = queryParams.get("searchId");

  const { searchId: searchIdParams } = useParams();

  const searchId = searchIdQuery || searchIdParams;

  const [searchDiamondData, setSearchDiamondData] = useState([]);

  const [isTableView, setIsTableView] = useState(() => {
    const savedView = sessionStorage.getItem(decrypt("isTableView"));
    return savedView === "true";
  });

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [datalength, setDatalength] = useState(0);
  const [likedItems, setLikedItems] = useState({});
  const [openMenuId, setOpenMenuId] = useState(null);
  const [imageLoading, setImageLoading] = useState({});
  const [sortOption, setSortOption] = useState({
    sortField: "",
    sortOrder: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [scrollRestored, setScrollRestored] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [restoringScroll, setRestoringScroll] = useState(false);

  const scrollPositionRef = useRef(0);

  const type = "diamond";

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevSelectedItems) => {
      const updatedItems = { ...prevSelectedItems };
      if (updatedItems[item.id]) {
        delete updatedItems[item.id];
      } else {
        updatedItems[item.id] = item;
      }

      return updatedItems;
    });
  };

  const toggleMenu = useCallback(
    (event, id) => {
      event.preventDefault(); // Prevent default behavior
      setOpenMenuId(openMenuId === id ? null : id);
    },
    [openMenuId]
  );

  // control scroll position

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.scrollY;
      sessionStorage.setItem(
        `searchId-${searchId}`,
        JSON.stringify({ scrollPosition: scrollPositionRef.current })
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [searchId]);


  useEffect(() => {
    const savedPosition = JSON.parse(
      sessionStorage.getItem(`searchId-${searchId}`)
    );
    if (savedPosition && savedPosition.scrollPosition) {
      setRestoringScroll(true);
      const tryScrollRestore = () => {
        if (window.scrollY !== savedPosition.scrollPosition) {
          window.scrollTo(0, savedPosition.scrollPosition);
          requestAnimationFrame(tryScrollRestore);
        }
        else {
          setRestoringScroll(false); // Scroll restoration complete
        }
      };
      tryScrollRestore();
   
    }
    
  }, []);

  useEffect(() => {
    if (contentLoaded) {
      const savedPosition = JSON.parse(
        sessionStorage.getItem(`searchId-${searchId}`)
      );
      if (savedPosition && savedPosition.scrollPosition) {
        window.scrollTo(0, savedPosition.scrollPosition);
      }
    }
  }, [contentLoaded, searchId]);


  useEffect(() => {
    const handleBeforeUnload = () => {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem(
        `searchId-${searchId}`,
        JSON.stringify({ scrollPosition })
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [searchId]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 5 &&
        hasMore &&
        !loading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  useEffect(() => {
    if (scrollRestored) {
      sessionStorage.removeItem(`searchId-${searchId}`);
    }
  }, [scrollRestored, searchId]);


  const fetchSearchData = useCallback(
    async (searchId, page, sortField, sortOrder) => {
      if (searchId) {
        setLoading(true);
        try {
          const token = sessionStorage.getItem("token");
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          let url;
          if (sortField && sortOrder) {
            url = `${baseUrl}/users/order-filter/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
          } else {
            url = `${baseUrl}/users/search/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
          }

          let response = await fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          });

          if (!response.ok || response.status === 404) {
            url = `${baseUrl}/users/SaveData/${searchId}?type=${sortField}&order=${sortOrder}&page=${page}`;
            response = await fetch(url, {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            });

            if (!response.ok) {
              if (response.status === 401) {
                console.error("Unauthorized: Token expired or invalid");
              } else {
                throw new Error("Failed to fetch search results");
              }
            }
          }
          const data = await response.json();
          if (data && data.data && data.data.length > 0) {
            setSearchDiamondData((prevData) => {
              const newData =
                page === 1 ? data.data : [...prevData, ...data.data];
              const uniqueData = newData.filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              );
              return uniqueData;
            });
            setDatalength(data.total);
            setHasMore(true);
            setIsOpen(false);
          } else {
            setHasMore(false);
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
        } finally {
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    fetchSearchData(searchId, page, sortOption.sortField, sortOption.sortOrder);
  }, [fetchSearchData, searchId, page, sortOption]);



  // const handleLike = useCallback(
  //   async (itemId) => {
  //     setLikedItems((prev) => ({
  //       ...prev,
  //       [itemId]: !prev[itemId],
  //     }));

  //     const isLiked = likedItems[itemId];

  //     try {
  //       const token = sessionStorage.getItem("token");
  //       const myHeaders = new Headers();
  //       myHeaders.append("Authorization", `Bearer ${token}`);
  //       myHeaders.append("Content-Type", "application/json");

  //       const requestOptions = {
  //         method: isLiked ? "DELETE" : "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           type: type,
  //           wish_list: true,
  //           record_id: itemId,
  //         }),
  //         redirect: "follow",
  //       };

  //       const apiEndpoint = isLiked
  //         ? `${baseUrl}/users/delete-wishlist/${itemId}`
  //         : `${baseUrl}/users/add-to-table`;

  //       const response = await fetch(apiEndpoint, requestOptions);

  //       if (response.ok) {
  //         dispatch(fetchWishlistSlice());
  //       } else {
  //         throw new Error("Failed to update like status");
  //       }
  //     } catch (error) {
  //       console.error("Error updating like status:", error);
  //     }
  //   },
  //   [likedItems, dispatch]
  // );

  const toggleTableView = useCallback(() => {
    setIsTableView(true);
    sessionStorage.setItem("isTableView", encrypt("true")); // Save state to sessionStorage
  }, []);

  const toggleCardView = useCallback(() => {
    setIsTableView(false);
    sessionStorage.setItem("isTableView", encrypt("false")); // Save state to sessionStorage
  }, []);

  useEffect(() => {
    // Check sessionStorage for view state when component mounts
    const savedViewState = sessionStorage.getItem("isTableView");
    if (savedViewState !== null) {
      setIsTableView(decrypt(savedViewState) === "true");
    }
  }, []);

  const AvailabilityDot = useMemo(
    () =>
      ({ availability }) => {
        const dotColor =
          availability === "MEMO"
            ? "red"
            : availability === "STOCK"
              ? "green"
              : "";
        return (
          <span className="flex items-center h-[50px] w-auto">
            <span className={`dot ${dotColor}`}></span>
          </span>
        );
      },
    []
  );

  const handleSelect = async (sortOption) => {
    let sortField = "";
    let sortOrder = "";

    if (sortOption === "low-to-high") {
      sortField = "Total Price";
      sortOrder = "asc";
    } else if (sortOption === "high-to-low") {
      sortField = "Total Price";
      sortOrder = "desc";
    } else if (sortOption === "cts-low-to-high") {
      sortField = "Weight";
      sortOrder = "asc";
    } else if (sortOption === "cts-high-to-low") {
      sortField = "Weight";
      sortOrder = "desc";
    }

    setSortOption({ sortField, sortOrder });
    setPage(1);
    fetchSearchData(searchId, 1, sortField, sortOrder);
  };

  const fallbackImage = "/logo/No Image Found.jpg";

  const handleImageLoad = useCallback((index) => {
    setImageLoading((prev) => ({ ...prev, [index]: false }));
  }, []);

  const handleImageError = useCallback((index) => {
    setImageLoading((prev) => ({ ...prev, [index]: false }));
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section>
      <div className="p-1 sticky max-sm:relative top-0 bg-[#feefdc] rounded-3xl z-40">
        <div className="flex justify-between pt-1 items-center pb-1 px-5">
          <div className="flex gap-5 items-center">
            <div>
              <div
                className="text-lg bg-[#2688bc] text-white py-1 px-2 rounded-lg cursor-pointer relative group"
                onClick={toggleDropdown}
              >
                <AiOutlineSortAscending fontSize={25} />
                <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
                  Sort Your Result
                </div>
              </div>
              {isOpen && (
                <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("low-to-high")}
                    >
                      Sort by Price: Low to High
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("high-to-low")}
                    >
                      Sort by Price: High to Low
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("cts-low-to-high")}
                    >
                      Sort by CTS: Low to High
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleSelect("cts-high-to-low")}
                    >
                      Sort by CTS: High to Low
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <h1 className="lg:text-2xl font-bold">Search Results</h1>
              <span>
                Total Diamonds -{" "}
                <span className="font-semibold text-[16px]">{datalength}</span>
              </span>
            </div>
          </div>
          <Navbar
            toggleTableView={toggleTableView}
            toggleCardView={toggleCardView}
            type={type}
            selectedItems={Object.keys(checkedItems).filter(
              (id) => checkedItems[id]
            )}
            searchId={searchId}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        </div>
      </div>

      {restoringScroll && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-100 z-30">
          <Loader />
        </div>
      )}

      {isTableView ? (
        <div
          className={`grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-5 gap-8 p-4 ${searchDiamondData.length === 0
            ? "flex items-center justify-center h-screen"
            : ""
            }`}
        >
          {searchDiamondData.length > 0 ? (
            searchDiamondData.map((item, index) => (
              <div
                key={index}
                className="border p-4 rounded-3xl relative shadow-2xl"
              >
                <span
                  className="z-10 absolute left-6 top-6 cursor-pointer"
                // onClick={() => handleLike(item.id)}
                >
                  {/* <HeartIcon filled={likedItems[item.id]} /> */}
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    className="hidden"
                    checked={!!checkedItems[item.id]}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label
                    htmlFor={`checkbox-${item.id}`}
                    className={`cursor-pointer flex items-center justify-center w-[20px] h-[20px] border border-black rounded transition-colors duration-200 ${checkedItems[item.id]
                      ? "bg-blue-500 border-blue-500"
                      : "bg-white"
                      }`}
                  >
                    <svg
                      className={`w-5 h-5 text-white transition-transform duration-200 ${checkedItems[item.id]
                        ? "transform scale-100"
                        : "transform scale-0"
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                </span>
                <Link
                  to={`/inventorypage/diamondcardpage/${searchId}/${item.id}`}
                >
                  <div className="image-container">
                    <div className="z-10">
                      <span className="absolute right-3 -top-1 z-10">
                        <AvailabilityDot availability={item["Availability"]} />
                      </span>
                    </div>
                    {imageLoading[index] && (
                      <div className="flex justify-center items-center h-full">
                        <Loader />
                      </div>
                    )}
                    <img
                      src={
                        item["Image Link"] && item["Image Link"] !== "NULL"
                          ? item["Image Link"]
                          : fallbackImage
                      }
                      alt={item.Title}
                      loading="lazy"
                      className={`rounded-xl w-full h-full object-cover ${imageLoading[index] ? "hidden" : ""
                        }`}
                      onLoad={() => handleImageLoad(index)}
                      onError={() => handleImageError(index)}
                    />
                  </div>
                </Link>
                <div className="text-[15px] flex gap-2 font-bold mt-2">
                  <span>{item.Shape}</span>
                  <span>{item["Weight"]}ct</span>
                  <span>
                    {item["Fancy Color Intensity"] || ""}{" "}
                    {item["Fancy Color Overtone"] || ""}{" "}
                    {item["Fancy Color"] || item.Color}
                  </span>
                  <span>{item["Clarity"]}</span>
                </div>
                <div className="flex justify-between items-end w-full">
                  <div className="mt-1 flex flex-col">
                    <span className="font-semibold text-gray-500">
                      Stock : {item["Stock #"]}
                    </span>
                    {item["Total Price"] ? (
                      <>
                        <span>Total Price :</span>
                        <span className="font-semibold">
                          ${(parseFloat(item["Total Price"]) || 0).toFixed(2)}
                        </span>
                      </>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  <div className="relative inline-block text-left">
                    <button
                      type="button"
                      onClick={(e) => toggleMenu(e, item.id)} // Pass event and id
                      className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <SlOptionsVertical fontSize={20} />
                    </button>
                    {openMenuId === item.id && (
                      <Menu searchId={searchId} item={item} type={type} />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) :
            loading ? (
              <div className="flex flex-col items-center justify-start min-h-screen mt-4">
                <p className="flex items-center justify-center mt-4">
                  <Loader />
                </p>
              </div>
            ) : (
              <div className="w-screen">
                <p className="text-lg font-bold text-center mt-4 whitespace-nowrap">
                  Unfortunately no items were found as per your search criteria. Please try modifying your search.
                </p>
              </div>
            )}
        </div>
      ) : (
        <div className="h-fulll w-full">
          <Table
            searchDiamondData={searchDiamondData}
            loading={loading}
            setLoading={setLoading}
            AvailabilityDot={AvailabilityDot}
            searchId={searchId}
            checkedItems={checkedItems}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>
      )}

      <style>{`
        .image-container {
          position: relative;
          width: 100%;
          height: 270px;
          overflow: hidden;
        }
        .dot.red {
          background-color: red;
        }
        .dot.green {
          background-color: green;
        }
      `}</style>
    </section>
  );
}
