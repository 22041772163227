import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LuArrowBigLeftDash } from "react-icons/lu";
import { FaArrowLeft, FaCirclePlay, FaShareNodes } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { IoCloseSharp, IoDiamondSharp } from "react-icons/io5";
import { GiShare } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { PiWhatsappLogoBold } from "react-icons/pi";
import { TbFileCertificate } from "react-icons/tb";
import Swal from "sweetalert2";
import DiamondRequestModal from "../../../../components/Inventory/Search/diamondssearch/diamondCard/diamondCardDaynamic/DiamondRequestModal";
import Navbar from "../../../../components/Inventory/Navbar/Navbar";
import Loader from "../../../../components/Loader/Loader";
import { fetchCartList } from "../../../../redux/slice/cartSlice/cartSlice";
import { baseUrl } from "../../../../utils/url";
import { AdminDefaultLayout } from "../../../../components/layout/adminDefaultLayout/AdminDefaultLayout";
import AdminNavbar from "../adminnavbar/AdminNavbar";

export default function AdminDiamondDetail() {
    const navigate = useNavigate();
    const type = "diamond";
    const { searchId, itemId } = useParams();

    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const id = queryParams.get('searchId');

    const [diamondData, setDiamondData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
    const mediaTypes = ["image", "video", "certificate"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalMatch, setTotalMatch] = useState(null);
    const [sendEmail, setSendEmail] = useState("");
    const [sendComments, setSendComments] = useState("");
    const [showPopup, setShowPopup] = useState(false);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCartList());
        // dispatch(fetchWishlistSlice()); 
    }, [dispatch]);

    useEffect(() => {
        const fetchDiamondData = async () => {
            setLoading(true);
            try {
                const token = sessionStorage.getItem("token");
                if (!token) {
                    console.error("Token not found");
                    setLoading(false);
                    return;
                }

                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Content-Type", "application/json");

                let url = `${baseUrl}/users/search/result/${searchId}/${itemId}`;

                let response = await fetch(url, {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        url = `${baseUrl}/users/get-from-table/${itemId}`;
                        response = await fetch(url, {
                            method: "GET",
                            headers: myHeaders,
                            redirect: "follow",
                        });
                    }

                    if (!response.ok) {
                        if (response.status === 401) {
                            console.error("Unauthorized: Token expired or invalid");
                        } else {
                            throw new Error(`Failed to fetch data: ${response.status}`);
                        }
                    }
                }

                const data = await response.json();
                setDiamondData(data.data);
            } catch (error) {
                console.error("Error fetching diamond data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (searchId && itemId) {
            fetchDiamondData();
        } else {
            console.error("Invalid searchId or itemId");
        }
    }, [searchId, itemId]);



    const MatchCount = async () => {
        try {
            const queryParams = new URLSearchParams({
                Shape: diamondData["Shape"],
                Color: diamondData["Color"],
                Clarity: diamondData["Clarity"],
                Weight: diamondData["Weight"],
            }).toString();

            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                `${baseUrl}/users/match-count?${queryParams}`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Failed to add item to cart");
            }

            const data = await response.json();
            setTotalMatch(data.totalmatch);
        } catch (error) {
            console.error("Error find match:", error);
        }
    };

    useEffect(() => {
        if (diamondData) {
            MatchCount();
        }
    }, [diamondData]);


    const handleShareClick = () => {
        setIsShareMenuOpen(!isShareMenuOpen);
    };

    // const handleShareWhatsApp = () => {
    //     const message = `Check out this diamond: ${diamondData.Shape} - ${window.location.href}`;
    //     const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    //     window.open(whatsappUrl, "_blank");

    //     console.log("message",window.location.href);
    //     console.log("whatsappUrl",whatsappUrl);

    // };

    const handleShareWhatsApp = () => {
        const message = `Check out this item: \n ${diamondData["Stock"]}\n ${diamondData["Title"] || diamondData.Shape
            }  ${diamondData.Weight} ${diamondData.Color} ${diamondData.Clarity
            } \n https://mail.itemlinkshare.com/emailshare/${type}/${diamondData.id}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, "_blank");
    };

    const handleOnShareClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleEmailShare = async (id) => {
        try {
            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            // Validate email addresses
            if (!validateEmails(sendEmail)) {
                Swal.fire({
                    icon: "error",
                    title: "Invalid Email Address",
                    text: "Please enter a valid email address.",
                });
                return;
            }

            const productIds = id ? Number(id) : [];
            console.log("id", id)
            console.log("productIds", productIds)
            console.log("sendEmail", sendEmail)

            const payload = {
                productId: [productIds],
                type: type,
                comments: sendComments,
                email: sendEmail,
            }

            const response = await fetch(`${baseUrl}/users/receipt-email`, {
                headers: myHeaders,
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to send email");
            }

            Swal.fire({
                icon: "success",
                title: "Email Sent",
                text: "Your email was successfully sent!",
            });

            setSendEmail("");
            setSendComments("");
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There was an error sending the email. Please try again later.",
            });

            console.error("Error to send email", error);
        }
    };

    const validateEmails = (emailString) => {
        const emails = emailString.split(/[,\s;]/).filter(Boolean);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.every((email) => emailPattern.test(email));
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % mediaTypes.length);
    };

    const handlePrevSlide = () => {
        setCurrentSlide(
            (prevSlide) => (prevSlide - 1 + mediaTypes.length) % mediaTypes.length
        );
    };

    const addToCart = async () => {
        try {
            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    type: "diamond",
                    productId: diamondData.id,
                    quantity: 1,
                }),
                redirect: "follow",
            };

            const response = await fetch(
                `${baseUrl}/users/add-to-cart`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Failed to add item to cart");
            }
            await Swal.fire({
                title: "Success",
                text: "Add To The Cart Successfully!",
                icon: "success",
            });
            dispatch(fetchCartList());

        } catch (error) {
            console.error("Error adding item to cart:", error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Item Already Add In Cart!",
            });
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (!diamondData) {
        return <p>No data found for this diamond.</p>;
    }

    const getMediaContent = () => {
        const mediaType = mediaTypes[currentSlide];
        if (
            mediaType === "image" &&
            diamondData["Image Link"] &&
            diamondData["Image Link"] !== "NULL"
        ) {
            return (
                <img
                    src={diamondData["Image Link"]}
                    alt={diamondData.Title}
                    loading="lazy"
                    className="rounded-xl w-full h-full object-cover"
                />
            );
        } else if (mediaType === "video" && diamondData["Video Link"]) {
            return (
                <div className="iframe-container">
                    <iframe
                        src={diamondData["Video Link"]}
                        title={diamondData.Title}
                        width="500"
                        height="500"
                        allowFullScreen
                        className="rounded-xl   object-cover"
                    />
                </div>
            );
        } else if (mediaType === "certificate" && diamondData["Certificate Url"]) {
            return (
                <div className="certificate-container">
                    <iframe
                        src={diamondData["Certificate Url"]}
                        title="Certificate"
                        width="100%"
                        height="500"
                        allowFullScreen
                        className="rounded-xl"
                    />
                </div>
            );
        }
        return (
            <img
                src="/logo/No Image Found.jpg"
                loading="lazy"
                className="rounded-xl w-full h-full object-cover"
            />
        );
    };

    const handleFindMatch = () => {
        const queryParams = new URLSearchParams({
            Shape: diamondData["Shape"],
            Color: diamondData["Color"],
            Clarity: diamondData["Clarity"],
            Weight: diamondData["Weight"],
            Stock: diamondData["Stock #"],
        }).toString();

        navigate(`/inventorypage/diamondcardpage/match?${queryParams}`, {
            state: searchId,
        });
    };

    const id = diamondData.id;

    const handleNavigateBack = () => {
        const scrollPosition = window.scrollY;
        sessionStorage.setItem("scrollPosition", scrollPosition);

        navigate(-1);
    };


    return (
        <AdminDefaultLayout>
            <section>
                <div className="flex px-16 justify-between ">
                    <span className="text-[25px]">Search</span>
                    <span>
                        <AdminNavbar id={id} type={type} />
                    </span>
                </div>
                <div className="p-4 max-w-7xl mx-auto">
                    <button
                        className="mb-4 text-black flex items-center gap-2"
                        onClick={handleNavigateBack}
                    >
                        <span>
                            <LuArrowBigLeftDash fontSize={20} />
                        </span>
                        <span> Back to Search Results</span>
                    </button>
                    <div className="flex flex-col lg:flex-row gap-8">
                        <div className="flex flex-col items-center">
                            <div className="w-full image-container relative">
                                {getMediaContent()}
                                <button
                                    className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                                    onClick={handlePrevSlide}
                                >
                                    <FaArrowLeft color="#000" />
                                </button>
                                <button
                                    className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-gray-400 text-white p-2 rounded-lg"
                                    onClick={handleNextSlide}
                                >
                                    <FaArrowRight color="#000" />
                                </button>
                            </div>
                            <div className="flex mt-4 gap-2">
                                {mediaTypes.map((type, index) => (
                                    <div
                                        key={type}
                                        className={`w-20 h-20 relative cursor-pointer ${currentSlide === index
                                            ? "border-2 border-blue-500 rounded-xl"
                                            : ""
                                            }`}
                                        onClick={() => setCurrentSlide(index)}
                                    >
                                        {type === "video" && (
                                            <span className="z-50 flex justify-center items-center left-5 absolute h-full">
                                                <FaCirclePlay fontSize={40} color="#fff" />
                                            </span>
                                        )}
                                        {type === "certificate" && (
                                            <span className="z-50 flex justify-center items-center h-full">
                                                <TbFileCertificate
                                                    fontSize={80}
                                                    color="rgb(148 158 169)"
                                                />
                                            </span>
                                        )}

                                        {type !== "certificate" && (
                                            <img
                                                src={
                                                    type === "image" || type === "video"
                                                        ? diamondData["Image Link"]
                                                        : diamondData["Video Link"]
                                                }
                                                alt={diamondData.Title}
                                                className="rounded-xl  w-full h-full object-cover -z-30 bg-black"
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="lg:w-1/2">
                            <div className="border-b-2 pb-3 mb-4">
                                <div className="font-semibold text-2xl">
                                    {diamondData.Shape} {diamondData["Weight"]}ct{" "}
                                    {diamondData["Fancy Color Intensity"] || ""}{" "}
                                    {diamondData["Fancy Color Overtone"] || ""}{" "}
                                    {diamondData["Fancy Color"] || diamondData.Color}{" "}
                                    {diamondData["Clarity"]}
                                </div>
                                <span className="text-lg">Stock  {diamondData["Stock #"]}</span>
                            </div>
                            {diamondData["Total Price"] ? (
                                <>
                                    <div className="grid grid-cols-2 gap-4 pb-5 border-b-2 mb-4">
                                        <div className="flex flex-col gap-1">
                                            <span>Status</span>
                                            <span>P/ct</span>
                                            <span>Discount</span>
                                            <span>Total Price</span>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold">
                                                {diamondData["Availability"] || "-"}
                                            </span>
                                            <span className="font-semibold">
                                                ${diamondData["Price"]}
                                            </span>
                                            <span className="font-semibold">
                                                {diamondData["Discount Percent"]}%
                                            </span>
                                            <span className="font-semibold">
                                                ${parseFloat(diamondData["Total Price"]).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <span>&nbsp;</span>
                            )}
                            <div className="flex gap-2 border-b-2 relative border-gray-200 pb-4">
                                {/* <button
                                    className={`bg-[#2688bc] rounded-md text-[#fff] py-2 gap-2 flex items-center px-5  ${totalMatch == 1 ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                    onClick={handleFindMatch}
                                    disabled={totalMatch == 1}
                                >
                                    <IoDiamondSharp color="#fff" /> Find Match
                                </button> */}
                                <button
                                    className=" bg-[#2688bc] rounded-md  text-[#fff]  py-2 gap-2 flex items-center px-5 "
                                    onClick={handleShareClick}
                                >
                                    <GiShare color="#fff" /> Share
                                </button>
                                {isShareMenuOpen && (
                                    <div className="absolute bg-gray-200 shadow-2xl top-11 left-24 rounded-lg p-2 flex flex-col">
                                        <button
                                            className="flex gap-2 items-center hover:bg-gray-400 p-2 rounded"
                                            onClick={handleShareWhatsApp}
                                        >
                                            <PiWhatsappLogoBold fontSize={18} />
                                            Share on WhatsApp
                                        </button>
                                        <button
                                            className="flex gap-2 items-center hover:bg-gray-400 p-2 rounded"
                                            onClick={handleOnShareClick}
                                        >
                                            <FaShareNodes fontSize={18} />
                                            Share via Email
                                        </button>
                                    </div>
                                )}

                                {showPopup && (
                                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                                        <div className="bg-white rounded-lg p-6 max-w-md w-full">
                                            <div className="flex justify-between items-center">
                                                <h3 className="text-lg font-semibold">Share {type}</h3>
                                                <button onClick={handleClosePopup}>
                                                    <IoCloseSharp fontSize={25} />
                                                </button>
                                            </div>
                                            <div className="mt-4">
                                                <label className="block text-sm font-semibold text-gray-700">
                                                    Enter Recipient's Email
                                                </label>
                                                <input
                                                    type="email"
                                                    value={sendEmail}
                                                    onChange={(e) => setSendEmail(e.target.value)}
                                                    placeholder="Please Enter Email."
                                                    className="mt-1 block border p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    required
                                                />
                                                <span className="text-sm pt-2">
                                                    Please use a comma or semicolon to separate multiple email
                                                    addresses.
                                                </span>
                                            </div>
                                            <div className="mt-6">
                                                <label className="block text-sm font-semibold text-gray-700">
                                                    Your Comment (Optional)
                                                </label>
                                                <textarea
                                                    className="mt-1 block p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    rows="3"
                                                    value={sendComments}
                                                    onChange={(e) => setSendComments(e.target.value)}
                                                />
                                            </div>

                                            <div className="mt-6 flex justify-center gap-6">
                                                <button
                                                    onClick={() => handleEmailShare(diamondData.id)}
                                                    className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
                                                >
                                                    Share
                                                </button>
                                                <button
                                                    onClick={handleClosePopup}
                                                    className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="border-b-2 pb-3 my-4 ">
                                <h3 className="font-semibold mb-5 text-[18px]">
                                    Product Details
                                </h3>
                                <div className="grid grid-cols-2 gap-5">
                                    <div className="flex flex-col gap-2">
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Lab</span>
                                            <span>{diamondData.Lab}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Report #</span>
                                            <span>{diamondData["Certificate #"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Shape</span>
                                            <span>{diamondData.Shape}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Carat</span>
                                            <span>{diamondData["Weight"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Color</span>
                                            <span>
                                                {diamondData["Fancy Color Intensity"] || ""}{" "}
                                                {diamondData["Fancy Color Overtone"] || ""}{" "}
                                                {diamondData["Fancy Color"] || diamondData.Color}
                                            </span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Clarity</span>
                                            <span>{diamondData.Clarity}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Cut</span>
                                            <span>{diamondData["Cut Grade"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Polish</span>
                                            <span>{diamondData.Polish}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Sym</span>
                                            <span>{diamondData["Symmetry"]}</span>
                                        </div>
                                        {/* <div className="grid grid-cols-2">
                    <span className="font-semibold">Location</span>
                    <span>{diamondData.Location || "-"}</span>
                  </div> */}
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Depth</span>
                                            <span>{diamondData["Depth Percent"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Table</span>
                                            <span>{diamondData["Table Percent"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Girdle</span>
                                            <span>{diamondData["Girdle Thick"]}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Culet</span>
                                            <span>{diamondData["Culet Size"] || "-"}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Meas</span>
                                            <span>{diamondData["Measurements"] || "-"}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <span className="font-semibold">Ratio</span>
                                            <span>{diamondData.ratio || "-"}</span>
                                        </div>
                                        <div className="grid grid-cols-2">
                    <span className="font-semibold">Location</span>
                    <span>{diamondData["Department Account Name"] === "LA Office" ? "Los Angeles": "New York" || "-"}</span>
                  </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border-b-2 pb-3 mb-4">
                                <h3 className="font-semibold mb-2">Comments</h3>
                                <p>{diamondData["Cert Comment"] || "-"}</p>
                            </div>
                            {/* <div className="border-b-2 pb-3 mb-4">
              <h3 className="font-semibold mb-2">Skylab Diamond Inc.</h3>
              <p>Email: {diamondData.Email}</p>
              <p>Address: {diamondData.Address}</p>
              <p>City: {diamondData.City}</p>
            </div> */}
                            <div className="flex gap-4">
                                {/* <button className="px-4 py-2 bg-gray-200 rounded">
                Add to Request List
              </button> */}
                                {/* <button
                className="px-4 py-2 bg-[#2688bc] text-white rounded"
                onClick={openModal}
              >
                Request Item
              </button> */}
                                {isModalOpen && (
                                    <DiamondRequestModal
                                        TotalPrice={diamondData["Total Price"]}
                                        Price={diamondData["Price"]}
                                        DiscountPercent={diamondData["Discount Percent"]}
                                        searchId={searchId}
                                        cardId={itemId}
                                        diamondData={diamondData}
                                        isOpen={openModal}
                                        onClose={closeModal}
                                    />
                                )}
                                {/* <button
                                    onClick={addToCart}
                                    className="px-4 py-2 bg-[#2688bc] text-white rounded"
                                >
                                    Add To Cart
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <style>{`
          .image-container {
            position: relative;
            width: 500px;
            height: 500px;
            overflow: hidden;
          }
        `}</style>
                    {/* {isModalOpen  && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-4">
                Request Item Popup Content
              </h2>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={closeModal} // Close popup on click
              >
                Close
              </button>
            </div>
          </div>
        )} */}
                </div>
            </section>
        </AdminDefaultLayout>
    );
}
