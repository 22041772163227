import React from "react";
import DashboardInvoice from "../../../components/Dashboard/DashboardInvoice/DashboardInvoice";
import { DefaultLayout } from "../../../components/layout/defaultLayout";

export default function InvoicePage() {
  return (
    <DefaultLayout>
      <DashboardInvoice />
    </DefaultLayout>
  );
}
