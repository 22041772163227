import React from "react";
import SuperAdminPermission from "../../superAdminComponents/superadminpermission/SuperAdminPermission";
import { AdminDefaultLayout } from "../../components/layout/adminDefaultLayout/AdminDefaultLayout";

export default function SuperAdminPermissionPage() {
  return (
    <AdminDefaultLayout>
      <SuperAdminPermission />
    </AdminDefaultLayout>
  );
}
