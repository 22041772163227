import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

// Define the initial state
const initialState = {
  data: [], 
  status: "idle",
  error: null,
};

export const fetchadminallmemo = createAsyncThunk(
  "allinvoice/fetchAdminAllInvoice",
  async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${baseUrl}/admin/get-item-request?request_type=memo`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch admin invoices");
      }
      

      const data = await response.json();
      return data;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

const allmemoSlice = createSlice({
  name: "allmemo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchadminallmemo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchadminallmemo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchadminallmemo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export default allmemoSlice.reducer;
