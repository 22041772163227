import { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/url";

export default function AdminEditInventoryDia({
  editData,
  setEditInventory,
  stocklot,
}) {
  const [formData, setFormData] = useState({
    stockNumber: "",
    availability: "",
    bgm: "",
    cashDiscountPercent: "",
    certComment: "",
    certificateNumber: "",
    certificateUrl: "",
    clarity: "",
    color: "",
    crownAngle: "",
    crownHeight: "",
    culetSize: "",
    cutGrade: "",
    depthPercent: "",
    discountPercent: "",
    fancyColor: "",
    fancyColorIntensity: "",
    fancyColorOvertone: "",
    fluorescenceColor: "",
    fluorescenceIntensity: "",
    girdleCondition: "",
    girdlePercent: "",
    girdleThick: "",
    girdleThin: "",
    imageLink: "",
    lab: "",
    laserInscription: "",
    measurements: "",
    memberComments: "",
    milky: "",
    pavilionAngle: "",
    pavilionDepth: "",
    polish: "",
    price: "",
    shape: "",
    symmetry: "",
    tablePercent: "",
    totalPrice: "",
    treatment: "",
    videoLink: "",
    weight: "",
  });

  // Options for availability dropdown
  const availabilityOptions = [
    { value: "", label: "Select Availability" },
    { value: "Stock", label: "Stock" },
    { value: "MEMO", label: "MEMO" },
    // Add more options as needed
  ];

  useEffect(() => {
    if (editData.length > 0) {
      const item = editData[0];
      setFormData({
        stockNumber: item["Stock #"] || "",
        availability: item["Availability"] || "",
        bgm: item["BGM"] || "",
        cashDiscountPercent: item["Cash Discount Percent"] || "",
        certComment: item["Cert Comment"] || "",
        certificateNumber: item["Certificate #"] || "",
        certificateUrl: item["Certificate Url"] || "",
        clarity: item["Clarity"] || "",
        color: item["Color"] || "",
        crownAngle: item["Crown Angle"] || "",
        crownHeight: item["Crown Height"] || "",
        culetSize: item["Culet Size"] || "",
        cutGrade: item["Cut Grade"] || "",
        depthPercent: item["Depth Percent"] || "",
        discountPercent: item["Discount Percent"] || "",
        fancyColor: item["Fancy Color"] || "",
        fancyColorIntensity: item["Fancy Color Intensity"] || "",
        fancyColorOvertone: item["Fancy Color Overtone"] || "",
        fluorescenceColor: item["Fluorescence Color"] || "",
        fluorescenceIntensity: item["Fluorescence Intensity"] || "",
        girdleCondition: item["Girdle Condition"] || "",
        girdlePercent: item["Girdle Percent"] || "",
        girdleThick: item["Girdle Thick"] || "",
        girdleThin: item["Girdle Thin"] || "",
        imageLink: item["Image Link"] || "",
        lab: item["Lab"] || "",
        laserInscription: item["Laser Inscription"] || "",
        measurements: item["Measurements"] || "",
        milky: item["Milky"] || "",
        pavilionAngle: item["Pavilion Angle"] || "",
        pavilionDepth: item["Pavilion Depth"] || "",
        polish: item["Polish"] || "",
        price: item["Price"] || "",
        shape: item["Shape"] || "",
        symmetry: item["Symmetry"] || "",
        tablePercent: item["Table Percent"] || "",
        totalPrice: item["Total Price"] || "",
        treatment: item["Treatment"] || "",
        videoLink: item["Video Link"] || "",
        weight: item["Weight"] || "",
      });
    }
  }, [editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const myheaders = new Headers();
      myheaders.append('Authorization',`Bearer ${token}`);

      const form = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        form.append(key, value);
      });

      const response = await fetch(`${baseUrl}/admin/update/stocklotname/${stocklot}`, {
        method: 'PUT',
        headers: myheaders,
        body: form,
      });
      if(!response.ok){
        throw new Error("Failed to update data");
      }

    } catch (error) {
      console.error("Error updating data:",error);
    }
  };

  return (
    <section className="flex flex-col">
      <div className="flex flex-wrap h-[800px] overflow-y-scroll -mx-2">
        {Object.keys(formData).map((key) => (
          <div key={key} className="w-full md:w-1/4 px-2 mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              {key.replace(/([A-Z])/g, " $1").toUpperCase()}
            </label>
            {key === "availability" ? (
              <select
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availabilityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          </div>
        ))}
        <div className="flex w-full gap-4 justify-center items-center mt-4">
          <button
            onClick={handleSubmit}
            className="px-8 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Save
          </button>
          <button
            onClick={() => setEditInventory(false)}
            className="px-8 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Close
          </button>
        </div>
      </div>
    </section>
  );
}
