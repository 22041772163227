import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../utils/url";

export default function AdminEditInventory({
  editData,
  setEditInventory,
  stocklot,
}) {

  const [formData, setFormData] = useState({
    availability: "Unavailable",
    stockNumber: "",
    lotname: "",
    jewelryStyle: "Stud Earring",
    title: "",
    metal: "",
    subType: "",
    totalPrice: "",
    stoneDetails: "",
    stoneDetail1: "",
    settingType: "",
    orientation: "",
    jewelrySize: "",
    avgCenterWeight: "",
    diamondShape: "",
    totalDiamondWeight: "",
    totalDiamondPcs: "",
    color: "",
    clarity: "",
    labName: "",
    report: "",
    imageFile: "",
    imageFile1: "",
    imageFile2: "",
    imageFile3: "",
    videoFile: "",
  });

  const availabilityOptions = [
    { value: "", label: "Select Availability" },
    { value: "Stock", label: "Stock" },
    { value: "MEMO", label: "MEMO" },
    // Add more options as needed
  ];

  useEffect(() => {
    if (editData.length > 0) {
      const item = editData[0]; // Assuming you want the first item
      setFormData({
        availability: item.Avability || "Unavailable",
        stockNumber: item["Sku #"] || "",
        lotname: item["Lot Name"] || "",
        jewelryStyle: item["Jewelry Type"] || "",
        subType: item["Jewelry Subtype"] || "",
        title: item["Title"] || "",
        metal: item["Metal Type"] || "",
        totalPrice: item["Total Price"] || "",
        stoneDetails: item["Stone Details"] || "",
        stoneDetail1: item["Stone Details_1"] || "",
        settingType: item["Setting Type"] || "",
        orientation: item["Orientation"] || "",
        jewelrySize: item["Jewelry Size"] || "",
        avgCenterWeight: item["Avg Center Weight"] || "",
        diamondShape: item["Diamond Shape"] || "",
        totalDiamondWeight: item["Total Diamond Weight"] || "",
        totalDiamondPcs: item["Total Diamond Pcs"] || "",
        color: item["Color"] || "",
        clarity: item["Clarity"] || "",
        labName: item["Lab Name"] || "",
        report: item["Report"] || "",
        imageFile: item["Image File"] || "",
        imageFile1: item["Image File_1"] || "",
        imageFile2: item["Image File_2"] || "",
        imageFile3: item["Image File_3"] || "",
        videoFile: item["Video File"] || "",
      });
    }
  }, [editData]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  console.log("stocklot",stocklot)

  const handleSubmit = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const myheaders = new Headers()
        myheaders.append('Authorization', `Bearer ${token}`);

        const form = new FormData();
      form.append("Avability", formData.availability);
      form.append("Sku #", formData.stockNumber);
      form.append("Lot Name", formData.lotname);
      form.append("Jewelry Type", formData.jewelryStyle);
      form.append("Jewelry Subtype", formData.subType);
      form.append("Title", formData.title);
      form.append("Metal Type", formData.metal);
      form.append("Total Price", formData.totalPrice);
      form.append("Stone Details", formData.stoneDetails);
      form.append("Stone Details_1", formData.stoneDetail1);
      form.append("Setting Type", formData.settingType);
      form.append("Orientation", formData.orientation);
      form.append("Jewelry Size", formData.jewelrySize);
      form.append("Avg Center Weight", formData.avgCenterWeight);
      form.append("Diamond Shape", formData.diamondShape);
      form.append("Total Diamond Weight", formData.totalDiamondWeight);
      form.append("Total Diamond Pcs", formData.totalDiamondPcs);
      form.append("Color", formData.color);
      form.append("Clarity", formData.clarity);
      form.append("Lab Name", formData.labName);
      form.append("Report", formData.report);
      form.append("Image File", formData.imageFile);
      form.append("Image File_1", formData.imageFile1);
      form.append("Image File_2", formData.imageFile2);
      form.append("Image File_3", formData.imageFile3);
      form.append("Video File", formData.videoFile);
    
        const response = await fetch(`${baseUrl}/admin/update/stocklotname/${stocklot}`, {
          method: 'PUT',
          headers: myheaders,
          body: form
        })

        if(!response.ok){
          throw new Error("Network response was not ok")
        }
    } catch (error) {
      console.error("Error updating data:",error);
    }
  };

  return (
    <section className="flex flex-col">
      <div className="flex flex-wrap h-[800px] overflow-y-scroll -mx-2">
        {Object.keys(formData).map((key) => (
          <div key={key} className="w-full md:w-1/4 px-2 mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              {key.replace(/([A-Z])/g, " $1").toUpperCase()}
            </label>
            {key === "availability" ? (
              <select
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availabilityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          </div>
        ))}
        <div className="flex w-full gap-4 justify-center items-center mt-4">
          <button
            onClick={handleSubmit}
            className="px-8 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Save
          </button>
          <button
            onClick={() => setEditInventory(false)}
            className="px-8 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Close
          </button>
        </div>
      </div>
    </section>
  );
}
