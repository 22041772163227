import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../../../utils/url';

export const fetchCartList = createAsyncThunk('cart/fetchCartList', async () => {
  const token = sessionStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const response = await fetch(`${baseUrl}/users/get-cartlist`, { method: 'GET', headers: myHeaders, redirect: "follow", });

  if (!response.ok) {
    throw new Error('Failed to fetch cart list');
  }

  const data = await response.json();
  return data.cart;
});

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCartList.fulfilled, (state, action) => {
        state.cartList = action.payload;
        state.loading = false;
      })
      .addCase(fetchCartList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default cartSlice.reducer;